import { BreakpointKey, Breakpoints } from '@client/style/Variables';
import { useEffect, useState } from 'react';

export default function useIsMobile(breakpoint: BreakpointKey = 'tablet') {
	const [isMobile, setIsMobile] = useState(true);

	useEffect(() => {
		const checkMobile = () => {
			if (window.innerWidth <= parseInt(Breakpoints[breakpoint])) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		checkMobile();

		window.addEventListener('resize', checkMobile);
		return function () {
			window.removeEventListener('resize', checkMobile);
		};
	}, []);

	return isMobile;
}
