import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
} from '../AnimationCommon';
import Functions from '@client/style/Functions';

const StyledAnimationContainer = styled(AnimationContainer)`
	/* position: relative;
	height: 700px;
	/* width: 100%; */
	/* height: 500px; */
	/* width: 100%; */
	align-self: center;
	justify-self: center;
	img {
		max-width: 100%;
		height: auto;
	}
	overflow: hidden;
`;

const HazeContainer = styled.div<{ fill?: string }>`
	width: 100vw;
	svg {
		width: 100%;
		height: auto;
		fill: ${props => props.fill || ''};
	}
`;
const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 1%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 99%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const MobileSpan = styled.span`
	display: block;
	${Functions.breakpoint('mobile')} {
		display: none;
	}
`;
const DesktopSpan = styled.span`
	display: none;
	${Functions.breakpoint('mobile')} {
		display: block;
	}
`;

const WishingWell = () => {
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670845480-onskebronden-desktop.png', {
		width: 558,
		height: 673,
	});

	return <AnimationImageElement image={image} />;
};

const mobileHaze = (
	<svg width='375' height='523' viewBox='0 0 375 523' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M73.2003 482.662C78.2136 500.587 128.847 498.436 132.357 522.815H252C250.83 519.111 247.189 512.834 258 503.481C266 496.561 278.877 501.814 294.5 482.662C308.537 465.453 275.978 442.348 302.5 438.281C321.5 435.367 335.501 420.169 335 403.677C334.499 387.185 376 371.163 376 343.199V0.81543H0.00701011C0.00701011 0.81543 0.508334 301.252 0.00701011 333.877C-0.494314 366.502 26.0759 362.2 21.0626 383.711C16.0494 405.222 35.0997 417.053 63.6752 427.45C92.2506 437.847 68.1871 464.736 73.2003 482.662Z' />
	</svg>
);
const desktopHaze = (
	<svg width='1728' height='1003' viewBox='0 0 1728 1003' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M623.278 854.773C663.973 946.089 703.855 916.312 717.572 1002.66H1033.21C1033.21 923.26 1052.37 923.798 1099.4 895.728C1160.94 859.004 1115.47 814.131 1150.33 771.397C1181.65 733.001 1334.97 782.304 1325.02 634.424C1318.07 531.197 1503.68 631.446 1536.44 490.502C1576.77 316.949 1728 500.428 1728 265.19V0.175781L0.941193 0.175792C0.941193 0.175792 2.0597 212.248 0.941144 285.041C-0.177415 357.835 122.319 303.186 150.818 435.911C167.623 514.174 192.506 582.81 333.45 582.81C474.394 582.81 350.728 706.893 482.334 746.583C607.397 784.301 589.186 778.273 623.278 854.773Z' />
	</svg>
);

export default function IllustrationWishingWell() {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (moduleRef.current) {
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor != null && nextColor != null) {
				setBackgroundColors([prevColor, nextColor]);
			} else if (prevColor != null) {
				setBackgroundColors([prevColor, prevColor]);
			} else if (nextColor != null) {
				setBackgroundColors([nextColor, nextColor]);
			} else {
				setBackgroundColors([undefined, undefined]);
			}
		}
	}, [moduleRef]);

	return (
		<StyledAnimationContainer
			height={1100}
			width={900}
			ref={moduleRef}
			breakpoints={{ mobile: { height: 1300, width: 2600 }, ultra: { height: 2000, width: 4000 } }}
		>
			<ColorWrapper>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>
			<AnimationPositionElement position={{ anchorY: 'bottom', y: '-6%', origin: '50% 100%' }}>
				<HazeContainer fill={backgroundColors[0]}>
					<MobileSpan>{mobileHaze}</MobileSpan>
					<DesktopSpan>{desktopHaze}</DesktopSpan>
				</HazeContainer>
			</AnimationPositionElement>

			<AnimationPositionElement
				position={{
					scale: 1.5,
					anchorY: 'bottom',
					y: '-6%',
					origin: '50% 100%',
					anchor: 'center',
					x: '-50%',
					breakpoints: { mobile: { scale: 0.7 }, tablet: { scale: 0.8 }, desktop: { scale: 1 }, ultra: { scale: 1.2 } },
				}}
			>
				<WishingWell />
			</AnimationPositionElement>
		</StyledAnimationContainer>
	);
}
