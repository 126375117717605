import styled from 'styled-components';
import { Breakpoints, Colors, Fonts } from '@style/Variables';
import Functions from '@style/Functions';

export const Wrapper = styled.div`
	/* background-color: yellow; */
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
`;

export const Page = styled(Wrapper)`
	min-height: 100vh;
	// position: relative;
`;

export const PageContent = styled.div`
	/* background-color: yellow; */
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
`;

export const SiteContent = styled(Wrapper)`
	min-height: 100vh;
	// overflow-x: hidden;

	/* ${Page} {
		background-color: var(--background);
		transition: background-color ${Colors.transition}s;
	} */
`;

export const CommonButton = styled.div`
	pointer-events: auto;
	width: auto;
	border-radius: 25px;
	background-color: ${Colors.darkBackground};
	color: ${Colors.lightText};
	position: relative;
	padding: 12px 24px;
	min-height: 42px;
	font-family: ${Fonts.Geomanist};
	text-decoration: Capitalize;
	font-size: 15px;
	margin-top: 10px;
	cursor: pointer;
	line-height: 1em;

	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);

	&:hover {
		transform: scale(1.1, 1.1);
	}

	&:active {
		transform: scale(0.95, 0.95);
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 21px;
	}
`;

export const CommonInput = styled.input`
	width: 100%;
	height: 60px;
	border-radius: 20px;
	background-color: ${Colors.ErrorPageBackground};
	border: 2px solid;

	:focus {
		outline: none;
	}

	font-family: ${Fonts.Geomanist};
	text-align: center;
`;

/**
 * Fonts
 *
 * Move all shared textstyles here over time
 */

export const TextStyleHeader = `
	font-size: 55px;
	line-height: 100%;
	margin: 0px;
	padding: 0px;
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 110px;
	}
`;

export const TextStyleSubHeader = `
	font-size: 35px;
	line-height: 100%;
	margin: 0px;
	padding: 0px;
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 70px;
	}
`;

export const TextStyleBody = `
	font-size: 30px;
	line-height: 120%;
	margin: 0px;
	padding: 0px;
	font-family: ${Fonts.Geomanist};

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 20px;
	}
`;

/**
 * Layout
 */
export const DefaultPageWrapper = `
	width: 100%;
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	padding-top: 100px;
`;
