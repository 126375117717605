interface IObservableItem<T = never> {
  callback: (data: T) => void;
  useOnce?: boolean;
  beingRemoved?: boolean;
}

export type ObservableListener<T = never> = Pick<
  Observable<T>,
  "on" | "off" | "once"
>;

export class Observable<T = never> {
  private _handlers: IObservableItem<T>[] = [];

  public get handlers(): IObservableItem<T>[] {
    return this._handlers;
  }

  public get listeners() {
    return this._handlers.length;
  }

  public on(handler: (data: T) => void) {
    const item = this._handlers.find((h) => h.callback === handler);

    if (item && item.beingRemoved) {
      item.beingRemoved = false;
      return;
    }

    this._handlers.push({ callback: handler });
  }

  public once(handler: (data: T) => void) {
    const item = this._handlers.find((h) => h.callback === handler);

    if (item && item.beingRemoved) {
      item.beingRemoved = false;
      return;
    }

    this._handlers.push({ callback: handler, useOnce: true });
  }

  public off(handler: (data: T) => void) {
    const item = this._handlers.find((h) => h.callback === handler);

    if (!item) {
      return;
    }

    item.beingRemoved = true;
  }

  public trigger(data: T) {
    const l: number = this._handlers.length;

    for (let i: number = 0; i < l; i += 1) {
      const item = this._handlers[i];
      if (item.beingRemoved === true) {
        continue;
      }

      try {
        // console.log("trigger " + index + " - " + h.callback.name);
        item.callback(data);
      } catch (e) {
        console.error("Triggering event, failed with error:");
        console.error(e);
      }

      if(item.useOnce) {
      	this.off(item.callback);
      }
    }

    this.cleanUp();
  }

  private cleanUp(): void {
    let l: number = this._handlers.length;

    for (let i: number = 0; i < l; i += 1) {
      const item = this._handlers[i];
      if (item.beingRemoved === true) {
        this._handlers.splice(i, 1);
        l -= 1;
        i -= 1;
      }
    }
  }

  public clear() {
    this._handlers.length = 0;
  }
}
