import { ColorUtils } from '@client/core/utils/colors/ColorUtils';

export const Variables = {
	gridColumnsMobile: 4,
	gridMarginMobile: '20px',
	gridGutterMobile: '20px',
	gridColumns: 16,
	gridMargin: '100px',
	gridGutter: '50px',
	gridMarginUltra: '100px',
	gridGutterUltra: '50px',
};

export const Fonts = {
	defaultFamily: `Helvetica, Arial, Roboto, sans-serif`,
	defaultSize: '16px',
	Geomanist: 'Geomanist',
	regularWeight: 400,
	boldWeight: 600,
};

export const Index = {
	PixiBackground: 0,
	PixiForeground: 44,
	cookieBanner: 45,
	FullScreenMenu: 1001,
	mainMenuButton: 1003,
	FullScreenVideoPlayer: 46,
	TextOverVideoPlayer: 48,
	indexMenu: 1003,
	preloader: 1010,
	siteLoader: 1010,
	debug: 1020,
	papand: 1000,
};

export const Breakpoints = {
	tiny: '0px',
	mobile: '690px',
	tablet: '900px',
	laptop: '1024px',
	desktop: '1600px',
	largeDesktop: '1790px',
	ultra: '2200px',
};
export type BreakpointKey = keyof typeof Breakpoints;

export const presetColors = {
	lightGrey: '#F5F5F5',
	grey: '#E6E6E6',
	darkGrey: '#CCCCCC',
	offWhite: '#F7F3F2',
	offsetBlack: '#272627',
	blackish: '#262C2E',
	kissMeRed: '#F74D38',
	dust: '#F1E1D6',
	white: '#ffffff',
	pinkish: '#F3C4B7',
	sunkiss: '#FFAE02',
	sunkissedContrast: '#C06800',
	solgult1: '#FFDF9A',
	nude: '#FFAD98',
	himmelblue: '#92CDDC',
	seablue: '#84AEFF',
	weirdgreen: '#18BF7E',
	evergreen: '#0B593B',
	pissyellow: '#F6F2DF',
	evergreenS1: '#0A472F',
	evergreenBright: '#157853',
};

export const Colors = {
	darkBackground: presetColors.blackish,
	lightBackground: presetColors.dust,
	darkText: presetColors.blackish,
	lightText: presetColors.white,
	lightButtonBackground: presetColors.white,
	mainMenuButtonBackground: presetColors.white,
	MainMenuButtonBackground: presetColors.white,
	preloaderBackground: presetColors.evergreen,
	footerLightBackground: presetColors.evergreen,
	footerDarkBackground: presetColors.evergreenS1,
	newsBackground: presetColors.sunkiss,
	favouriteBackground: presetColors.sunkissedContrast,
	defaultOverlayDim: ColorUtils.hexToRGB(presetColors.evergreen, 0.5),
	ErrorPageBackground: presetColors.pissyellow,
	defaultLightGrey: presetColors.grey,
	defaultDarkGrey: presetColors.darkGrey,
	defaultOffWhite: presetColors.offWhite,
	defaultWhite: presetColors.white,
	footerButtonTextColor: presetColors.evergreen,
	loginButton: presetColors.evergreen,
	loginButtonIdle: presetColors.evergreenBright,
	error: presetColors.kissMeRed,
	transition: 0.4,
};

export default Variables;
