import Functions from '@client/style/Functions';
import { Breakpoints, Fonts } from '@client/style/Variables';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SquaredLinkButton from '../shared/SquaredLinkButton';
import { VideoData } from '../VideoPlayer/VideoComponent';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import IndexMenuButton from '../IndexMenu/IndexMenuButton';
import IndexMenu from '../IndexMenu/IndexMenu';
import PageOverlayStore from '../overlays/PageOverlays/PageOverlayStore';
import { AnimationBlockLeavesHeader } from '../animations/AnimationBlockLeavesHeader';
import WaveContainer from '../Dividers.tsx/WaveContainer';
import Embed from '../Embed/Embed';

const Container = styled.div<{ backgroundColor?: string }>`
	background-color: ${props => props.backgroundColor};
	position: relative;
	align-self: start;
	width: 100%;
`;

const Title = styled.h1<{ color?: string }>`
	text-align: center;
	z-index: 1;
	font-size: 55px;
	line-height: 100%;

	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	color: ${props => props.color};
	margin: 0;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin: 0;
		font-size: 138px;
		line-height: 100%;
	}
`;

const Subtitle = styled.p<{ color?: string }>`
	text-align: center;
	z-index: 1;

	font-size: 18px;
	line-height: 130%;
	font-family: ${Fonts.Geomanist};
	color: ${props => props.color};

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 10px;
		font-size: 28px;
		line-height: 120%;
		width: ${Functions.col(10)};
	}
`;

const VideoPlayer = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;

	iframe {
		aspect-ratio: 16/9;
		width: auto !important;
		height: 100% !important;

		object-fit: cover;
		pointer-events: none;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		${Functions.breakpoint(Breakpoints.largeDesktop)} {
			height: auto !important;
			width: 100vw !important;
		}
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		overflow: hidden;
		display: flex;
		align-items: center;
	}
`;

const Content = styled.div`
	padding: 0px var(--gridMargin);
	padding-top: 200px;
	padding-bottom: 260px;

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 300px;
		padding-bottom: 500px;
	}
`;

const StyledButton = styled(SquaredLinkButton)`
	margin-top: 40px;
`;

const IndexButtonPlacer = styled.div`
	justify-self: end;
	align-self: end;
`;

interface IProps {
	backgroundColor?: any;
	title: string;
	subtitle?: string;
	video?: VideoData;
	button?: any;
	indexMenu?: any[];
	videoEmbed?: { embedType: 'youtube' | 'spotify' | 'twentythree'; url: string }[];
}

export default function LandingPageHeader({ data }: { data: IProps }) {
	const [indexToggled, setIndexToggled] = useState<boolean>(false);

	const containerRef = useRef<HTMLDivElement>(null);
	const [nextColor, setNextColor] = useState<string>();
	const isMobile = useIsMobile();

	useEffect(() => {
		const nextSibling = containerRef.current?.nextSibling;
		let nextColorElement;

		if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
			nextColorElement = nextSibling;
		}
		if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
			nextColorElement = nextSibling;
		} else {
			nextColorElement =
				nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
		}

		const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

		if (nextColor != null) {
			setNextColor(nextColor);
		} else {
			setNextColor(undefined);
		}
	}, []);

	const targetVideo = data.videoEmbed ?? [];

	return (
		<Container ref={containerRef} backgroundColor={nextColor}>
			<WaveContainer
				className='colorWrapperAnimatedModule'
				background={data.backgroundColor && Functions.hexFromRGB(data.backgroundColor?.color)}
			>
				{targetVideo.length > 0 && (
					<VideoPlayer>
						<Embed embedType={targetVideo[0]?.embedType} url={targetVideo[0]?.url}></Embed>
					</VideoPlayer>
				)}
				<Content>
					<Title color={data.backgroundColor && Functions.hexFromRGB(data.backgroundColor?.contrastColor)}>
						{data.title}
					</Title>
					<Subtitle color={data.backgroundColor && Functions.hexFromRGB(data.backgroundColor?.contrastColor)}>
						{data.subtitle}
					</Subtitle>
					{data.button[0] && (
						<StyledButton
							backgroundColor={Functions.hexFromRGB(data.button[0].buttonColorLink?.color)}
							textColor={Functions.hexFromRGB(data.button[0].buttonColorLink?.contrastColor)}
							data={data.button[0].link[0]}
						></StyledButton>
					)}
					{data.indexMenu && data.indexMenu.length > 0 && (
						<>
							<IndexButtonPlacer>
								<IndexMenuButton
									bgColor={data.backgroundColor}
									onClick={() => {
										PageOverlayStore.setContent(
											data.indexMenu && data.indexMenu.length > 0 && (
												<IndexMenu
													show={!indexToggled}
													close={() => {
														PageOverlayStore.setContent(undefined);
													}}
													backgroundColor={data.indexMenu[0].backgroundColorLink}
													indexAnchors={data.indexMenu[0].indexAnchors}
												/>
											)
										);
									}}
								/>
							</IndexButtonPlacer>
						</>
					)}
				</Content>
				{!data.video && targetVideo.length <= 0 && <AnimationBlockLeavesHeader />}
			</WaveContainer>
		</Container>
	);
}
