import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';

export type CarouselSettingsObject = {
	id: string;
	link: any;
	linkText: string;
	maxEvents: number;
	modelId: string;
	title: string;
	type: string;
};

const Container = styled.div`
	height: fit-content;
	width: 100%;
	position: relative;
	overflow-x: hidden;

	${Functions.breakpoint(Breakpoints.mobile)} {
		overflow-x: visible;
	}
`;

const Inner = styled.div`
	position: relative;
	left: calc(${Functions.col(3, 16, 1)});
	display: flex;
	gap: var(--gridGutter);
`;

export interface ImperativeCarousel {
	triggerHandleResize: () => void;
}

function Carousel(
	{
		children,
		className,
		activeIndex,
		isArtistCarousel = false,
	}: { children: ReactNode; className?: string; activeIndex: number; isArtistCarousel?: boolean },
	ref: React.ForwardedRef<ImperativeCarousel>
) {
	const carouselContainer = React.useRef<HTMLDivElement | null>(null);

	useImperativeHandle(ref, () => ({
		triggerHandleResize() {
			handleResize();
		},
	}));

	useEffect(() => {
		if (!carouselContainer.current) return;
		const observer = new ResizeObserver(handleResize);
		observer.observe(carouselContainer.current);

		return () => {
			if (!carouselContainer.current) return;
			observer.unobserve(carouselContainer.current);
		};
	}, [carouselContainer]);

	useEffect(() => {
		moveToIndex(activeIndex, 0.5);
	}, [activeIndex]);

	const handleResize = () => {
		moveToIndex(activeIndex, 0);
	};

	const moveToIndex = (index: number, duration: number) => {
		if (!carouselContainer.current) return;

		const root = document.querySelector(':root');
		if (!root) return;
		const rootStyled = getComputedStyle(root);

		let point = 0;
		for (let i = 0; i < index; i++) {
			point +=
				carouselContainer.current.children[i].clientWidth + parseInt(rootStyled.getPropertyValue('--gridGutter'));
		}

		gsap.to(carouselContainer.current, {
			x: -point,
			duration: duration,
			overwrite: true,
			ease: 'back(1.2).inOut',
		});
	};

	return (
		<Container className={className}>
			<Inner ref={carouselContainer}>{children}</Inner>
		</Container>
	);
}

export default forwardRef(Carousel);
