import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import gsap, { Power2 } from 'gsap';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import RoundButtonWithAnimation from './RoundButtonWithAnimation';

const OuterContainer = styled.div<{ backgroundColor?: string }>`
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'inherit')};
	pointer-events: all;
	padding: 20px;
`;

const TransitionContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

const Container = styled.div`
	position: relative;
	max-width: 600px;
	width: 100%;
	height: 100%;
	pointer-events: all;
`;

const Background = styled.div<{ backgroundColor: string }>`
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: ${props => props.backgroundColor};
`;

const InnerContainer = styled.div`
	position: relative;
	height: 100%;
	padding: 20px;
	padding-top: 80px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		padding: 50px;
		padding-top: 80px;
	}
`;

const CloseButton = styled(RoundButtonWithAnimation)`
	position: absolute;
	top: 20px;
	right: 20px;
	width: 40px;
	height: 40px;
	pointer-events: all;
`;

/**
 * Illustrations
 */
const IllustrationsContainer = styled.div`
	position: absolute;
	bottom: 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const IlluHands = styled.div`
	position: absolute;
	bottom: 0px;
	display: flex;
	left: 50%;
	height: 40%;
	transform: translateX(-50%);

	${Functions.breakpoint(Breakpoints.laptop)} {
		height: 50%;
	}
`;

const IlluBranches = styled.div`
	position: absolute;
	bottom: 0px;
	display: flex;
	left: 50%;
	height: 40%;
	transform: translateX(-50%);
	width: auto;

	${Functions.breakpoint(Breakpoints.laptop)} {
		height: 50%;
	}
`;

interface IProps {
	className?: string;
	children?: JSX.Element | JSX.Element[];
	backgroundColor: string;
	dimColor?: string;
	onCloseButtonClick?: () => void;
}

export const LoginPromptOverlayFrame = (props: IProps) => {
	const outerContainerRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (!outerContainerRef.current) return;
		gsap.from(outerContainerRef.current, {
			y: '100%',
			ease: Power2.easeOut,
			duration: 0.5,
		});
	}, []);

	const onCloseButtonClick = (): void => {
		if (!outerContainerRef.current) {
			props.onCloseButtonClick?.();
			return;
		}
		gsap.to(outerContainerRef.current, {
			y: '100%',
			ease: Power2.easeIn,
			duration: 0.5,
			onComplete: () => {
				props.onCloseButtonClick?.();
			},
		});
	};

	return (
		<>
			<OuterContainer backgroundColor={props.dimColor}>
				<TransitionContainer ref={outerContainerRef}>
					<Container>
						<Background backgroundColor={props.backgroundColor} />
						<IllustrationsContainer>
							<IlluBranches>
								<img src='/assets/images/sign-up-branches.png' />
							</IlluBranches>
						</IllustrationsContainer>
						<InnerContainer>
							{props.children && props.children}

							{props.onCloseButtonClick && (
								<CloseButton size={40} interaction={onCloseButtonClick}>
									<svg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M0.656854 12.6569L6.31371 7M11.9706 1.34315L6.31371 7M11.9706 12.6569L6.31371 7M6.31371 7L0.656854 1.34315'
											stroke='#262C2E'
											strokeWidth='1.5'
										/>
									</svg>
								</CloseButton>
							)}
						</InnerContainer>
						<IlluHands>
							<img src='/assets/images/lovehands.png' />
						</IlluHands>
					</Container>
				</TransitionContainer>
			</OuterContainer>
		</>
	);
};
