import React from 'react';
import ModuleSwitcherCore, { Module } from '@core/ModuleSwitcherCore';
import { TextCTA } from './TextCTA';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import NewsCarousel from './Carousel/NewsCarousel';
import WishingWellHandler from './WishingWell/WishingWellHandler';
import TextModule from './TextModule';
import HighlightedArtists from './HighlightedArtists/HighlightedArtists';
import FilteredList from './ListModules/FilteredList';
import ImageAndText from './ImageAndText';
import EmbedModule from './Embed/EmbedModule';
import { VideoCTA } from './VideoPlayer/VideoCTA';
import SimpleImageCarousel from './Carousel/SimpleImageCarousel';
import Statistics from './StatisticModule/Statistics';
import Accordian from './Accordian/Accordian';
import QuoteModule from './Quote/QuoteModule';
import AutomatedBreakerModule from './Dividers.tsx/AutomatedBreakerModule';
import ListingTextModule from './ListingTextModule';
import LinkButtonList from './LinkButtonList';
import FeaturedLinksList from './ListModules/FeaturedLinksList';
import FullBleedMediaModule from './FullBleedMediaModule';
import BackgroundImageModule from './BackgroundImageModule';
import BackgroundVideoModule from './BackgroundVideoModule';
import OpenMapModule from './Map/OpenMapModule';
import Legekammerater from './Legekammerater/Legekammerater';
import DownloadModule from './DownloadModule/DownloadModule';
import { Illustration } from './animations/illustrations/Illustration';
import FormModule from './FormModule/FormModule';
import FullBleedEmbed from './Embed/FullBleedEmbed';
import { StoreModule } from './StoreModule/StoreModule';

export default function MagicModuleSwitcher(props: {
	modules: Array<any>;
	addedContent?: Array<any>;
	addedNews?: Array<any>;
}) {
	if ((props.addedContent || props.addedNews) && props.modules) {
		props.modules.map((module: any) => {
			if (module.modelId === 'filteredList') {
				module.addedContent = props.addedContent;
			}
			if (module.modelId === 'newsCarousel') {
				module.addedNews = props.addedNews;
			}
		});
	}
	return (
		<ModuleSwitcherCore modules={props.modules}>
			<Module modelId={'textCta'} component={TextCTA} />
			<Module modelId={'videoPlayer'} component={VideoPlayer} />
			<Module modelId={'newsCarousel'} component={NewsCarousel} />
			<Module modelId={'wishingWell'} component={WishingWellHandler} />
			<Module modelId={'blockOfText'} component={TextModule} />
			<Module modelId={'focusArtist'} component={HighlightedArtists} />
			<Module modelId={'filteredList'} component={FilteredList} />
			<Module modelId={'imageWithText'} component={ImageAndText} />
			<Module modelId={'embed'} component={EmbedModule} />
			<Module modelId={'videoCta'} component={VideoCTA} />
			<Module modelId={'imageCarousel'} component={SimpleImageCarousel} />
			<Module modelId={'statistic'} component={Statistics} />
			<Module modelId={'accordian'} component={Accordian} />
			<Module modelId={'quote'} component={QuoteModule} />
			<Module modelId={'automatedBreaker'} component={AutomatedBreakerModule} />
			<Module modelId={'listingTextBlock'} component={ListingTextModule} />
			<Module modelId={'linkButtonList'} component={LinkButtonList} />
			<Module modelId={'featuredLinksList'} component={FeaturedLinksList} />
			<Module modelId={'fullBleedMediaModule'} component={FullBleedMediaModule} />
			<Module modelId={'fullBleedMediaImage'} component={BackgroundImageModule} />
			<Module modelId={'fullBleedMediaLoopingVideo'} component={BackgroundVideoModule} />
			<Module modelId={'openMapModule'} component={OpenMapModule} />
			<Module modelId={'legekammerater'} component={Legekammerater} />
			<Module modelId={'downloadModule'} component={DownloadModule} />
			<Module modelId={'illustration'} component={Illustration} />
			<Module modelId={'illustrationBlock'} component={Illustration} />
			<Module modelId={'formModule'} component={FormModule} />
			<Module modelId={'fullBleedEmbed'} component={FullBleedEmbed} />
			<Module modelId={'store'} component={StoreModule} />
			{/* <Module modelId={'textSection'} component={TextModule} />
			<Module modelId={'video'} component={VideoAssetModule} /> */}
		</ModuleSwitcherCore>
	);
}
