import HTML from '@client/core/utils/HTML';
import Functions from '@client/style/Functions';
import { Breakpoints, Fonts } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ backgroundColor: string }>`
	background-color: ${props => props.backgroundColor};
	width: 100%;

	padding-top: 40px;
	padding-bottom: 40px;
	padding: 40px var(--gridMargin);

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 0px ${Functions.col(4)};
		padding-bottom: 40px;

		padding-top: 80px;
	}
`;

const TextWrapper = styled.div<{ color: string }>`
	width: 100%;
	text-align: center;
	color: ${props => props.color};
	margin: 0px;

	p {
		font-family: ${Fonts.Geomanist};
		font-size: 18px;
		line-height: 130%;
		margin: 40px 0;

		${Functions.breakpoint(Breakpoints.mobile)} {
			font-size: 22px;
			line-height: 120%;
		}
	}

	h1 {
		font-size: 55px;
		line-height: 100%;
		margin: 10px 0;

		${Functions.breakpoint(Breakpoints.mobile)} {
			font-size: 110px;
			line-height: 100%;
			margin: 0px 0;
		}
	}
	,
	h2 {
		font-size: 44px;
		line-height: 110%;
		margin: 10px 0;

		${Functions.breakpoint(Breakpoints.mobile)} {
			font-size: 86px;
			line-height: 120%;
			margin: 0px 0;
		}
	}
	,
	h3,
	h4,
	h5 {
		margin: 10px 0;
		font-family: ${Fonts.Geomanist};
		font-weight: ${Fonts.boldWeight};
	}
	h6 {
		font-family: ${Fonts.Geomanist};
		font-size: 22px;
		line-height: 120%;

		margin: 10px 0;
		b {
			font-family: ${Fonts.Geomanist};
			font-weight: ${Fonts.boldWeight};
		}

		${Functions.breakpoint(Breakpoints.mobile)} {
			font-size: 35px;
			line-height: 100%;

			font-size: 44px;
			line-height: 120%;
			b {
				font-size: 35px;
				line-height: 100%;
			}
		}
	}

	h6 + h2 {
		margin-top: 40px;
	}

	h1 + h6 {
		margin-top: 20px;
	}
	*:last-child {
		margin-bottom: 0;
	}
`;

export default function ListingTextModule({ data }: { data: { backgroundColor: any; text: string } }) {
	return (
		<Container
			className='colorWrapperAnimatedModule'
			backgroundColor={data.backgroundColor && Functions.hexFromRGB(data.backgroundColor?.color)}
		>
			<TextWrapper
				color={data.backgroundColor && Functions.hexFromRGB(data.backgroundColor?.contrastColor)}
				dangerouslySetInnerHTML={{ __html: HTML.clean(data.text) }}
			></TextWrapper>
		</Container>
	);
}
