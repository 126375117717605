import Functions from '@client/style/Functions';
import { Breakpoints, Colors, Fonts } from '@client/style/Variables';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import SquaredLinkButton from '@client/modules/shared/SquaredLinkButton';
import Breaker from '../Dividers.tsx/Breaker';
import Image, { ImageData } from '../Image';
import Embed from '../Embed/Embed';
import IndexMenu from '../IndexMenu/IndexMenu';
import IndexMenuButton from '../IndexMenu/IndexMenuButton';
import PageOverlayStore from '../overlays/PageOverlays/PageOverlayStore';
import DownloadButton from '../shared/DownloadButton';
import MagicButtonSwitcher from '../shared/MagicButtonSwitcher';

const Container = styled.div<{
	$negativeBottomOffset?: boolean;
	backgroundColor?: string;
	color?: string;
	hasImage: boolean;
	hasIndex: boolean;
}>`
	position: relative;
	padding-top: 116px;
	padding-bottom: ${props => (props.hasIndex ? '280px' : '260px')};

	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${props => props.backgroundColor};
	width: 100%;
	color: ${props => props.color};

	/* Add negative bottom margin if first module on page is FullBleedMediaModule */
	/* The percentage values are based on the aspect ratios of the breaker waves */
	margin-bottom: ${props => (props.$negativeBottomOffset ? '-37%' : '0')};
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-bottom: ${props => (props.$negativeBottomOffset ? '-17%' : '0')};
	}

	overflow: hidden;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 300px;

		padding-bottom: ${props => {
			if (props.hasImage && props.hasIndex) {
				return '500px';
			} else if (props.hasImage) {
				return '450px';
			} else if (props.hasIndex) {
				return '350px';
			} else {
				return '500px';
			}
		}};
	}
`;

const BackArrowContainer = styled.div`
	padding-bottom: 30px;
	z-index: 1;
`;

const BackArrow = styled.a<{ color?: string }>`
	width: 68px;
	height: 68px;
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: start;
	svg {
		path {
			stroke: ${props => props.color};
		}
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 0px;

		margin-left: -26px;
		justify-content: center;

		background-color: ${Colors.darkBackground};
		svg {
			path {
				stroke: ${Colors.lightText};
			}
		}
	}
`;

const TitleContainer = styled.div`
	z-index: 1;
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 44px;
	padding-bottom: 10px;
	text-align: center;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 110px;
		line-height: 100%;
	}
`;

const SubtitleContainer = styled.div`
	z-index: 1;
	font-size: 18px;
	text-align: center;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(10)};
		position: relative;
		align-self: center;

		font-size: 28px;
		line-height: 120%;
	}
`;

const ButtonContainer = styled.div`
	display: inline-block;
	width: auto;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40px;
`;

const BreakerPlacer = styled.div<{ svgColor?: string }>`
	position: absolute;
	width: 102%;
	bottom: -1%;
	left: -1%;

	pointer-events: none;

	svg > path {
		fill: ${props => props.svgColor};
	}
`;

const VideoPlayer = styled.div`
	position: absolute;
	/* margin-left: calc(-1 * (var(--gridMargin))); */
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	iframe {
		aspect-ratio: 16/9;
		width: auto !important;
		height: 100% !important;
		/* height: auto; */
		object-fit: cover;
		pointer-events: none;

		${Functions.breakpoint(Breakpoints.largeDesktop)} {
			height: auto !important;
			width: 100vw !important;
		}
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		overflow: hidden;
		display: flex;
		align-items: center;
	}
`;

const ImageContainer = styled(Image)`
	position: absolute;
	top: 0;
	left: 0;
	.img {
		/* width: auto !important; */
		object-fit: cover;
		pointer-events: none;

		${Functions.breakpoint(Breakpoints.mobile)} {
			width: 100vw !important;
			height: auto !important;
		}
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		overflow: hidden;
		display: flex;
		align-items: center;
	}
`;

const ImageBlockContainer = styled(Image)`
	margin-top: 30px;
	min-width: 700px;
	max-width: 1200px;
	margin-left: 50%;
	transform: translateX(-50%);

	/* Negate padding on parent */
	margin-bottom: -260px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-bottom: -450px;
	}
`;

interface ContentHeaderProps {
	title: string;
	subtitle: string;
	parent: any;
	bgColor?: any;
	CTALink?: any;
	video?: any;
	image?: ImageData;
	fullBleed?: boolean;
	indexMenu?: any[];
	hideBreaker?: boolean;
}

export default function ContentpageHeader(props: ContentHeaderProps) {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [nextColor, setNextColor] = useState<string>();

	useEffect(() => {
		const nextSibling = containerRef.current?.nextSibling;
		let nextColorElement;

		if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
			nextColorElement = nextSibling;
		}
		if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
			nextColorElement = nextSibling;
		} else {
			nextColorElement =
				nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
		}

		const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

		if (nextColor != null) {
			setNextColor(nextColor);
		} else {
			setNextColor(undefined);
		}
	}, []);

	return (
		<Container
			ref={containerRef}
			className='colorWrapperAnimatedModule'
			backgroundColor={Functions.hexFromRGB(props.bgColor?.color)}
			color={Functions.hexFromRGB(props.bgColor?.contrastColor)}
			hasImage={props.image ? true : props.video.length > 0 ? true : false}
			hasIndex={props.indexMenu && props.indexMenu.length > 0 ? true : false}
			$negativeBottomOffset={props.hideBreaker}
		>
			{props.video.length > 0 && (
				<VideoPlayer>
					<Embed embedType={props.video[0]?.embedType} url={props.video[0]?.url}></Embed>
				</VideoPlayer>
			)}
			{props.image && props.fullBleed && <ImageContainer maxWidth={1920} data={props.image}></ImageContainer>}
			<BackArrowContainer>
				{props.parent && (
					<BackArrow
						color={Functions.hexFromRGB(props.bgColor?.contrastColor)}
						href={props.parent?._prefix + props.parent?.slug}
					>
						<svg width='15' height='24' viewBox='0 0 15 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M12.7569 21.9679L2.85742 12.0684L12.7569 2.16886' stroke='#262C2E' strokeWidth='4' />
						</svg>
					</BackArrow>
				)}
			</BackArrowContainer>
			<TitleContainer>{props.title}</TitleContainer>
			<SubtitleContainer>{props.subtitle}</SubtitleContainer>
			{props.CTALink[0] && (
				<ButtonContainer>
					<MagicButtonSwitcher button={props.CTALink[0]} backgroundColorLink={props.bgColor?.contrastColor} />{' '}
				</ButtonContainer>
			)}
			{props.indexMenu && props.indexMenu.length > 0 && (
				<>
					<IndexMenuButton
						bgColor={props.bgColor}
						onClick={() => {
							PageOverlayStore.setContent(
								props.indexMenu && props.indexMenu.length > 0 && (
									<IndexMenu
										close={() => {
											PageOverlayStore.setContent(undefined);
										}}
										backgroundColor={props.indexMenu[0].backgroundColorLink}
										indexAnchors={props.indexMenu[0].indexAnchors}
									/>
								)
							);
						}}
					/>
				</>
			)}
			{props.image && !props.fullBleed && <ImageBlockContainer data={props.image}></ImageBlockContainer>}
			{!props.hideBreaker && (
				<BreakerPlacer svgColor={nextColor}>
					<Breaker></Breaker>
				</BreakerPlacer>
			)}
		</Container>
	);
}
