import { Breakpoints, Colors, Fonts, presetColors } from '@client/style/Variables';
import React, { useRef } from 'react';
import styled from 'styled-components';
import gsap, { Expo } from 'gsap';
import Functions from '@client/style/Functions';
import { type } from 'os';

const CommonSquaredBotton = styled.button<{ backgroundcolor?: string; $size?: ButtonSizeType }>`
	display: flex;
	justify-content: center;
	overflow: hidden;

	pointer-events: auto;
	width: auto;

	background-color: ${props =>
		props.backgroundcolor === presetColors.dust.toLocaleLowerCase() ? 'white' : props.backgroundcolor};
	color: ${props =>
		props.backgroundcolor?.toLocaleLowerCase() === presetColors.blackish.toLocaleLowerCase()
			? 'white'
			: presetColors.blackish};

	position: relative;
	padding: ${props => (props.$size === 'small' ? '9px 16px' : '14px 20px')};

	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	text-decoration: Capitalize;
	font-size: ${props => (props.$size === 'small' ? '20px' : '22px')};
	cursor: pointer;

	/* transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);

	&:hover {
		transform: scale(1.1, 1.1);
	}

	&:active {
		transform: scale(0.95, 0.95);
	} */
	:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: ${props => (props.$size === 'small' ? '14px 22px' : '20px 26px')};

		font-size: ${props => (props.$size === 'small' ? '24px' : '28px')};
	}
`;

export const WipeArea = styled.div<{ backgroundcolor?: string }>`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: hidden;

	.wipe {
		background-color: ${props => props.backgroundcolor || Colors.darkBackground};
	}
`;
export const StyledWipe = styled.div`
	position: absolute;
	top: -1px;
	left: -100%;
	bottom: -1px;
	width: 90%;
	background: white;
	opacity: 0.5;
	transform: skewX(-20deg);
`;

export type ButtonSizeType = 'small' | 'medium';

interface IProps {
	title?: string;
	textColor?: string;
	backgroundColor?: string;
	onClick?: () => void;
	disabled?: boolean;
	size?: ButtonSizeType;
}

export default function SquaredDefaultButton(props: IProps) {
	const wipeRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const animationRef = useRef<gsap.core.Timeline>();

	const onMouseEnter = (): void => {
		animationRef.current?.restart();
	};

	React.useEffect(() => {
		const ease = 'back(1.3).out';
		const duration = 0.6;

		const timeline = gsap.timeline({
			scrollTrigger: {
				trigger: buttonRef.current,
				toggleActions: 'play reset play reset',
				start: 'top 80%',
				end: 'bottom 20%',
			},
		});

		timeline.to(
			buttonRef.current,
			{
				keyframes: {
					scale: [1, 1.1, 1],
					easeEach: ease,
				},
				duration: duration,
				ease: 'none',
			},
			0
		);
		timeline.to(
			wipeRef.current,
			{
				keyframes: {
					x: ['0%', '100%', '230%'],
					easeEach: 'power1.out',
				},
				duration: duration,
				ease: 'none',
			},
			0
		);

		animationRef.current = timeline;
	}, []);

	return (
		<CommonSquaredBotton
			ref={buttonRef}
			disabled={props.disabled}
			backgroundcolor={props.backgroundColor}
			onClick={props.onClick}
			onMouseEnter={onMouseEnter}
			$size={props.size}
		>
			{props.title}
			<StyledWipe ref={wipeRef} />
		</CommonSquaredBotton>
	);
}
