import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import Link, { createLinkObject } from '@client/core/PageSwitch/Link';
import { Fonts, presetColors } from '@client/style/Variables';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import infoIcon from '../../assets/svgs/icon-info.svg';
import { AnimationBlockFlowerPointer } from '../animations/AnimationBlockFlowerPointer';
import { DateUtils } from '@client/core/utils/DateUtils';
import SpilleplanEvent from './SpillleplanEvent';

const Container = styled.div<{ width: number }>`
	min-width: calc(${props => props.width}px + var(--gridMargin));
	position: relative;
	display: flex;
	flex-direction: column;
	height: fit-content;
	height: 100%;
`;

const TimeIndicator = styled.div`
	width: 100%;

	border-bottom: 1px solid ${presetColors.blackish};
	height: 1.3em;

	z-index: 1;
	top: 0px;
`;

const TimeIndicatorBackground = styled.div<{ bgColor: string }>`
	position: absolute;
	top: -100%;
	left: 0;
	width: 101%;
	height: 200%;
`;

const TimeEntries = styled.div<{ leftPost: number }>`
	padding: 0px var(--gridMargin);

	font-family: ${Fonts.Geomanist};
	font-size: 12px;
	line-height: 1.2;

	left: ${props => props.leftPost}px;
	top: 0;
	position: absolute;
`;

const CurrentTimeIndicator = styled.div<{ leftPos: number }>`
	margin: 0px var(--gridMargin);

	position: absolute;
	top: 0;
	left: ${props => props.leftPos}px;
	width: 1px;
	height: 100%;
	background-color: ${presetColors.kissMeRed};
`;

const LocationEntry = styled.div<{ rightBorder: boolean | undefined }>`
	height: 150px;
	border-bottom: 1px solid ${presetColors.blackish};
	position: relative;
	border-right: ${props => (props.rightBorder ? `1px solid ${presetColors.blackish}` : 'none')};
	border-left: 1px solid ${presetColors.blackish};

	position: relative;
`;

const PointerIllustration = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	width: 100%;
	flex-direction: column;
	border: 1px solid ${presetColors.blackish};
	border-right: none;
	border-top: none;
`;

const MoreToTheLeft = styled.div`
	display: flex;
	/* width: 100%; */
`;

const LocationEntryInner = styled.div`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	padding-left: var(--gridMargin);
`;

const LocationName = styled.p`
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);

	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 16px;
	line-height: 1.2;
	padding-top: 20px;
	position: sticky;
	display: flex;
	top: 0;
	left: 0;
	width: fit-content;
	height: fit-content;
	svg {
		margin-left: 5px;
	}

	text-transform: capitalize;
	color: ${presetColors.blackish};
`;

const InfoIcon = styled(infoIcon)``;

type LocationEventsObject = {
	location: string;
	events: any[];
};
type TimeRange = {
	earliestTime: Date;
	latestTime: Date;
};

type SpecialOptions = { pointerIllustration?: boolean; lastDate?: boolean; index?: number };

export default function SpilleplanDayEntry({
	events,
	bgColor,
	dateIndicator,
	specialOptions = { pointerIllustration: false, lastDate: false },
}: {
	events: any[];
	bgColor: string;
	dateIndicator: Date;
	specialOptions?: SpecialOptions;
}) {
	const isMobile = useIsMobile();
	const containerRef = useRef<HTMLDivElement>(null);
	const timeIndicatorRef = useRef<HTMLDivElement>(null);
	const timeBackgroundRef = useRef<HTMLDivElement>(null);
	const illustrationRef = useRef<HTMLImageElement>(null);

	const pixelsPerHour: number = isMobile ? 180 : 260;
	const timeRange: TimeRange = getTimeRange(events);

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		ScrollTrigger.matchMedia({
			'(min-width: 1024px)': () => {
				const calendar = document.getElementsByClassName('calendarCarousel')[0];
				if (!calendar) return;
				ScrollTrigger.create({
					pin: timeIndicatorRef.current,
					pinType: 'transform',
					end: () => `${calendar.clientHeight} bottom`,
					pinSpacing: false,
					start: '65px +=235',
				});
			},
			'(max-width: 1023px)': () => {
				const calendar = document.getElementsByClassName('calendarCarousel')[0];
				if (!calendar) return;

				ScrollTrigger.create({
					pin: timeIndicatorRef.current,
					pinType: 'transform',
					end: () => `${calendar.clientHeight} bottom`,
					pinSpacing: false,
					start: '65px +=225',
				});
			},
		});
	}, []);

	useEffect(() => {
		gsap.to(timeBackgroundRef.current, {
			backgroundColor: bgColor,
			duration: 0.5,
		});
	}, [bgColor]);

	function separateEventsByLocation(events: any[]): LocationEventsObject[] {
		const locationEventsMap: Map<string, any[]> = new Map();
		for (const event of events) {
			const location = event.location ? event.location.name : 'ukendt';
			if (!locationEventsMap.has(location)) {
				locationEventsMap.set(location, []);
			}
			locationEventsMap.get(location)?.push(event);
		}

		const locationEventsArray = Array.from(locationEventsMap.entries()).map(([location, events]) => ({
			location,
			events,
		}));

		const locationOrder = ['Bøgescenerne', 'Stjernescenen', 'Månen', 'The Hood', 'Udsigten'];
		locationEventsArray.sort((a, b) => {
			const aIndex = locationOrder.indexOf(a.location);
			const bIndex = locationOrder.indexOf(b.location);
			if (aIndex === -1 && bIndex === -1) {
				return a.location.localeCompare(b.location);
			}
			if (aIndex === -1) {
				return 1;
			}
			if (bIndex === -1) {
				return -1;
			}
			return aIndex - bIndex;
		});

		return locationEventsArray;
	}

	function getTimeRange(events: any[]): TimeRange {
		const sortedEvents = events
			.slice()
			.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
		const earliestTime = new Date(sortedEvents[0].startTime);
		const latestTime = Math.max(...sortedEvents.map(event => new Date(event.startTime).getTime()));

		const dateLatestTime: Date = new Date(latestTime);

		//Adding one hours before earliest event and two hours after last event two give spacing
		earliestTime.setHours(earliestTime.getHours());
		dateLatestTime.setHours(dateLatestTime.getHours() + 2);

		return {
			earliestTime,
			latestTime: dateLatestTime,
		};
	}

	function getTimeIndicatorHours(start: Date, end: Date): number {
		const diff = end.getTime() - start.getTime();
		const hours = diff < 0 ? 0 : diff / (1000 * 60 * 60);
		return hours;
	}

	function getWholeHoursInRange(start: Date, end: Date, reference: Date): { hour: Date; hoursFromReference: number }[] {
		const hoursInRange: { hour: Date; hoursFromReference: number }[] = [];
		const hourMs = 60 * 60 * 1000; // 1 hour in milliseconds
		const diffFromReference = (reference.getTime() - start.getTime()) / hourMs;

		let currHour = new Date(Math.ceil(start.getTime() / hourMs) * hourMs);
		while (currHour < end) {
			const hoursFromReference = (currHour.getTime() - reference.getTime()) / hourMs;
			hoursInRange.push({ hour: currHour, hoursFromReference: hoursFromReference });
			currHour = new Date(currHour.getTime() + hourMs);
		}

		return hoursInRange.map(hour => ({
			hour: hour.hour,
			hoursFromReference: hour.hoursFromReference - diffFromReference,
		}));
	}

	function checkDateIndicator(): boolean {
		const tempDateIndicator = new Date(dateIndicator.getTime());
		tempDateIndicator.setHours(dateIndicator.getHours() - 6);

		return (
			tempDateIndicator.getFullYear() === timeRange.earliestTime.getFullYear() &&
			tempDateIndicator.getMonth() === timeRange.earliestTime.getMonth() &&
			tempDateIndicator.getDate() === timeRange.earliestTime.getDate()
		);
	}

	return (
		<Container
			width={pixelsPerHour * Math.max(DateUtils.getHoursBetween(timeRange.earliestTime, timeRange.latestTime), 3)}
			ref={containerRef}
		>
			<TimeIndicator ref={timeIndicatorRef}>
				<TimeIndicatorBackground ref={timeBackgroundRef} bgColor={bgColor}></TimeIndicatorBackground>

				{getWholeHoursInRange(timeRange.earliestTime, timeRange.latestTime, timeRange.earliestTime).map((time, i) => (
					<TimeEntries leftPost={time.hoursFromReference * pixelsPerHour} key={i}>
						{time.hour.toLocaleTimeString('Da-dk', {
							hour: '2-digit',
							minute: '2-digit',
							timeZone: 'Europe/Copenhagen',
						})}
					</TimeEntries>
				))}
			</TimeIndicator>
			{separateEventsByLocation(events).map((event, i) => (
				<LocationEntry key={'event_' + i + '_' + event.location} rightBorder={specialOptions.lastDate}>
					{checkDateIndicator() && (
						<CurrentTimeIndicator
							className='timeIndicator'
							leftPos={dateIndicator && getTimeIndicatorHours(timeRange.earliestTime, dateIndicator) * pixelsPerHour}
						/>
					)}

					<LocationEntryInner>
						{event.events
							.sort((a, b) => Date.parse(a.startTime) - Date.parse(b.startTime))
							.map((performance, i) => (
								<SpilleplanEvent
									key={performance.id}
									performance={performance}
									leftPos={
										DateUtils.getHoursBetween(new Date(performance.startTime), timeRange.earliestTime) * pixelsPerHour
									}
									width={
										pixelsPerHour *
										(event.events.length > i + 1
											? DateUtils.getHoursBetween(
													new Date(performance.startTime),
													new Date(event.events[i + 1].startTime)
												)
											: 2)
									}
								/>
							))}
					</LocationEntryInner>

					{event.events[0].location.link ? (
						<Link data={createLinkObject(event.events[0].location.link)}>
							<LocationName>
								{event.location}
								<InfoIcon></InfoIcon>
							</LocationName>
						</Link>
					) : (
						<LocationName>{event.location}</LocationName>
					)}
				</LocationEntry>
			))}
			{specialOptions.pointerIllustration && (
				<PointerIllustration>
					<MoreToTheLeft>
						{/* <StyledImage src='/assets/images/spilleplan-footer-left.png' alt='pegeblomst' /> */}
						<AnimationBlockFlowerPointer />
					</MoreToTheLeft>
				</PointerIllustration>
			)}
		</Container>
	);
}
