import React from 'react';
import styled from 'styled-components';
import Image, { ImageData } from '../Image';
import Link from '@client/core/PageSwitch/Link';
import Functions from '@client/style/Functions';
import { Breakpoints, Fonts } from '@client/style/Variables';
import { heading3TextStyle } from '@client/common/CommonText';
import { commonTextStyle } from '@client/common/CommonText';

const Container = styled(Link)`
	width: 100%;
	display: flex;
	gap: 20px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		gap: 40px;
	}
`;

const ImagesWrapper = styled.div`
	width: 30%;
	display: flex;
	justify-content: center;

	${Functions.breakpoint(Breakpoints.mobile)} {
		/* width: ${Functions.col(8)}; */
	}
`;

const StyledImage = styled(Image)`
	width: 100%;
	aspect-ratio: 1/1;
`;

const InfoWrapper = styled.div`
	width: 70%;
`;

const Title = styled.h3`
	${heading3TextStyle};
	font-size: 28px;
	line-height: 1.1;
	margin: 0;
	${Functions.limitText(1)};
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 40px;
		${Functions.limitText(2)};
	}
`;

const Subtitle = styled.p`
	${commonTextStyle};
	${Functions.limitText(2)};

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 6px;
		${Functions.limitText(6)};
	}
`;

const TimeAndLocation = styled.p`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 14px;
	line-height: 1.2;
	margin-top: 6px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 18px;
		margin-top: 12px;
	}
`;

export default function DetailedListEntryWithTimestamp({
	image,
	title,
	subtitle,
	link,
	backgroundColorLink,
	startTime,
	location,
}: {
	image: ImageData;
	title: string;
	subtitle: string;
	link: any;
	backgroundColorLink: any;
	startTime: string;
	location: string;
}) {
	return (
		<Container data={link[0]}>
			<ImagesWrapper>{image && <StyledImage data={image}></StyledImage>}</ImagesWrapper>

			<InfoWrapper>
				<Title color={backgroundColorLink && Functions.hexFromRGB(backgroundColorLink.contrastColor)}>{title}</Title>
				<Subtitle color={backgroundColorLink && Functions.hexFromRGB(backgroundColorLink.contrastColor)}>
					{subtitle}
				</Subtitle>
				<TimeAndLocation>
					{new Date(startTime)
						.toLocaleTimeString('da-DK', {
							hour12: false,
							hour: '2-digit',
							minute: '2-digit',
							timeZone: 'Europe/Copenhagen',
						})
						.replace('.', ':')}{' '}
					- {location}
				</TimeAndLocation>
			</InfoWrapper>
		</Container>
	);
}
