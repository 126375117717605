import Store from '@client/core/Store';
import { ArrayUtils } from '@client/core/utils/ArrayUtils';

export interface IDefaultFilterType {
	id: string;
	name: string;
}

export type ViewType = 'tile' | 'list';

export interface IFilterState {
	dateFilters: Date[];
	tagFilters: IDefaultFilterType[];
	locationFilters: IDefaultFilterType[];
	selectedDateFilters: string;
	selectedTagFilters: string[];
	selectedLocationFilters: string[];
	activeFilters: number;

	// View props
	view: ViewType;
}

export const DefaultState = {
	dateFilters: [],
	tagFilters: [],
	locationFilters: [],
	selectedDateFilters: '',
	selectedTagFilters: [],
	selectedLocationFilters: [],
	activeFilters: 0,
	view: 'tile' as ViewType,
};

export class FilterStoreClass extends Store<IFilterState> {
	constructor() {
		super(DefaultState);
	}

	public setTagFilters(filters: IDefaultFilterType[]) {
		this.set({ tagFilters: filters });
	}

	public setLocationFilters(filters: IDefaultFilterType[]) {
		this.set({ locationFilters: filters });
	}

	public setDateFilters(filters: Date[]) {
		this.set({ dateFilters: filters });
	}

	public setView(view: ViewType) {
		this.set({ view: view });
	}

	public changeDateFilter(date: Date, checked: boolean) {
		if (checked) {
			// const copy: string[] = [...this.get().selectedDateFilters, date.toString()];
			this.set({ selectedDateFilters: date.toString() });
		} else {
			// const copy: string[] = [...this.get().selectedDateFilters];
			// ArrayUtils.removeElement(copy, date.toString());
			this.set({ selectedDateFilters: undefined });
		}
		this.calcActiveFilters();
	}

	public changeLocationFilter(id: string, checked: boolean) {
		// console.log('id, checked :', id, checked);
		if (checked) {
			const copy: string[] = [...this.get().selectedLocationFilters, id];
			this.set({ selectedLocationFilters: copy });
		} else {
			const copy: string[] = [...this.get().selectedLocationFilters];
			ArrayUtils.removeElement(copy, id);
			this.set({ selectedLocationFilters: copy });
		}
		this.calcActiveFilters();
	}
	public changeTagFilter(id: string, checked: boolean) {
		// console.log('id, checked :', id, checked);

		if (checked) {
			const copy: string[] = [...this.get().selectedTagFilters, id];
			this.set({ selectedTagFilters: copy });
		} else {
			const copy: string[] = [...this.get().selectedTagFilters];
			ArrayUtils.removeElement(copy, id);
			this.set({ selectedTagFilters: copy });
		}
		this.calcActiveFilters();
	}

	private calcActiveFilters() {
		const selectedFilters: string[] = [...this.get().selectedLocationFilters, ...this.get().selectedTagFilters];
		if (this.get().selectedDateFilters) {
			selectedFilters.push(this.get().selectedDateFilters);
		}
		this.set({ activeFilters: selectedFilters.length });
	}
}

const FilterStore = new FilterStoreClass();

export default FilterStore;
