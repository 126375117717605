import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import React, { ReactChild, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Carousel, { ImperativeCarousel } from './Carousel';
import DraggableCarousel from './DraggableCarousel';
import gsap from 'gsap';

const StyledCarousel = styled(Carousel)`
	padding: 0 var(--gridMargin);
	height: fit-content;
`;

const StyledDraggableCarousel = styled(DraggableCarousel)`
	padding: 0 var(--gridMargin);
`;

const PointerCatcher = styled.div`
	position: absolute;
	width: 100vw;
	padding: 0 var(--gridMargin);
	height: 100%;
	left: 0;
	top: 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	pointer-events: none;
`;

const PointerCatcherItem = styled.svg`
	pointer-events: all;
	cursor: pointer;
`;

export default function MagicCarouselSelector(props: { onChange: (index: number) => void; children: ReactNode }) {
	if (!props.children) return null;

	const isMobile = useIsMobile();
	const [activeIndex, setActiveIndex] = useState(0);
	const carouselRef = useRef<ImperativeCarousel | null>(null);
	const leftTriggerRef = useRef<SVGSVGElement | null>(null);
	const rightTriggerRef = useRef<SVGSVGElement | null>(null);

	useEffect(() => {
		shouldShowBackButton();
		shouldShowNextButton();
		props.onChange(activeIndex);
	}, [activeIndex]);

	useEffect(() => {
		shouldShowBackButton();
		shouldShowNextButton();
	}, [props.children]);

	const incrementActiveIndex = () => {
		if (activeIndex < React.Children.count(props.children) - 1) {
			setActiveIndex(activeIndex + 1);
		} else {
			setActiveIndex(0);
		}
	};

	const decrementActiveIndex = () => {
		if (activeIndex > 0) {
			setActiveIndex(activeIndex - 1);
		} else {
			setActiveIndex(React.Children.count(props.children) - 1);
		}
	};

	const shouldShowBackButton = () => {
		if (!leftTriggerRef.current) return;
		if (activeIndex === 0) gsap.to(leftTriggerRef.current, { opacity: 0, duration: 0.25, pointerEvents: 'none' });
		else gsap.to(leftTriggerRef.current, { opacity: 1, duration: 0.25, pointerEvents: 'all' });
	};

	const shouldShowNextButton = () => {
		if (!rightTriggerRef.current) return;
		if (activeIndex === React.Children.count(props.children) - 1)
			gsap.to(rightTriggerRef.current, { opacity: 0, duration: 0.25, pointerEvents: 'none' });
		else gsap.to(rightTriggerRef.current, { opacity: 1, duration: 0.25, pointerEvents: 'all' });
	};

	return (
		<>
			{isMobile ? (
				<StyledDraggableCarousel activeIndex={activeIndex} dragToNewIndex={setActiveIndex}>
					{props.children}
				</StyledDraggableCarousel>
			) : (
				<>
					<StyledCarousel ref={carouselRef} activeIndex={activeIndex}>
						{props.children}
					</StyledCarousel>
					<PointerCatcher>
						<PointerCatcherItem
							width='68'
							height='69'
							viewBox='0 0 68 69'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							onClick={decrementActiveIndex}
							ref={leftTriggerRef}
						>
							<circle cx='34' cy='34.457' r='34' transform='rotate(-180 34 34.457)' fill='#262C2E' />
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M18.5466 34.457L29.8603 23.1433L32.6887 25.9718L26.2034 32.457L49 32.457L49 36.457L26.2034 36.457L32.6887 42.9423L29.8603 45.7707L18.5466 34.457Z'
								fill='white'
							/>
						</PointerCatcherItem>
						<PointerCatcherItem
							width='68'
							height='69'
							viewBox='0 0 68 69'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							onClick={incrementActiveIndex}
							ref={rightTriggerRef}
						>
							<circle cx='34' cy='34.457' r='34' fill='#262C2E' />
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M49.4534 34.457L38.1397 45.7707L35.3112 42.9423L41.7965 36.457L18.9999 36.457V32.457L41.7965 32.457L35.3112 25.9718L38.1397 23.1433L49.4534 34.457Z'
								fill='white'
							/>
						</PointerCatcherItem>
					</PointerCatcher>
				</>
			)}
		</>
	);
}
