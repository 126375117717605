import Store from '@client/core/Store';
import { Color } from '@client/core/utils/colors/Color';
import { presetColors } from '@client/style/Variables';

export interface IContentPageState {
	backgroundColor: string | undefined;
	contrastColor: string | undefined;
}

export const DefaultState = {
	backgroundColor: presetColors.white,
	contrastColor: presetColors.blackish,
};

export class ContentPageStoreClass extends Store<IContentPageState> {
	constructor() {
		super(DefaultState);
	}

	public setBackgroundColor(color: string | undefined) {
		this.set({ backgroundColor: color });
		this.set({ contrastColor: this.findContrastingColor(color) });
	}

	private findContrastingColor(color: string | undefined) {
		if (!color) return presetColors.blackish;
		const newColor = new Color(color);
		// console.log(newColor.yiq);
		if (newColor.yiq > 127) {
			return presetColors.blackish;
		} else {
			return presetColors.white;
		}
	}
}

const ContentPageStore = new ContentPageStoreClass();
export default ContentPageStore;
