import React from 'react';
import styled from 'styled-components';
import { Colors, Breakpoints, Fonts, presetColors } from '@style/Variables';
import Link from '@core/PageSwitch/Link';
import Site from '@client/store/Site';
import Functions from '@client/style/Functions';
import Image from '../Image';
import SquaredLinkButton from '../shared/SquaredLinkButton';
import SoMeIcon from '../SoMeIcon';
import Breaker from '../Dividers.tsx/Breaker';

const OutsideContainer = styled.div<{ bgColor?: string; textColor?: string }>`
	position: relative;
	width: 100%;
	background-color: ${props => props.bgColor || Colors.footerDarkBackground};
`;

export const FooterContainer = styled.div<{ bgColor?: string; textColor?: string }>`
	background-color: ${props => props.bgColor || Colors.footerDarkBackground};
	color: ${props => props.textColor || Colors.lightText};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	position: relative;
	z-index: 1;
	overflow: hidden;
	font-family: ${Fonts.Geomanist};
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	padding-top: 90px;
	margin-top: -5px;
	padding-bottom: 50px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 0px;
	}
	${Functions.breakpoint(Breakpoints.laptop)} {
		flex-direction: row;
		padding-bottom: 300px;
	}
`;

const Column = styled.div<{ bgColor?: string }>`
	//background-color: ${props => props.bgColor || Colors.footerDarkBackground};
	width: 100%;
`;

const DesktopLinkList = styled(Column)`
	display: flex;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	${Functions.breakpoint(Breakpoints.laptop)} {
		justify-content: start;
		align-items: start;
		a:nth-of-type(4) {
			padding-top: 60px;
		}
	}
`;

const ColumnContainer = styled(Column)`
	max-width: 600px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-right: ${Functions.col(1, 16, 0.25)};
	}
`;

const DesktopLink = styled(Link)`
	&:hover {
		color: ${Colors.newsBackground};
	}

	display: block;
	padding-bottom: 15px;
	font-size: 18px;
	line-height: 1.2;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 28px;
		line-height: 1.2;
	}
`;

const Sponsors = styled.div`
	display: flex;

	flex-direction: row;
	gap: var(--gridGutter);

	position: relative;

	margin-top: 50px;
	${Functions.breakpoint(Breakpoints.laptop)} {
		margin-top: 0px;
	}
`;

const SponsorImage = styled(Image)`
	width: 100%;
	/* margin: 0px 20px; */
	height: unset;
	padding: 0px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		/* padding: 20px; */
	}
	image {
		object-fit: cover;
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		border-radius: 20px;
		//background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #0b593b;
		mix-blend-mode: normal;
	}
`;

const SponsorButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const MoreSponsors = styled(SquaredLinkButton)`
	text-align: center;
	margin: 50px 0px;
	padding-left: 27px;
	padding-right: 27px;
	${Functions.breakpoint(Breakpoints.laptop)} {
		color: ${Colors.darkText};
	}
`;

const FooterSoMeBar = styled.div<{ bgColor?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: row;
	padding-bottom: 80px;
	z-index: 1;
	svg {
		path:first-of-type {
			//fill: white;
		}
		path {
			fill: ${props => props.bgColor || 'white'};
		}
	}
`;

const LinkContainer = styled.div`
	min-width: 90px;
	width: 90px;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 120px;
		height: 120px;
	}
`;

const StyledLink = styled(Link).withConfig({
	shouldForwardProp: prop => {
		if (prop === 'iconColor') return false;
		if (prop === 'secondaryIconColor') return false;
		return true;
	},
})<{ iconColor: string; secondaryIconColor: string }>`
	scale: 0.8;

	${Functions.breakpoint(Breakpoints.mobile)} {
		scale: 1;
	}
	svg > path {
		fill: ${props => props.secondaryIconColor};
	}
	svg > path:first-of-type {
		fill: ${props => props.iconColor};
	}
`;

const AllRights = styled.div`
	text-align: center;
	font-family: ${Fonts.Geomanist};
	font-size: 18px;
	padding-bottom: 50px;
	color: ${Colors.lightText};
	z-index: 1;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 100px;
		font-size: 14px;
	}
`;
const BreakerPlacer = styled.div<{ svgColor?: string }>`
	position: absolute;
	width: 102%;
	top: 1%;
	left: -1%;

	transform: translateY(-100%);

	svg > path {
		fill: ${props => props.svgColor};
	}
`;

export default function Footer() {
	const footer = Site.use(state => state.footer);

	return (
		<>
			<OutsideContainer>
				<BreakerPlacer svgColor={Functions.hexFromRGB(footer.backgroundColor?.color)}>
					<Breaker></Breaker>
				</BreakerPlacer>
				<FooterContainer
					bgColor={Functions.hexFromRGB(footer.backgroundColor?.color)}
					textColor={Functions.hexFromRGB(footer.backgroundColor?.contrastColor)}
				>
					<DesktopLinkList bgColor={Functions.hexFromRGB(footer.backgroundColor?.color)}>
						{footer.desktopLinkList.map((link, i) => (
							<DesktopLink key={i} data={link}>
								{link.title}
							</DesktopLink>
						))}
					</DesktopLinkList>
					<ColumnContainer bgColor={footer.backgroundColor?.color}>
						<div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyItems: 'center' }}>
							<Sponsors>
								{footer.sponsors.map((image, i) => (
									<SponsorImage data={image} lossless={true} key={i}></SponsorImage>
								))}
							</Sponsors>
							<SponsorButtonContainer>
								<MoreSponsors
									data={footer.sponsorLink[0]}
									backgroundColor={Functions.hexFromRGB(footer.backgroundColor?.contrastColor)}
									textColor={Functions.hexFromRGB(footer.backgroundColor?.color)}
								></MoreSponsors>
							</SponsorButtonContainer>
						</div>
					</ColumnContainer>
				</FooterContainer>
				<FooterSoMeBar bgColor={Functions.hexFromRGB(footer.backgroundColor?.color)}>
					<>
						{footer.socialMediaBar.map((entry, index) => {
							return (
								<LinkContainer key={'link_' + index}>
									<StyledLink
										key={index}
										data={{ link: entry.url }}
										iconColor={footer.backgroundColor && Functions.hexFromRGB(footer.backgroundColor?.contrastColor)}
										secondaryIconColor={footer.backgroundColor && Functions.hexFromRGB(footer.backgroundColor?.color)}
									>
										<SoMeIcon icon={entry.socialMediaChannel} />
									</StyledLink>
								</LinkContainer>
							);
						})}
					</>
				</FooterSoMeBar>
				<AllRights>Smukfest - All rights reserved</AllRights>
			</OutsideContainer>
		</>
	);
}
