import { BreakpointKey, Breakpoints } from '@style/Variables';
import CustomEvents, { Events } from '@core/CustomEvents';

export interface Breakpoint {
	name: string;
	pixels: string;
	width: number;
}

export default class ResizeHandler {
	private _breakpoints: Array<Breakpoint> = [];
	public get breakpoints() {
		return this._breakpoints;
	}
	private _currentBreakpoint: Breakpoint | undefined = undefined;
	public get currentBreakpoint() {
		return this._currentBreakpoint;
	}

	private static _instance: ResizeHandler | undefined = undefined;
	public static getInstance(): ResizeHandler {
		if (this._instance === undefined) this._instance = new ResizeHandler();

		return this._instance;
	}

	private _isIosDevice: boolean | string = false;
	public get isIosDevice() {
		return this._isIosDevice;
	}

	private _width: number = 0;
	private _height: number = 0;
	private _timer: any;
	private _isResizing: boolean = false;

	constructor() {
		this._width = window?.innerWidth || 0;

		for (const key in Breakpoints) {
			this._breakpoints.push({
				name: key,
				pixels: Breakpoints[key as BreakpointKey],
				width: parseInt(Breakpoints[key as BreakpointKey]),
			});
		}

		let lastBreakpoint: Breakpoint | undefined = undefined;

		for (let i = this._breakpoints.length - 1; i >= 0; i--) {
			const breakpoint = this._breakpoints[i];
			if (breakpoint.width < this._width) {
				this._currentBreakpoint = lastBreakpoint ?? breakpoint;
				break;
			}
			lastBreakpoint = breakpoint;
		}

		if (!this._currentBreakpoint) this._currentBreakpoint = this._breakpoints[0];

		// this._currentBreakpoint = this._breakpoints[0];
		this._isIosDevice =
			typeof window !== 'undefined' &&
			window.navigator &&
			window.navigator.platform &&
			(/iP(ad|hone|od)/.test(window.navigator.platform) ||
				(window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1));

		window.addEventListener('resize', this.handleResize);
		window.addEventListener('scroll', this.handleScroll, { passive: true });
		this.checkSize();
	}

	public unmount = () => {
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('scroll', this.handleScroll);
	};

	public checkSize = () => {
		this.handleScroll();
		this.handleResize();
	};

	private handleScroll = () => {
		const height = document?.documentElement?.scrollHeight;
		if (this._height === height && height > 0) return;
		this._height = height;
		if (!this._isResizing) {
			window.dispatchEvent(new Event('resize'));
			// CustomEvents.dispatch(Events.RESIZE);
		}
	};

	private handleResize = () => {
		if (typeof window == 'undefined') return;
		const width = window.innerWidth;
		if (this._width === width) return;
		this._width = width;

		this._isResizing = true;
		clearTimeout(this._timer);
		this._timer = setTimeout(this.innerResize, 200);
	};

	private innerResize = () => {
		this._timer = null;
		this._isResizing = false;

		for (let i = this._breakpoints.length - 1; i >= 0; i--) {
			const breakpoint = this._breakpoints[i];
			if (breakpoint.width < this._width) {
				if (this._currentBreakpoint !== breakpoint) {
					this._currentBreakpoint = breakpoint;
					CustomEvents.dispatch(Events.BREAKPOINT, breakpoint);
				}
				break;
			}
		}

		// CustomEvents.dispatch(Events.RESIZE);
	};

	public query = (key: string, minMax: 'min' | 'max' = 'min'): boolean => {
		const name = key;

		if (this._currentBreakpoint === undefined) return false;
		if (name.endsWith('px')) {
			const queryWidth = parseInt(name);
			return minMax === 'max' ? queryWidth < this._currentBreakpoint.width : queryWidth > this._currentBreakpoint.width;
		} else if (minMax === 'max') {
			const currentName = this._currentBreakpoint.name;
			// console.log(currentName);

			for (let i = this._breakpoints.length - 1; i >= 0; i--) {
				const breakpoint = this._breakpoints[i];
				if (breakpoint.name === name) return true;
				if (breakpoint.name === currentName) return false;
			}
		} else {
			const currentName = this._currentBreakpoint.name;

			for (let i = 0; i < this._breakpoints.length; i++) {
				const breakpoint = this._breakpoints[i];
				if (breakpoint.name === name) return true;
				if (breakpoint.name === currentName) return false;
			}
		}

		return false;
	};

	public disableScroll = () => {
		document.documentElement.style.overflow = 'hidden';
		document.body.style.overflow = 'scroll';

		// if (this._isIosDevice) {
		// 	document.body.style.position = 'fixed';
		// 	document.body.style.top = `-${window.scrollY}px`;
		// 	document.body.style.left = '0';
		// 	document.body.style.right = '0';
		// }
	};

	public enableScroll = () => {
		document.documentElement.style.removeProperty('overflow');
		document.body.style.removeProperty('overflow');

		const top = parseInt(document.body.style.top);

		// document.body.style.removeProperty('position');
		// document.body.style.removeProperty('top');
		// document.body.style.removeProperty('left');
		// document.body.style.removeProperty('right');

		if (top && top < 0) window.scrollTo(0, top * -1);
	};
}
