import React from 'react';
import styled from 'styled-components';
import { AnimationElementTreetop } from './animations/AnimationElementTreetop';
import { PapandSVG } from './PapandSVG';
import { Breakpoints, Colors, Fonts, Index } from '@client/style/Variables';
import { gsap } from 'gsap/all';
import { commonTextStyle } from '@client/common/CommonText';
import SquaredDefaultButton from './shared/SquaredDefaultButton';
import Functions from '@client/style/Functions';
import CloseIcon from '@client/assets/svgs/closeIcon.svg';
import Link, { ILinkData } from '@client/core/PageSwitch/Link';

const StyledContainer = styled.button`
	display: block;
	appearance: none;
	position: fixed;
	background: transparent;
	border: none;
	bottom: 20px;
	right: 0;

	width: 150px;
	height: 150px;
	z-index: ${Index.papand};
	cursor: pointer;
	${Functions.breakpoint(Breakpoints.laptop)} {
		right: 30px;
		bottom: 40px;
		transform: scale(1.5);
	}
`;

const StyledPapandSvg = styled(PapandSVG)`
	position: absolute;
	top: 0;
	right: 0;

	width: 150px;
	height: 150px;
	overflow: visible;
`;

const StyledExlamationSvg = styled.svg`
	position: absolute;
	top: -80px;
	left: -40px;
	width: 70px;
	height: 70px;
	overflow: visible;
`;

const StyledOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: ${Index.papand};
	display: flex;
	align-items: end;
	justify-content: center;
	padding-bottom: 150px;
`;
const StyledPopup = styled.div`
	position: relative;
	background: #f8f1eb;
	padding: 20px;
	margin-right: 15px;
	margin-left: 25px;
	display: flex;
	flex-direction: column;
	max-height: 90vh;
	align-items: center;
	${Functions.breakpoint(Breakpoints.laptop)} {
		padding: 80px;
		max-width: 1220px;
		max-height: 80vh;
	}
`;
const StyledCloseButton = styled.button`
	position: absolute;
	appearance: none;
	margin: 0;
	padding: 0;
	top: 20px;
	right: 20px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		display: block;
		rect {
			fill: ${Colors.darkText};
		}
	}
	${Functions.breakpoint(Breakpoints.laptop)} {
	}
`;
const StyledButtonContainer = styled.div`
	margin-top: 2em;
	display: none;
	${Functions.breakpoint(Breakpoints.laptop)} {
		display: block;
	}
`;
const StyledPopupMessage = styled.div`
	${commonTextStyle}
	text-align: left;
	width: 100%;
	overflow-y: auto;
	${Functions.breakpoint(Breakpoints.laptop)} {
		text-align: center;
	}
	a {
		font-weight: bold;
		text-decoration: underline;
	}
`;
const StyledPopupTitle = styled.h2`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 34px;
	margin: 0px;
	text-align: left;
	width: 100%;
	margin-bottom: 0.5em;
	padding-right: 40px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 55px;
		text-align: center;
	}
`;

export function Papand({
	message,
}: {
	message: { title: string; text: string; link: ILinkData[]; desktopButtonText: string };
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isHidden, setIsHidden] = React.useState(false);
	const containerRef = React.useRef<HTMLButtonElement>(null);

	if (isHidden) {
		return null;
	}

	const closeAndHide = () => {
		setIsOpen(false);

		gsap.to(containerRef.current, {
			x: '100%',
			y: '10%',
			duration: 0.8,
			ease: 'elastic.in(1.1, 0.8)',
			onComplete: () => {
				setIsHidden(true);
			},
		});
	};

	return (
		<>
			{isOpen && (
				<StyledOverlay>
					<StyledPopup>
						<StyledPopupTitle>{message.title}</StyledPopupTitle>
						<StyledCloseButton onClick={closeAndHide}>
							<CloseIcon />
						</StyledCloseButton>

						<StyledPopupMessage>
							{message.text + ' '}
							{message.link[0] && (
								<Link data={message.link[0]} customOnClick={() => closeAndHide()}>
									{message.link[0].title}
								</Link>
							)}
						</StyledPopupMessage>
						<StyledButtonContainer>
							<SquaredDefaultButton
								title={message.desktopButtonText}
								backgroundColor={Colors.darkBackground}
								onClick={closeAndHide}
							></SquaredDefaultButton>
						</StyledButtonContainer>
					</StyledPopup>
				</StyledOverlay>
			)}
			<StyledContainer onClick={() => setIsOpen(true)} ref={containerRef}>
				<PapandAnimation idleOnly={isOpen} />
			</StyledContainer>
		</>
	);
}

function PapandAnimation(props: { idleOnly: boolean }) {
	const idleOnlyRef = React.useRef<boolean>(props.idleOnly);
	const papandRef = React.useRef<SVGSVGElement>(null);
	const exclamationRef = React.useRef<SVGSVGElement>(null);

	React.useEffect(() => {
		idleOnlyRef.current = props.idleOnly;
	}, [props.idleOnly]);

	React.useEffect(() => {
		const query = gsap.utils.selector(papandRef.current);
		const exclamationQuery = gsap.utils.selector(exclamationRef.current);

		const quack = gsap.timeline({
			repeat: -1,
			repeatDelay: 0,
		});

		// Quack
		quack.fromTo(
			papandRef.current,
			{
				y: 0,
			},
			{
				y: -25,
				repeat: 1,
				yoyo: true,
				duration: 1,
				ease: 'elastic.inOut(1.2, 0.6)',
			},
			0
		);
		quack.fromTo(
			query('#beak-top'),
			{
				y: 0,
				x: 0,
				skewY: 0,
				skewX: 0,
				transformOrigin: 'center right',
			},
			{
				skewY: 25,
				skewX: 5,
				scaleX: 0.9,
				x: 7,
				scaleY: 0.4,
				y: -15,
				repeat: 1,
				yoyo: true,
				duration: 1,
				ease: 'elastic.inOut(1.2, 0.4)',
			},
			0
		);
		quack.fromTo(
			query('#beak-bottom'),
			{
				y: 0,
				x: 0,
				skewX: 0,
				scaleX: 1,
				transformOrigin: 'center right',
			},
			{
				scaleX: 0.9,
				skewX: 10,
				y: -18,
				x: 7,
				repeat: 1,
				yoyo: true,
				duration: 1,
				ease: 'elastic.inOut(1.2, 0.4)',
			},
			0
		);

		quack.fromTo(
			query('#eye'),
			{
				y: 0,
				x: 0,
				scale: 1,
				transformOrigin: 'center',
			},
			{
				scale: 1.2,
				x: 5,
				y: -3,
				repeat: 1,
				yoyo: true,
				duration: 1,
				ease: 'elastic.inOut(1, 1)',
			},
			0
		);
		quack.fromTo(
			query('#iris'),
			{
				y: 0,
				x: 0,
				scale: 1,
				transformOrigin: 'center',
			},
			{
				scale: 0.6,
				x: -8,
				y: 2,
				repeat: 1,
				yoyo: true,
				duration: 1,
				ease: 'elastic.inOut(1, 0.8)',
			},
			0
		);

		quack.fromTo(
			query('#wing'),
			{
				y: 0,
			},
			{
				y: -9,
				yoyo: true,
				repeat: 1,
				duration: 1,
				ease: 'elastic.inOut(1.2, 0.5)',
			},
			0
		);
		quack.fromTo(
			query('#wing'),
			{
				rotate: 0,
				skewX: 0,
				skewY: 0,
				scaleX: 1,
				x: 0,
				transformOrigin: 'left',
			},
			{
				rotate: -5,
				skewX: 30,
				skewY: 5,
				scaleX: 1.2,
				x: 14,
				repeat: 3,
				yoyo: true,
				delay: 0.5,
				duration: 0.25,
				ease: 'back.out(1.3)',
			},
			0
		);

		quack.fromTo(
			query('#body'),
			{
				skewY: 0,
				skewX: 0,
				scaleY: 1,
				scaleX: 1,
				transformOrigin: 'bottom',
			},
			{
				skewY: -2,
				skewX: -2,
				scaleY: 1.05,
				scaleX: 0.95,
				yoyo: true,
				repeat: 1,
				duration: 1,
				ease: 'elastic.inOut(1.2, 0.5)',
			},
			0
		);

		// Exlamation
		quack.fromTo(
			[exclamationQuery('#exclamation-1'), exclamationQuery('#exclamation-2')],
			{
				y: 4,
				scale: 0.5,
				opacity: 0,
				transformOrigin: 'bottom',
			},
			{
				scale: 1,
				y: 0,
				x: 0,
				opacity: 1,
				stagger: 0.1,
				repeat: 1,
				yoyo: true,
				delay: 0.6,
				duration: 0.2,
				repeatDelay: 0.5,
				ease: 'elastic.out(1.2, 0.9)',
			},
			0
		);
		quack.fromTo(
			exclamationQuery('#cloud'),
			{
				rotate: -5,
				scale: 0.8,
				opacity: 0,
				transformOrigin: 'center',
			},
			{
				opacity: 1,
				rotate: 0,
				scale: 1,
				repeat: 1,
				yoyo: true,
				delay: 0.5,
				duration: 0.2,
				repeatDelay: 0.8,
				ease: 'elastic.out(1.2, 0.9)',
			},
			0
		);
		quack.fromTo(
			[exclamationQuery('#circle-1'), exclamationQuery('#circle-2')],
			{
				scale: 0,
				transformOrigin: 'center',
			},
			{
				scale: 1,
				repeat: 1,
				yoyo: true,
				stagger: 0.2,
				delay: 0,
				duration: 0.4,
				repeatDelay: 0.3,
				ease: 'elastic.out(2, 0.9)',
			},
			0
		);

		quack.addLabel('idle-begin');

		// Eye idle
		quack.fromTo(
			query('#iris'),
			{
				y: 0,
				x: 0,
				transformOrigin: 'center',
			},
			{
				keyframes: {
					x: [0, -7, -6, -8, 0],
					y: [0, 2, -1, 3, 0],
					easeEach: 'elastic.inOut(1.1,1)',
				},
				duration: 4,
			},
			'idle-begin'
		);

		quack.fromTo(
			papandRef.current,
			{
				skewY: 0,
				skewX: 0,
				scaleY: 1,
				scaleX: 1,
				transformOrigin: 'bottom',
			},
			{
				keyframes: {
					scaleX: [1, 1, 1.025, 0.98],
					scaleY: [1, 1, 0.98, 1.025],
					// easeEach: 'elastic.inOut(1.1,1)',
					easeEach: 'power3.out',
				},
				repeat: 7,
				yoyo: true,
				duration: 0.5,
				// ease: 'sine.inOut',
			},
			'idle-begin'
		);

		quack.call(() => {
			if (idleOnlyRef.current) {
				quack.play('idle-begin');
			}
		});

		return () => {
			quack.kill();
		};
	}, []);

	return (
		<>
			<AnimationElementTreetop
				variant={3}
				position={{
					breakpoints: {
						tiny: {
							width: '300px',
						},
						mobile: {
							width: '300px',
						},
						desktop: {
							width: '300px',
						},
						largeDesktop: {
							width: '300px',
						},
						ultra: {
							width: '300px',
						},
					},
				}}
			/>

			<StyledExlamationSvg ref={exclamationRef} width='110' height='115' viewBox='0 0 110 115' fill='none'>
				<path
					id='cloud'
					d='M67.7366 83.6563C70.248 88.0445 69.5435 97.8447 65.8514 101.3C62.1593 104.756 56.0471 105.265 51.8266 102.474C47.6127 99.6769 45.7014 93.8542 47.4496 89.1073C46.295 94.3954 41.5266 98.6858 36.145 99.2857C30.7634 99.8855 25.1665 96.7492 22.8703 91.8459C20.5742 86.9426 21.7549 80.6374 25.6688 76.8947C19.55 80.37 11.2852 79.568 5.94274 74.9907C0.600275 70.4134 -1.448 62.3738 1.05037 55.7947C3.54874 49.2222 10.4242 44.5666 17.4626 44.697C12.081 36.703 13.1965 24.9729 19.9806 18.133C26.7647 11.2931 38.4933 10.0803 46.5363 15.3879C48.2976 8.07202 54.5011 2.01459 61.8592 0.430135C69.2174 -1.15432 77.3648 1.79942 81.9897 7.73949C86.6146 13.6796 87.4822 22.2995 84.1358 29.0416C91.9962 29.0025 99.5631 34.5774 101.859 42.0954C104.155 49.6134 100.985 58.455 94.4424 62.8106C99.5696 64.3038 101.677 71.0328 99.7784 76.0275C97.8801 81.0156 93.1965 84.3996 88.3954 86.7404C84.886 88.4488 81.0634 89.7789 77.1691 89.5442C73.2748 89.3095 69.3087 87.223 67.7301 83.6563H67.7366Z'
					fill='#F8F1EB'
				/>
				<path
					id='exclamation-2'
					d='M67.1364 61.2069C66.2818 58.8139 67.4886 56.1731 69.8826 55.3124C72.2766 54.4517 74.9381 55.7102 75.7926 58.1032C76.6341 60.4505 75.3816 63.1108 72.9877 63.965C70.5937 64.8191 67.9779 63.5542 67.1364 61.2003V61.2069ZM73.079 51.661L65.1338 54.5104L55.2251 29.0418L64.5402 25.7034L73.079 51.661Z'
					fill='#0B593B'
				/>
				<path
					id='exclamation-1'
					d='M55.1009 79.9269C53.581 77.886 53.9724 75.004 56.0142 73.4913C58.0559 71.972 60.9652 72.4023 62.4786 74.4432C63.992 76.4841 63.5419 79.3531 61.5001 80.8723C59.4584 82.3916 56.5882 81.9351 55.1009 79.9334V79.9269ZM58.0103 69.0639L51.2392 74.0976L34.3442 52.613L42.2829 46.712L58.0103 69.0639Z'
					fill='#0B593B'
				/>
				<path
					id='circle-1'
					d='M105.212 115.006C107.856 115.006 110 112.864 110 110.221C110 107.577 107.856 105.435 105.212 105.435C102.567 105.435 100.424 107.577 100.424 110.221C100.424 112.864 102.567 115.006 105.212 115.006Z'
					fill='#F8F1EB'
				/>
				<path
					id='circle-2'
					d='M93.0139 106.289C97.3983 106.289 100.953 102.736 100.953 98.3535C100.953 93.971 97.3983 90.4182 93.0139 90.4182C88.6295 90.4182 85.0752 93.971 85.0752 98.3535C85.0752 102.736 88.6295 106.289 93.0139 106.289Z'
					fill='#F8F1EB'
				/>
			</StyledExlamationSvg>

			<StyledPapandSvg ref={papandRef} />

			<AnimationElementTreetop
				variant={2}
				position={{
					y: '30%',
					flip: true,
					breakpoints: {
						tiny: {
							width: '300px',
						},
						mobile: {
							width: '300px',
						},
						desktop: {
							width: '300px',
						},
						largeDesktop: {
							width: '300px',
						},
						ultra: {
							width: '300px',
						},
					},
				}}
			/>
		</>
	);
}
