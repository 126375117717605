import CustomEvents, { Events } from '@client/core/CustomEvents';
import { matchBreakpoint } from '@client/core/matchBreakpoint';
import { BreakpointKey, Breakpoints } from '@client/style/Variables';
import gsap, { CustomEase, ScrollTrigger } from 'gsap/all';
import React from 'react';
import styled from 'styled-components';

gsap.registerPlugin(CustomEase);

function urlWithParams(url: URL | string, params: { [key: string]: string | number | undefined }) {
	const urlObject = new URL(`${url}`);
	const urlParams = urlObject.searchParams;

	Object.keys(params).forEach(key => {
		const value = params[key];
		if (value !== undefined) {
			urlParams.set(key, typeof value === 'number' ? value.toString() : value);
		}
	});

	return urlObject;
}

type ResponsiveKey = BreakpointKey | 'width' | 'height';

type responsiveSize = {
	[Property in ResponsiveKey]?: number;
};

export class AnimationImageAsset {
	src: string;
	srcSet: string;
	width: number | undefined;
	height: number | undefined;
	breakpoints: responsiveSize = {};
	sizes: string;
	constructor(url: string, opts: responsiveSize & { width?: number; height?: number }) {
		this.src = urlWithParams(url, {
			auto: 'format',
			lossless: '1',
			fit: 'max',
		}).href;

		this.width = opts.width;
		this.height = opts.height;

		const breakpoints = Object.keys(opts) as ResponsiveKey[];

		const srcSet: string[] = [];
		const sizes: string[] = [];

		// Generate 1x/2x/3x dpr sources for each width
		breakpoints.forEach(breakpoint => {
			if (breakpoint === 'height') return;

			const width = opts[breakpoint as BreakpointKey];
			if (!width) return;
			srcSet.push(urlWithParams(this.src, { w: width * 1 }).href + ` ${width * 1}w`);
			srcSet.push(urlWithParams(this.src, { w: width * 2 }).href + ` ${width * 2}w`);
			srcSet.push(urlWithParams(this.src, { w: width * 3 }).href + ` ${width * 3}w`);

			if (breakpoint !== 'width') {
				this.breakpoints[breakpoint] = width;
				sizes.push(`(min-width: ${Breakpoints[breakpoint]}) ${opts[breakpoint]}px`);
			}
		});

		this.srcSet = srcSet.join(',');
		this.sizes = sizes.join(',');
	}
}

const StyledAnimationPositionElement = styled.div`
	position: absolute;
	/* top: 0; */
`;

export interface AnimationElementPosition {
	origin?: string;
	anchor?: 'left' | 'right' | 'center';
	anchorY?: 'top' | 'bottom' | 'center';
	x?: number | string;
	y?: number | string;
	scale?: number;
	angle?: number;
	flip?: boolean;
	flipY?: boolean;
	width?: string;
	height?: string;
	breakpoints?: ResponsiveAnimationElementPosition;
}

export type ResponsiveAnimationElementPosition = {
	[Property in ResponsiveKey]?: AnimationElementPosition;
};

export function mergePositions(...positions: AnimationElementPosition[]) {
	let base: AnimationElementPosition & { breakpoints: ResponsiveAnimationElementPosition } = {
		breakpoints: {},
	};

	for (const position of positions) {
		// Merge base values
		const { breakpoints, ...rest } = position;
		base = { ...base, ...rest };

		// Merge breakpoint values
		for (const breakpoint in breakpoints) {
			base.breakpoints[breakpoint as ResponsiveKey] = {
				...base.breakpoints[breakpoint as ResponsiveKey],
				...breakpoints[breakpoint as ResponsiveKey],
			};
		}
	}

	return base;
}

/**
 * Wrapper component for positioning/scaling/rotating animation elements.
 */
export const AnimationPositionElement = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<{
		position?: AnimationElementPosition;
	}>
>(function AnimationElement(props, ref) {
	const changeListenerIdRef = React.useRef<number>(0);

	const setPosition = React.useMemo(() => {
		return (element: HTMLElement, position: AnimationElementPosition, breakpointMatches?: any) => {
			let newPosition = position;
			for (const breakpoint in position.breakpoints) {
				if (breakpointMatches[breakpoint]) {
					newPosition = { ...newPosition, ...position.breakpoints[breakpoint as ResponsiveKey] };
				}
			}

			gsap.set(element, {
				transformOrigin: newPosition.origin,
				x: newPosition.x || 0,
				y: newPosition.y || 0,
				[newPosition.anchor === 'right' ? 'right' : 'left']: newPosition.anchor === 'center' ? '50%' : 0,
				[newPosition.anchorY === 'bottom' ? 'bottom' : 'top']: newPosition.anchorY === 'center' ? '50%' : 0,

				scaleX: (newPosition.scale || 1) * (newPosition.flip ? -1 : 1),
				scaleY: (newPosition.scale || 1) * (newPosition.flipY ? -1 : 1),
				rotate: newPosition.angle,
				width: newPosition.width,
				height: newPosition.height,
			});
		};
	}, []);

	const refFunction = (element: HTMLDivElement) => {
		if (ref) {
			if (typeof ref === 'function') {
				ref(element);
			} else {
				ref.current = element;
			}
		}

		if (element !== null) {
			if (props.position) {
				setPosition(element, props.position, matchBreakpoint.breakpoints);

				if (props.position.breakpoints) {
					const position = props.position;
					changeListenerIdRef.current = matchBreakpoint.addChangeListener(breakpointMatches => {
						setPosition(element, position, breakpointMatches);
					});
				}
			}
		} else {
			matchBreakpoint.removeChangeListener(changeListenerIdRef.current);
		}
	};

	return <StyledAnimationPositionElement ref={refFunction}>{props.children}</StyledAnimationPositionElement>;
});

const StyledAnimationImage = styled.img<{ $breakpoints: responsiveSize }>`
	display: block;
	${props =>
		Object.keys(props.$breakpoints).map(breakpoint => {
			const key = breakpoint as ResponsiveKey;
			if (key === 'width' || key === 'height') return '';
			return `@media (min-width: ${Breakpoints[key]}) {
				width: ${props.$breakpoints[key]}px;
			};`;
		})}

	${StyledAnimationPositionElement} & {
		max-width: 100%;
	}
`;

/**
 * Renders an `<img>` element with data from an `AnimationImageAsset` object
 */
export const AnimationImageElement = React.forwardRef<
	HTMLImageElement,
	{
		image: AnimationImageAsset;
		className?: string;
	}
>(function AnimationElement(props, ref) {
	return (
		<StyledAnimationImage
			className={props.className}
			ref={ref}
			width={props.image.width}
			height={props.image.height}
			src={props.image.src}
			srcSet={props.image.srcSet}
			sizes={props.image.sizes}
			$breakpoints={props.image.breakpoints}
		>
			{props.children}
		</StyledAnimationImage>
	);
});

// Animation container
const StyledAnimationContainer = styled.div<{
	$size: { width?: number; height?: number };
	$breakpoints?: ResponsiveProps;
}>`
	pointer-events: none;
	user-select: none;
	position: absolute;
	width: 100%;
	height: 100%;
	${({ $size }) =>
		$size.width &&
		$size.height &&
		`
		position: relative;
		height: auto;
		max-width: ${$size.width}px;
		aspect-ratio: 1/${$size.height / $size.width};
	`}

	${({ $breakpoints }) =>
		$breakpoints &&
		Object.keys($breakpoints).map(breakpoint => {
			const key = breakpoint as ResponsiveKey;
			if (key === 'width' || key === 'height') return '';
			const breakpointSize = $breakpoints[key];
			return `
			@media (min-width: ${Breakpoints[key]}) {
				${breakpointSize?.width && `max-width: ${breakpointSize.width}px;`}
				${breakpointSize?.height && breakpointSize?.width && `aspect-ratio: 1/${breakpointSize.height / breakpointSize.width};`}
			}
		`;
		})}
	top: 0;
	left: 0;
`;

const animationContainerContext = React.createContext<gsap.core.Timeline>(gsap.timeline());

type ResponsiveProps = {
	[Property in keyof typeof Breakpoints]?: { width?: number; height?: number };
};

/**
 * Used for wrapping animation elements. Sets role="img" so they are presented as a single image.
 * Provides a shared timeline for child animations that pauses while out of view and during page transitions.
 * Defaults to position: absolute and filling its parent.
 * Setting a width and height changes it to position: relative.
 */
export const AnimationContainer = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<{ className?: string; width?: number; height?: number; breakpoints?: ResponsiveProps }>
>(function AnimationContainer(props, ref) {
	const timelineRef = React.useRef<gsap.core.Timeline>(gsap.timeline());
	const containerRef = React.useRef<HTMLDivElement>();
	const scrollTriggerRef = React.useRef<ScrollTrigger>();

	const refFunction = (element: HTMLDivElement) => {
		if (ref) {
			if (typeof ref === 'function') {
				ref(element);
			} else {
				ref.current = element;
			}
		}
		containerRef.current = element;
	};

	React.useEffect(() => {
		const pauseTimeline = () => {
			timelineRef.current.pause();
		};

		const playTimeline = () => {
			if (scrollTriggerRef.current?.isActive) {
				timelineRef.current.restart();
			}
		};

		if (containerRef.current !== null) {
			// Pause/Restart animation during page transitions
			CustomEvents.listen(Events.TRANSITIONOUT, pauseTimeline);
			CustomEvents.listen(Events.TRANSITIONIN, playTimeline);

			// Pause/Restart animation when entering/exiting viewport
			scrollTriggerRef.current = ScrollTrigger.create({
				animation: timelineRef.current,
				trigger: containerRef.current,
				toggleActions: 'restart pause restart pause',
			});
		}
		return () => {
			scrollTriggerRef.current?.kill();
			CustomEvents.remove(Events.TRANSITIONOUT, pauseTimeline);
			CustomEvents.remove(Events.TRANSITIONIN, playTimeline);
		};
	}, []);

	return (
		<StyledAnimationContainer
			ref={refFunction}
			role='img'
			className={props.className}
			$size={{ width: props.width, height: props.height }}
			$breakpoints={props.breakpoints}
		>
			<animationContainerContext.Provider value={timelineRef.current}>
				{props.children}
			</animationContainerContext.Provider>
		</StyledAnimationContainer>
	);
});

/**
 * Returns a parent timeline used to play/pause multiple child animations
 */
export const useAnimationContainerTimeline = () => {
	return React.useContext(animationContainerContext);
};

/**
 * Create an easing curve that slowly speeds up and slows down.
 * Useful for looping animations.
 * @param intensity 0 = linear, 1 = circular
 */
export function WaveEase(intensity = 0.5) {
	const angle = 0.5 + 0.5 * intensity; // 0.5 = linear
	const invertedAngle = 1 - angle;
	const length = Math.min(0.5 * intensity, 0.5); // Limit length to 0.5

	const curvePoints = [
		// Start point
		'M0,0',
		'C' + [length * angle, length * invertedAngle],

		// Middle point
		[0.5 - length * invertedAngle, 0.5 - length * angle],
		'0.5,0.5',
		[0.5 + length * invertedAngle, 0.5 + length * angle],

		// End point
		[1 - length * angle, 1 - length * invertedAngle],
		'1,1',
	];

	return CustomEase.create('custom', curvePoints.join(' '));
}
