import { IAzureAuthConfig } from '@client/core/auth/azure/IAzureAuthConfig';

export interface ISmukConfig {
	authConfig: IAzureAuthConfig;
	api: string;
}

// staging
const stageConfig: ISmukConfig = {
	api: 'https://staging.smukcrew.dk/api/',
	authConfig: {
		clientId: '9f44e7de-a8d5-4d73-b0e4-aec799347864',
		authServer: 'https://festiweb.b2clogin.com/festiweb.onmicrosoft.com/',
		// redirectURL: 'https://smukfest-user-authentic-vqb60w.herokuapp.com/auth/login-done',
		redirectURL: 'https://smukfest-stage.herokuapp.com/auth/login-done',
		scopes: [
			'openid',
			'offline_access',
			'https://festiweb.onmicrosoft.com/94fee9f4-ee37-4d60-84ba-cdbf6e154c53/API.Access',
		],
		policy: 'B2C_1_QA_SignUp_SignIn',
		defaultRedirectPage: '/',
		debugTools: true,
		log: true,
	},
};

// live
const liveConfig: ISmukConfig = {
	api: 'https://www.smukcrew.dk/api/',
	authConfig: {
		clientId: 'c3c243e2-78a3-41e6-9bf4-40d81a017231',
		authServer: 'https://festiweb.b2clogin.com/festiweb.onmicrosoft.com/',
		redirectURL: 'https://www.smukfest.dk/auth/login-done',
		scopes: [
			'openid',
			'offline_access',
			'https://festiweb.onmicrosoft.com/d75e6e10-8d66-43b9-a85d-699aca5fdf0c/API.Access',
		],
		policy: 'B2C_1_PROD_SignUp_SignIn',
		defaultRedirectPage: '/',
		debugTools: true,
		log: false,
	},
};

const stagingDomains = ['localhost', '0.0.0.0', 'smukfest-user-authentic-vqb60w.herokuapp', 'smukfest-stage.herokuapp'];

const useStaging = () => {
	if (typeof window === 'undefined') {
		return false;
	}

	console.log('host: ' + window.location.host);
	for (const domain of stagingDomains) {
		if (window.location.host.startsWith(domain)) return true;
	}
	return false;
};

const config = useStaging() ? stageConfig : liveConfig;

// console.log('config');
// console.dir(config);

export default config;
