import gsap from 'gsap';
import { InertiaPlugin, Draggable } from 'gsap/all';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import { useHammer } from './UseHammer';

import OverlayAll from '@client/assets/map/overlays/all-2025.svg';
const imgSrc = '/assets/map/background-2025.png';

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const MapContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const ImageWrapper = styled.div`
	width: fit-content;
	height: fit-content;
`;

const SvgWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
`;

export default function MapModule({
	onLocationSelect,
	startFocus = { x: -1975, y: -809 },
	offsetX = 0,
	offsetY = 0,
}: {
	onLocationSelect: (id: string) => void;
	startFocus: { x: number; y: number };
	offsetX?: number;
	offsetY?: number;
}) {
	const isMobile = useIsMobile('mobile');
	const mapContainerRef = React.useRef<HTMLDivElement | null>(null);
	const mapRef = React.useRef<HTMLDivElement | null>(null);
	const [hammerLoaded, Hammer] = useHammer();
	const [draggable, setDraggable] = React.useState<Draggable[]>([]);

	const zoom = {
		mobileCenter: { x: 0, y: 0 },
		oldZoom: 1,
		value: 1,
		min: isMobile ? 0.3 : 0.7,
		max: 2,
		step: 1,
		factor: 1.1,
	};

	const testImg = React.useRef<HTMLImageElement | null>(null);
	const testSvg = React.useRef<HTMLDivElement | null>(null);

	const updateBounds = () => {
		if (!mapContainerRef.current) return;
		if (!mapRef.current) return;

		const containerWidth = mapContainerRef.current.offsetWidth + offsetX;
		const containerHeight = mapContainerRef.current.offsetHeight + offsetY;
		const dx = containerWidth - mapRef.current.offsetWidth * zoom.value;
		const dy = containerHeight - mapRef.current.offsetHeight * zoom.value;

		const width = containerWidth - dx * 2;
		const height = containerHeight - dy * 2;

		draggable[0]?.applyBounds({
			left: dx,
			top: dy,
			width: width,
			height: height,
		});
	};

	useEffect(() => {
		if (!mapContainerRef.current) return;
		if (!mapRef.current) return;

		gsap.registerPlugin(Draggable, InertiaPlugin);
		setDraggable(
			Draggable.create(mapRef.current, {
				trigger: mapContainerRef.current,
				bounds: mapContainerRef.current,
				inertia: true,
			})
		);

		updateBounds();
		return () => {
			draggable[0]?.kill();
		};
	}, [mapRef, mapContainerRef, hammerLoaded]);

	useEffect(() => {
		if (!mapContainerRef.current) return;
		if (!mapRef.current) return;

		gsap.set(mapRef.current, {
			scale: zoom.value,
			transformOrigin: 'left top',
			transform: `translate3d(${startFocus.x}px, ${startFocus.y}px, 0px)`,
		});

		const props = gsap.getProperty(mapRef.current);

		function wheelAction(event: WheelEvent) {
			event.preventDefault();
			const oldZoom = zoom.value;
			const wheel = event.deltaY / 100;

			if (wheel > 0) {
				zoom.value /= zoom.factor;
			} else {
				zoom.value *= zoom.factor;
			}

			zoom.value = gsap.utils.clamp(zoom.min, zoom.max, zoom.value);

			changeZoom(zoom.value - oldZoom, event);
		}

		const pinchAction = (event: HammerInput) => {
			event.preventDefault();

			const oldZoom = zoom.value;

			const scale = event.scale;
			zoom.value = scale * zoom.oldZoom;

			zoom.value = gsap.utils.clamp(zoom.min, zoom.max, zoom.value);

			changeZoom(zoom.value - oldZoom, { clientX: zoom.mobileCenter.x, clientY: zoom.mobileCenter.y });
		};

		function changeZoom(zoomDelta: number, clientPos: { clientX: number; clientY: number }) {
			if (!mapContainerRef.current) return;
			const scale = props('scaleX');
			let x = props('x') as number;
			let y = props('y') as number;

			const rect = mapContainerRef.current.getBoundingClientRect();
			const globalX = clientPos.clientX - rect.left;
			const globalY = clientPos.clientY - rect.top;

			const localX = (globalX - (x as number)) / (scale as number);
			const localY = (globalY - (y as number)) / (scale as number);

			x += -(localX * zoomDelta);
			y += -(localY * zoomDelta);

			gsap.set(mapRef.current, {
				scale: zoom.value,
				x: x,
				y: y,
			});

			updateBounds();
		}

		mapContainerRef.current.addEventListener('wheel', wheelAction);
		let hammer: HammerManager;
		if (hammerLoaded) {
			hammer = new Hammer!(mapRef.current);

			hammer.get('pinch').set({ enable: true });
			hammer.on('tap', function (ev) {
				onLocationSelect(ev.target.dataset.hash ?? '');
			});
			hammer.on('pinchstart', function (ev) {
				draggable[0]?.disable();
				zoom.mobileCenter = { x: ev.center.x, y: ev.center.y };
			});
			hammer.on('pinchmove', pinchAction);
			hammer.on('pinchend', function (_) {
				zoom.oldZoom = zoom.value;
				draggable[0]?.enable();
			});
		}

		updateBounds();

		return () => {
			if (hammer) {
				hammer.off('tap');
				hammer.off('pinchstart');
				hammer.off('pinchmove');
				hammer.off('pinchend');
			}

			mapContainerRef.current?.removeEventListener('wheel', wheelAction);
		};
	}, [isMobile, hammerLoaded]);

	useEffect(() => {
		window.addEventListener('resize', updateBounds);
		updateBounds();
		return () => {
			window.removeEventListener('resize', updateBounds);
		};
	}, [offsetX, offsetY, mapContainerRef, mapRef]);

	return (
		<Container>
			<MapContainer ref={mapContainerRef} style={{ position: 'relative' }}>
				<ImageWrapper ref={mapRef}>
					<img ref={testImg} id='testImage' style={{ pointerEvents: 'none' }} src={imgSrc}></img>
					<SvgWrapper ref={testSvg}>
						<OverlayAll />
					</SvgWrapper>
				</ImageWrapper>
			</MapContainer>
		</Container>
	);
}
