import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';
import { Colors, presetColors } from '@client/style/Variables';
import { AnimationElementTreetop } from '../AnimationElementTreetop';
import { AnimationElementLamps } from '../AnimationElementLamps';
import { gsap } from 'gsap';

import Functions from '@client/style/Functions';
import SquaredLinkButton from '@client/modules/shared/SquaredLinkButton';
import { FlyingCanMessage } from './FlyingCanMessage';

const StyledAnimationContainer = styled(AnimationContainer)`
	background-color: ${presetColors.dust};
	img {
		max-width: 100%;
		height: auto;
	}
	margin-bottom: -15%;

	${Functions.breakpoint('mobile')} {
		margin-bottom: -10%;
	}
`;

const BackPortalContainer = styled.div`
	width: 90vw;
	svg {
		width: 100%;
		height: auto;
	}
	${Functions.breakpoint('mobile')} {
		width: 50vw;
	}
`;

const FrontPortalContainer = styled.div`
	width: 75vw;
	svg {
		width: 100%;
		height: auto;
	}

	${Functions.breakpoint('mobile')} {
		width: 42vw;
	}
`;
const StarsContainer = styled.div`
	width: 55vw;
	svg {
		width: 100%;
		height: auto;
	}
	${Functions.breakpoint('mobile')} {
		width: 34vw;
	}
`;

const RainbowContainer = styled.div`
	width: 60vw;
	svg {
		width: 100%;
		height: auto;
	}
`;

const ButtonPlacer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 114%;
	${Functions.breakpoint('mobile')} {
		padding-top: 33%;
	}
`;
const MobileSpan = styled.span`
	display: block;
	${Functions.breakpoint('mobile')} {
		display: none;
	}
`;
const DesktopSpan = styled.span`
	display: none;
	${Functions.breakpoint('mobile')} {
		display: block;
	}
`;

const backPortalSVG = (
	<svg width='343' height='462' viewBox='0 0 343 462' fill='none'>
		<path
			d='M73.4378 72.7713C111.179 68.6125 101.563 47.3527 132.113 21.7092C164.462 -12.7487 229.194 -4.56521 242.972 38.8042C256.751 82.1736 294.8 68.0377 327.44 118.414C354.353 159.952 327.105 210.932 322.312 259.054C317.52 307.177 351.182 332.894 340.5 390.551C327.44 461.051 284.753 475.952 235.877 447.573C189.678 420.748 134.659 424.373 80.144 438.631C25.6295 452.89 31.5037 410.712 8.1403 358.431C-15.2231 306.15 23.7158 280.009 33.8999 250.304C44.084 220.599 11.7347 210.914 8.1403 165.168C4.54594 119.422 35.6971 76.93 73.4378 72.7713Z'
			fill='#FFBFB0'
		/>
	</svg>
);
const frontPortalSVG = (
	<svg width='275' height='360' viewBox='0 0 275 360' fill='none'>
		<path
			d='M22.8574 318.374C33.325 330.412 64.728 350.721 91.9439 337.636C168.881 312.514 152.656 354.384 221.219 359.618C289.782 364.852 282.978 267.326 251.576 239.063C220.173 210.8 225.155 192.915 251.576 154.623C267.277 131.866 267.223 98.9927 243.23 75.572C219.658 52.5628 209.182 54.4863 194.527 20.9898C179.872 -12.5067 132.244 -2.03901 118.113 26.747C103.982 55.5331 78.3359 55.0097 42.7459 57.6266C7.15592 60.2435 -2.29126 111.081 10.2699 133.063C22.8311 155.045 33.8221 191.043 10.2699 223.492C-13.2823 255.942 12.3897 306.336 22.8574 318.374Z'
			fill='#262C2E'
		/>
	</svg>
);
const stars = (
	<svg width='206' height='321' viewBox='0 0 206 321' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clipPath='url(#clip0_551_2794)'>
			<path
				d='M143.62 292.221L142.762 287.954L141.903 292.221C141.657 293.445 140.663 294.383 139.42 294.564L136.29 295.019L139.588 295.566C140.81 295.768 141.777 296.703 142.015 297.912L142.762 301.706L143.508 297.912C143.746 296.703 144.713 295.768 145.935 295.566L149.233 295.019L146.103 294.564C144.86 294.383 143.866 293.445 143.62 292.221H143.62Z'
				fill='white'
				className='star'
			/>
		</g>
		<g clipPath='url(#clip1_551_2794)'>
			<path
				d='M54.7232 66.8377L53.8651 62.5713L53.0069 66.8377C52.7606 68.0621 51.7666 69.0002 50.524 69.1811L47.3936 69.6365L50.6914 70.183C51.9134 70.3854 52.8806 71.3206 53.1186 72.5294L53.8651 76.3232L54.6116 72.5294C54.8494 71.3206 55.8167 70.3856 57.0387 70.183L60.3366 69.6365L57.2063 69.1811C55.9637 69.0002 54.9695 68.0621 54.7233 66.8377H54.7232Z'
				fill='white'
				className='star'
			/>
		</g>
		<circle cx='90.7515' cy='33.7271' r='1.10795' fill='white' className='star' />
		<circle cx='64.899' cy='302.814' r='1.10795' fill='white' className='star' />
		<circle cx='98.065' cy='278.546' r='1.10795' fill='white' className='star' />
		<circle cx='65.7076' cy='253.469' r='1.10795' fill='white' className='star' />
		<circle cx='139.321' cy='254.278' r='1.10795' fill='white' className='star' />
		<circle cx='171.678' cy='318.993' r='1.10795' fill='white' className='star' />
		<circle cx='131.198' cy='69.4468' r='1.10795' fill='white' className='star' />
		<circle cx='204.407' cy='90.7575' r='1.51242' fill='white' className='star' />
		<circle cx='179.33' cy='65.68' r='0.703476' fill='white' className='star' />
		<circle cx='138.883' cy='0.965195' r='0.703476' fill='white' className='star' />
		<circle cx='1.36363' cy='49.5013' r='0.703476' fill='white' className='star' />
		<circle cx='9.45348' cy='81.8587' r='0.703476' fill='white' className='star' />
		<defs>
			<clipPath id='clip0_551_2794'>
				<rect width='12.943' height='13.752' fill='white' transform='translate(136.29 287.954)' className='star' />
			</clipPath>
			<clipPath id='clip1_551_2794'>
				<rect width='12.943' height='13.752' fill='white' transform='translate(47.3936 62.5713)' className='star' />
			</clipPath>
		</defs>
	</svg>
);
const starsDesktop = (
	<svg width='965' height='662' viewBox='0 0 965 662' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='787.28' cy='512.634' r='2.5' fill='white' className='star' />
		<circle cx='668.28' cy='635.634' r='2.5' fill='white' className='star' />
		<circle cx='43.9178' cy='84.6338' r='2.5' fill='white' className='star' />
		<circle cx='81.7797' cy='165.305' r='2.5' fill='white' className='star' />
		<circle cx='342.28' cy='58.126' r='2.5' fill='white' className='star' />
		<circle cx='436.28' cy='45.6338' r='2.5' fill='white' className='star' />
		<circle cx='712.28' cy='515.634' r='2.5' fill='white' className='star' />
		<circle cx='748.28' cy='635.634' r='2.5' fill='white' className='star' />
		<circle cx='839.739' cy='375.223' r='2.5' fill='white' className='star' />
		<circle cx='864.28' cy='272.134' r='2.5' fill='white' className='star' />
		<circle cx='756.28' cy='334.634' r='2.5' fill='white' className='star' />
		<circle cx='864.28' cy='471.634' r='2.5' fill='white' className='star' />
		<circle cx='872.28' cy='557.634' r='2.5' fill='white' className='star' />
		<circle cx='961.723' cy='658.572' r='2.5' fill='white' className='star' />
		<circle cx='294.28' cy='425.634' r='2.5' fill='white' className='star' />
		<circle cx='782.28' cy='150.339' r='2.5' fill='white' className='star' />
		<circle cx='490.28' cy='79.6338' r='2.5' fill='white' className='star' />
		<circle cx='179.78' cy='107.134' r='2.5' fill='white' className='star' />
		<circle cx='810.28' cy='77.1338' r='2.5' fill='white' className='star' />
		<circle cx='566.28' cy='241.634' r='2.5' fill='white' className='star' />
		<circle cx='915.28' cy='162.634' r='2.5' fill='white' className='star' />
		<circle cx='383.28' cy='218.634' r='2.5' fill='white' className='star' />
		<circle cx='362.28' cy='274.634' r='2.5' fill='white' className='star' />
		<circle cx='524.28' cy='342.634' r='2.5' fill='white' className='star' />
		<circle cx='538.263' cy='213.634' r='2.5' fill='white' className='star' />
		<circle cx='761.28' cy='3.12012' r='2.5' fill='white' className='star' />
		<circle cx='457.28' cy='534.634' r='2.5' fill='white' className='star' />
		<circle cx='566.28' cy='404.634' r='2.5' fill='white' className='star' />
		<circle cx='589.28' cy='468.634' r='2.5' fill='white' className='star' />
		<circle cx='508.28' cy='565.634' r='2.5' fill='white' className='star' />
		<circle cx='956.723' cy='221.634' r='2.5' fill='white' className='star' />
		<circle cx='399.28' cy='445.634' r='2.5' fill='white' className='star' />
		<circle cx='151.28' cy='498.634' r='2.5' fill='white' className='star' />
		<circle cx='177.28' cy='559.634' r='2.5' fill='white' className='star' />
		<circle cx='99.2797' cy='618.634' r='2.5' fill='white' className='star' />
		<circle cx='196.28' cy='630.634' r='2.5' fill='white' className='star' />
		<circle cx='248.28' cy='539.634' r='2.5' fill='white' className='star' />
		<circle cx='163.28' cy='420.634' r='2.5' fill='white' className='star' />
		<circle cx='84.2797' cy='354.846' r='2.5' fill='white' className='star' />
		<circle cx='212.5' cy='317.026' r='2.5' fill='white' className='star' />
		<circle cx='94.2797' cy='279.634' r='2.5' fill='white' className='star' />
		<circle cx='2.58769' cy='154.809' r='2.5' fill='white' className='star' />
		<circle cx='58.2797' cy='529.634' r='2.5' fill='white' className='star' />
		<g clipPath='url(#clip0_24_242)'>
			<path
				d='M479.557 248.955L474.783 224.136L470.009 248.955C468.639 256.078 463.11 261.535 456.197 262.587L438.783 265.237L457.129 268.416C463.926 269.593 469.307 275.034 470.63 282.066L474.783 304.136L478.936 282.066C480.259 275.034 485.64 269.594 492.438 268.416L510.783 265.237L493.37 262.587C486.457 261.535 480.927 256.078 479.557 248.955H479.557Z'
				fill='white'
				className='star'
			/>
		</g>
		<g clipPath='url(#clip1_24_242)'>
			<path
				d='M716.636 396.063L714.779 386.136L712.923 396.063C712.39 398.913 710.24 401.096 707.551 401.516L700.779 402.576L707.914 403.848C710.557 404.319 712.649 406.495 713.164 409.308L714.779 418.136L716.394 409.308C716.909 406.495 719.001 404.319 721.645 403.848L728.779 402.576L722.007 401.516C719.319 401.096 717.169 398.913 716.636 396.063H716.636Z'
				fill='white'
				className='star'
			/>
		</g>
		<g clipPath='url(#clip2_24_242)'>
			<path
				d='M928.636 412.063L926.779 402.136L924.923 412.063C924.39 414.913 922.24 417.096 919.551 417.516L912.779 418.576L919.914 419.848C922.557 420.319 924.649 422.495 925.164 425.308L926.779 434.136L928.394 425.308C928.909 422.495 931.001 420.319 933.645 419.848L940.779 418.576L934.007 417.516C931.319 417.096 929.169 414.913 928.636 412.063H928.636Z'
				fill='white'
				className='star'
			/>
		</g>
		<g clipPath='url(#clip3_24_242)'>
			<path
				d='M233.64 456.063L231.783 446.136L229.927 456.063C229.394 458.913 227.243 461.096 224.555 461.516L217.783 462.576L224.917 463.848C227.561 464.319 229.653 466.495 230.168 469.308L231.783 478.136L233.398 469.308C233.913 466.495 236.005 464.319 238.649 463.848L245.783 462.576L239.011 461.516C236.323 461.096 234.172 458.913 233.64 456.063H233.64Z'
				fill='white'
				className='star'
			/>
		</g>
		<g clipPath='url(#clip4_24_242)'>
			<path
				d='M672.768 33.5556L670.779 23.6279L668.79 33.5556C668.219 36.4048 665.915 38.5877 663.035 39.0085L655.779 40.0683L663.423 41.34C666.256 41.811 668.497 43.9871 669.049 46.8L670.779 55.6279L672.509 46.8C673.061 43.9871 675.303 41.8113 678.135 41.34L685.779 40.0683L678.524 39.0085C675.643 38.5877 673.339 36.4048 672.768 33.5556H672.768Z'
				fill='white'
				className='star'
			/>
		</g>
		<g clipPath='url(#clip5_24_242)'>
			<path
				d='M824.105 605.961L822.779 599.136L821.453 605.961C821.072 607.92 819.537 609.421 817.616 609.71L812.779 610.439L817.875 611.313C819.763 611.637 821.258 613.133 821.626 615.067L822.779 621.136L823.933 615.067C824.3 613.133 825.795 611.637 827.683 611.313L832.779 610.439L827.942 609.71C826.022 609.421 824.486 607.92 824.105 605.961H824.105Z'
				fill='white'
				className='star'
			/>
		</g>
		<g clipPath='url(#clip6_24_242)'>
			<path
				d='M101.6 116.425L99.2792 104.636L96.9587 116.425C96.2925 119.808 93.6046 122.401 90.2445 122.9L81.7792 124.159L90.6972 125.669C94.0016 126.228 96.6171 128.812 97.2605 132.153L99.2792 142.636L101.298 132.153C101.941 128.812 104.557 126.229 107.861 125.669L116.779 124.159L108.314 122.9C104.954 122.401 102.266 119.808 101.6 116.425H101.6Z'
				fill='white'
				className='star'
			/>
		</g>
		<g clipPath='url(#clip7_24_242)'>
			<path
				d='M98.1091 440.961L96.7831 434.136L95.4571 440.961C95.0764 442.92 93.5405 444.421 91.6204 444.71L86.7831 445.439L91.8791 446.313C93.7673 446.637 95.2619 448.133 95.6296 450.067L96.7831 456.136L97.9366 450.067C98.3041 448.133 99.7989 446.637 101.687 446.313L106.783 445.439L101.946 444.71C100.026 444.421 98.4898 442.92 98.1093 440.961H98.1091Z'
				fill='white'
				className='star'
			/>
		</g>
		<defs>
			<clipPath id='clip0_24_242'>
				<rect width='72' height='80' fill='white' transform='translate(438.783 224.136)' className='star' />
			</clipPath>
			<clipPath id='clip1_24_242'>
				<rect width='28' height='32' fill='white' transform='translate(700.779 386.136)' className='star' />
			</clipPath>
			<clipPath id='clip2_24_242'>
				<rect width='28' height='32' fill='white' transform='translate(912.779 402.136)' className='star' />
			</clipPath>
			<clipPath id='clip3_24_242'>
				<rect width='28' height='32' fill='white' transform='translate(217.783 446.136)' className='star' />
			</clipPath>
			<clipPath id='clip4_24_242'>
				<rect width='30' height='32' fill='white' transform='translate(655.779 23.6279)' className='star' />
			</clipPath>
			<clipPath id='clip5_24_242'>
				<rect width='20' height='22' fill='white' transform='translate(812.779 599.136)' className='star' />
			</clipPath>
			<clipPath id='clip6_24_242'>
				<rect width='35' height='38' fill='white' transform='translate(81.7792 104.636)' className='star' />
			</clipPath>
			<clipPath id='clip7_24_242'>
				<rect width='20' height='22' fill='white' transform='translate(86.7831 434.136)' className='star' />
			</clipPath>
		</defs>
	</svg>
);

const BushyTop = () => {
	return (
		<>
			<AnimationElementLamps
				variant={4}
				position={{ y: '20%', x: '-43%', breakpoints: { mobile: { scale: 0.5, y: '5%', x: '-40%' } } }}
			/>
			<DesktopSpan>
				<AnimationElementLamps
					variant={2}
					position={{
						breakpoints: {
							mobile: { scale: 0.85, y: '72%', x: '40%', angle: -12 },
							ultra: { scale: 0.85, y: '80%', x: '40%', angle: -12 },
						},
					}}
				/>
				<AnimationElementLamps
					variant={1}
					position={{
						breakpoints: {
							mobile: { scale: 0.85, y: '12%', x: '270%' },
							ultra: { scale: 0.85, y: '12%', x: '380%' },
						},
					}}
				/>
			</DesktopSpan>

			<AnimationElementTreetop
				variant={1}
				position={{
					flip: false,
					angle: 12,
					anchor: 'center',
					x: '-35%',
					scale: 1.15,
					y: '-70%',
					breakpoints: { mobile: { scale: 0.8, y: '-75%' } },
				}}
			></AnimationElementTreetop>
			<AnimationElementTreetop
				variant={5}
				position={{ scale: 1.15, x: '-70%', breakpoints: { mobile: { scale: 0.7, x: '-45%', y: '-20%' } } }}
			></AnimationElementTreetop>
			<AnimationElementTreetop
				variant={5}
				position={{
					flip: true,
					scale: 1.15,
					anchor: 'right',
					y: '15%',
					x: '70%',
					breakpoints: { mobile: { scale: 0.7, x: '62%', y: '-15%' } },
				}}
			></AnimationElementTreetop>
			<DesktopSpan>
				<AnimationElementTreetop
					variant={2}
					position={{
						flip: false,
						angle: -20,
						x: '-35%',
						scale: 1.15,
						y: '-70%',
						breakpoints: {
							mobile: { scale: 0.8, y: '-70%', x: '0%' },
							ultra: { scale: 0.8, y: '-70%', x: '20%' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopSpan>
			<DesktopSpan>
				<AnimationElementTreetop
					variant={2}
					position={{
						flip: false,
						angle: 20,
						x: '-35%',
						scale: 1.15,
						y: '-70%',
						breakpoints: {
							mobile: { scale: 0.8, y: '-70%', x: '100%' },
							ultra: { scale: 0.8, y: '-70%', x: '150%' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopSpan>
			<AnimationElementTreetop
				variant={1}
				position={{
					flip: true,
					angle: -12,
					anchor: 'center',
					x: '-54%',
					scale: 1.15,
					y: '-60%',
					breakpoints: { mobile: { scale: 0.8, y: '-70%' } },
				}}
			></AnimationElementTreetop>
			<AnimationElementTreetop
				variant={3}
				position={{
					flip: false,
					scale: 1.15,
					y: '-60%',
					x: '-42%',
					angle: -5,
					breakpoints: { mobile: { scale: 0.8 } },
				}}
			></AnimationElementTreetop>
			<AnimationElementTreetop
				variant={2}
				position={{ flip: false, scale: 1, y: '-50%', x: '-55%', angle: -5, breakpoints: { mobile: { scale: 0.785 } } }}
			></AnimationElementTreetop>

			<AnimationElementLamps
				variant={5}
				position={{ y: '20%', x: '32.5%', anchor: 'right', breakpoints: { mobile: { scale: 0.5, y: '0%' } } }}
			/>

			<AnimationElementTreetop
				variant={1}
				position={{
					scale: 1.2,
					anchor: 'right',
					flip: false,
					x: '65%',
					y: '-30%',
					angle: 10,
					breakpoints: {
						mobile: { scale: 0.8, x: '45%' },
					},
				}}
			></AnimationElementTreetop>
		</>
	);
};

const Portal = () => {
	const backRef = React.useRef<HTMLDivElement>(null);
	const frontRef = React.useRef<HTMLDivElement>(null);
	const starRef = React.useRef<HTMLDivElement>(null);

	const containerTimeline = useAnimationContainerTimeline();

	const skewVal = 2;
	const duration = 20;

	useEffect(() => {
		const tl = gsap.timeline({ repeat: -1, delay: 0 });
		tl.to(backRef.current, {
			keyframes: {
				scaleY: [1, 1.01, 1.02, 1.01, 1, 0.99, 1],
				scaleX: [1, 0.99, 0.98, 0.99, 1, 1.01, 1],
				ease: 'none',
				easeEach: 'none',
			},
			duration: duration,
			ease: 'none',
		});
		tl.to(
			backRef.current,
			{
				keyframes: {
					skewY: [0, skewVal, skewVal * 2, skewVal, 0, -skewVal, 0],
					skewX: [0, -skewVal, -(skewVal * 2), -skewVal, 0, skewVal, 0],
					ease: 'none',
					easeEach: 'none',
				},
				duration: duration,
				ease: 'none',
			},
			0
		);
		tl.to(
			frontRef.current,
			{
				keyframes: {
					skewX: [0, skewVal, skewVal * 2, skewVal, 0, -skewVal, 0],
					skewY: [0, -skewVal, -(skewVal * 2), -skewVal, 0, skewVal, 0],
					ease: 'none',
					easeEach: 'none',
				},
				duration: duration,
				ease: 'none',
			},
			0
		);
		tl.to(
			frontRef.current,
			{
				keyframes: {
					scaleX: [1, 1.01, 1.02, 1.01, 1, 0.99, 1],
					scaleY: [1, 0.99, 0.98, 0.99, 1, 1.01, 1],
					ease: 'none',
					easeEach: 'none',
				},
				duration: duration,
				ease: 'none',
			},
			0
		);
		containerTimeline.add(tl, 0);

		return () => {
			tl.kill();
		};
	}, []);

	useEffect(() => {
		const query = gsap.utils.toArray<SVGElement>('.star');

		const animationList: any[] = [];

		query.forEach(el => {
			const delay = Math.random() * 10 + 6;
			const tl = gsap.timeline({ repeat: -1, delay: delay - 5, repeatDelay: delay });
			tl.to(el, {
				transformOrigin: 'center center',
				scale: 0,
				duration: 1,
				ease: 'power1.inOut',
			});
			tl.to(el, {
				transformOrigin: 'center center',
				scale: 1,
				duration: 1,
				ease: 'power1.inOut',
			});
			animationList.push(tl);
		});
		const tween = gsap.to(starRef.current, {
			transformOrigin: 'center center',
			keyframes: {
				scale: [1, 1.01, 1, 0.99, 1],
				easeEach: 'sine.inOut',
			},
			duration: 10,
		});

		return () => {
			animationList.forEach(tl => {
				tl.kill();
			});
			tween.kill();
		};
	}, []);

	const yVal = 25;
	const yValBig = -12;

	return (
		<>
			<AnimationPositionElement
				position={{
					anchor: 'center',
					x: '-50%',
					y: `${yVal}%`,
					breakpoints: { mobile: { angle: 90, y: `${yValBig}%` } },
				}}
			>
				<BackPortalContainer ref={backRef}>{backPortalSVG}</BackPortalContainer>
			</AnimationPositionElement>
			<AnimationPositionElement
				position={{
					anchor: 'center',
					x: '-50%',
					y: `${yVal + 20}%`,
					breakpoints: { mobile: { angle: 90, y: `${yValBig + 9}%` }, ultra: { angle: 90, y: `${yValBig + 9}%` } },
				}}
			>
				<FrontPortalContainer ref={frontRef}> {frontPortalSVG}</FrontPortalContainer>
			</AnimationPositionElement>
			<AnimationPositionElement
				position={{
					anchor: 'center',
					x: '-55%',
					y: `${yVal + 35}%`,
					breakpoints: { mobile: { y: `${yValBig + 60}%`, scale: 1.2 } },
				}}
			>
				<StarsContainer>
					<MobileSpan>{stars}</MobileSpan>
					<DesktopSpan ref={starRef}>{starsDesktop}</DesktopSpan>
				</StarsContainer>
			</AnimationPositionElement>
		</>
	);
};

const Scene = () => {
	const imageRef = useRef<HTMLImageElement | null>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1686225173-bogescenen-lille.png', {});
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		const tween = gsap.to(imageRef.current, {
			scale: 1.04,
			duration: 0.4,
			yoyo: true,
			repeat: -1,
			ease: 'back.in(1, 0.3)',
		});
		containerTimeline.add(tween, 0);
		return () => {
			tween.kill();
		};
	}, []);
	return <AnimationImageElement image={image} ref={imageRef} />;
};

const rainbowSvg = (
	<svg width='265' height='277' viewBox='0 0 265 277' fill='none'>
		<path
			d='M52.1706 37.144C60.4368 30.9527 89.7753 26.8264 100.521 50.3532C111.267 73.88 119.117 100.71 143.5 92.8689C167.883 85.0278 184 0 220.365 0L235.654 46.642L217.47 109.793L195.154 125.063C195.154 125.063 160.029 140.749 157.55 140.334C155.07 139.922 124.488 143.637 122.009 141.984C119.529 140.334 90.603 122.998 90.1873 121.76C89.7753 120.522 72.0031 92.8689 72.0031 92.8689L65.8043 75.9449L52.167 37.1476L52.1706 37.144Z'
			fill='#FFAE02'
			className='rainbow'
		/>
		<path
			d='M24.0684 37.9704C38.1177 29.3026 52.8443 23.9344 66.22 37.5588C87.2959 59.0206 93.0826 145.702 142.261 129.605C179.342 117.465 207.144 72.2299 227.392 79.6594C247.64 87.0889 232.763 228.253 232.763 228.253L131.103 200.185L50.1032 146.94L24.0684 37.9739V37.9704Z'
			fill='#F74D38'
			className='rainbow'
		/>
		<path
			d='M0 41.9346C14.2285 36.6202 23.6524 49.1144 23.6524 91.2149C23.6524 133.315 29.8512 207.611 106.304 245.173C182.757 282.732 252.183 276.541 252.183 276.541L248.052 248.061C248.052 248.061 286.896 188.21 245.572 166.337C204.248 144.46 145.152 213.805 99.2775 165.925C47.6772 112.065 65.2667 62.4739 37.7018 40.4466C23.2368 28.8908 5.03699 36.4224 0 41.9346Z'
			fill='#9CBEFF'
			className='rainbow'
		/>
	</svg>
);
const rainbowSvgDesktop = (
	<svg width='557' height='283' viewBox='0 0 557 283' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M52.1706 8.38297C60.4368 2.19172 89.7753 -1.93457 100.521 21.5922C111.267 45.119 107.311 114.599 151.5 131.994C292.5 187.5 437.5 108.091 518 21.592C598.5 -64.9069 528.5 136.994 528.5 136.994L373 170.993L263 205.493C263 205.493 148.98 205.908 146.5 205.493C144.02 205.081 103.001 151.146 100.521 149.493C98.0415 147.843 66.2199 100.731 65.8043 99.493C65.3922 98.2547 72.0031 64.1076 72.0031 64.1076L65.8043 47.1837L52.167 8.38658L52.1706 8.38297Z'
			fill='#FFAE02'
			className='rainbow'
		/>
		<path
			d='M24.0684 9.21157C38.1177 0.543805 52.8443 -4.82434 66.22 8.80001C87.2959 30.2619 71.5 118.486 134 158.493C196.5 198.5 366.5 192.5 464 113.493C561.5 34.4863 513 220.989 513 220.989H109.5L50.1032 118.181L24.0684 9.21515V9.21157Z'
			fill='#F74D38'
			className='rainbow'
		/>
		<path
			d='M0 13.1739C14.2285 7.85943 23.6524 20.3536 23.6524 62.4542C23.6524 104.555 46.0473 214.437 122.5 252C198.953 289.559 335.5 291.993 518.5 264.493C585.952 254.357 531.59 119.111 481.5 160.993C389.5 237.918 143.875 224.873 98 176.993C46.3997 123.133 65.2667 33.7131 37.7018 11.6859C23.2368 0.130024 5.03699 7.66163 0 13.1739Z'
			fill='#9CBEFF'
			className='rainbow'
		/>
	</svg>
);
const Rainbows = () => {
	const ref = React.useRef<HTMLDivElement>(null);
	const parentTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const tl = gsap.timeline({ repeat: -1 });
		tl.set(ref.current, { transformOrigin: 'center left' });
		tl.to(ref.current, {
			skewX: -1.2,

			duration: 0.4,
			yoyo: true,
			repeat: -1,
			ease: 'back.in(1, 0.3)',
		});

		parentTimeline.add(tl, 0);
		return () => {
			tl.kill();
		};
	}, []);
	return (
		<RainbowContainer ref={ref}>
			<MobileSpan>{rainbowSvg}</MobileSpan>
			<DesktopSpan>{rainbowSvgDesktop}</DesktopSpan>
		</RainbowContainer>
	);
};

export default function IllustrationRegnbuescene(props: any) {
	return (
		<StyledAnimationContainer
			className='colorWrapperAnimatedModule'
			height={4155}
			width={2500}
			breakpoints={{ mobile: { height: 1500, width: 2600 }, ultra: { width: 4000, height: 2308 } }}
		>
			{props.data.flyingBannerMessage.length > 0 && <FlyingCanMessage link={props.data.flyingBannerMessage[0]} />}
			<Portal></Portal>
			<BushyTop></BushyTop>
			<AnimationPositionElement
				position={{
					scale: 0.86,
					y: '120%',
					breakpoints: { mobile: { scale: 0.75, y: '40%', x: '-50%', anchor: 'center' } },
				}}
			>
				<Scene></Scene>
			</AnimationPositionElement>
			<AnimationPositionElement
				position={{
					scale: 1,
					y: '141.5%',
					anchor: 'center',
					x: '19%',
					breakpoints: { mobile: { y: '77%', scale: 0.9, x: '4%' } },
				}}
			>
				<Rainbows></Rainbows>
			</AnimationPositionElement>
			<AnimationPositionElement
				position={{
					scale: 1,
					y: '142.5%',
					anchor: 'center',
					x: '-114%',
					flip: true,
					breakpoints: { mobile: { y: '78%', scale: 0.9, x: '-102%' } },
				}}
			>
				<Rainbows></Rainbows>
			</AnimationPositionElement>
			{props.data.cta && (
				<ButtonPlacer>
					<SquaredLinkButton
						backgroundColor={presetColors.white}
						textColor={presetColors.blackish}
						data={props.data.cta[0]}
					></SquaredLinkButton>
				</ButtonPlacer>
			)}
		</StyledAnimationContainer>
	);
}
