import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Link, { createLinkObject } from '@core/PageSwitch/Link';
import { Colors, Index, Fonts, presetColors, Breakpoints } from '@client/style/Variables';
import gsap from 'gsap';
import Site from '@client/store/Site';
import LogoWithText from './LogoWithText';
import Functions from '@client/style/Functions';
import { Splash } from './Splash';
import { LoginLogoutButton } from '../Auth/LoginLogoutButton';
import { useAuthenticatedUser } from '@client/utils/AuthStore';

const MainMenuButton = styled.button<{ $color: string }>`
	position: fixed;
	z-index: ${Index.mainMenuButton};
	color: ${props => props.$color};
	top: 15px;
	right: 10px;
	appearance: none;
	background: transparent;
	padding: 18px;
`;

const FullScreenMenuWrapper = styled.nav`
	display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: ${Index.FullScreenMenu};
	overflow: hidden;
	pointer-events: none;
`;

const StyledMenuInner = styled.div`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	height: 100vh;
	color: ${Colors.lightText};
	background-color: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	pointer-events: all;
`;

const Homelink = styled(Link)`
	z-index: ${Index.FullScreenMenu - 1};
	display: flex;
	position: relative;
`;

const Home = styled.button`
	z-index: ${Index.FullScreenMenu - 1};
	display: flex;
	appearance: none;
	text-align: left;
	position: relative;
	cursor: pointer;
`;

const StyledMenuLogo = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		left: 47px;
	}
`;

const TopBar = styled.div`
	padding: 20px 20px 20px 20px;
	display: flex;
	/* flex-direction: row; */
	justify-content: space-between;
	height: 80px;
	width: 100vw;
	position: absolute;

	${Functions.breakpoint(Breakpoints.laptop)} {
		padding: 20px 47px;
		padding-left: 51px;
	}
`;

const Mainlinks = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 100vw;
	padding: 0;
	padding-top: 20px;
	@media all and (min-height: 724px) {
		padding-top: 50px;
	}
	margin: 0;
	li {
		list-style-type: none;
	}
`;

const Mainlink = styled(Link)<{ isactive: string }>`
	font-size: 35px;
	color: ${props => (props.isactive === 'true' ? Colors.newsBackground : Colors.lightText)};
	&:hover {
		color: ${Colors.newsBackground};
	}

	@media all and (min-height: 724px) {
		font-size: 55px;
	}
	@media all and (min-height: 1024px) {
		font-size: 86px;
	}

	@media all and (max-width: 554px) {
		font-size: 35px;
	}
`;

const ComingSoonLink = styled.div<{ rotation?: string }>`
	font-size: 55px;
	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 86px;
	}
	&.unavailable {
		color: rgba(255, 255, 255, 0.1);
		&:before {
			content: 'kommer snart';
			position: absolute;
			background-color: ${presetColors.sunkiss};
			font-size: 13px;
			color: ${Colors.darkText};
			padding: 8px 18px 5px 18px;
			transform: translate(-50%, 50%) rotate(${props => props.rotation});
			transform-origin: center;
			left: 50%;
			${Functions.breakpoint(Breakpoints.mobile)} {
				font-size: 24px;
			}
		}
	}
`;

const Sublinks = styled.ul`
	padding: 0;
	padding-top: 30px;
	display: flex;
	width: 100vw;
	flex-direction: column;
	font-family: ${Fonts.Geomanist};
	font-size: 18px;
	justify-content: center;
	text-align: center;
	margin: 0;

	${Functions.breakpoint(Breakpoints.laptop)} {
		/* font-size: 16px; */
		font-size: 28px;
	}

	li {
		padding-bottom: 10px;
		list-style-type: none;
	}
`;

const Sublink = styled(Link)`
	padding-bottom: 10px;
	&:hover {
		color: ${Colors.newsBackground};
	}
`;

const LoginLogoutButtonContainer = styled.div`
	position: fixed;
	z-index: ${Index.mainMenuButton};
	bottom: 22px;
	left: 22px;
	background: transparent;

	${Functions.breakpoint(Breakpoints.tablet)} {
		bottom: unset;
		left: unset;
		right: 78px;
		top: 17px;
	}
`;

const StyledIconWrapper = styled.div`
	width: 28px;
	height: 16px;
	position: relative;
`;

const StyledBurgerBar = styled.div`
	width: 100%;
	height: 4px;
	background: currentColor;
	position: absolute;
	left: 0;
	&:nth-child(1) {
		top: 0;
		/* transform-origin: top right; */
	}
	&:nth-child(2) {
		bottom: 0;
		/* transform-origin: bottom right; */
	}
`;

const BurgerIcon = (props: { open: boolean }) => {
	const animationRef = React.useRef<gsap.core.Timeline>();
	const bar1Ref = React.useRef<HTMLDivElement>(null);
	const bar2Ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (animationRef.current) {
			if (props.open) {
				animationRef.current.play(0.2);
			} else {
				animationRef.current.reverse(0.8);
			}
		} else {
			const timeline = gsap.timeline({ paused: true });

			timeline.to(
				bar1Ref.current,
				{
					rotate: -45,
					duration: 1,
					y: '150%',
					ease: 'elastic.inOut(1.75, 0.5)',
				},
				0
			);
			timeline.to(
				bar2Ref.current,
				{
					rotate: 45,
					y: '-150%',
					duration: 1.15,
					ease: 'elastic.inOut(1.75, 0.5)',
				},
				0
			);

			animationRef.current = timeline;
		}
	}, [props.open]);

	return (
		<StyledIconWrapper>
			<StyledBurgerBar ref={bar1Ref} />
			<StyledBurgerBar ref={bar2Ref} />
		</StyledIconWrapper>
	);
};

export default function Menu({
	onLogoClick,
	specificColor = undefined,
	currentSlug,
	showLogin = true,
}: {
	onLogoClick?: () => void;
	specificColor?: string;
	currentSlug?: string;
	showLogin?: boolean;
}) {
	const [navigationToggle, setNavigationToggle] = useState(false);
	const topMenuState = Site.use(state => state.topMenu);

	const fullScreenMenu = useRef<HTMLDivElement | null>(null);
	const subLinksRef = useRef<HTMLUListElement | null>(null);
	const mainLinksRef = useRef<HTMLUListElement | null>(null);
	const menuLogoRef = useRef<HTMLDivElement | null>(null);
	const loginButton = useRef<HTMLDivElement | null>(null);

	const [isHomePage, setIsHomePage] = useState<boolean>(false);

	const user = useAuthenticatedUser();
	const toggleMenu = () => {
		setNavigationToggle(!navigationToggle);
	};

	// Open animation
	const menuOpenAnimation = () => {
		if (!fullScreenMenu.current) return;
		if (!mainLinksRef.current) return;
		fullScreenMenu.current.style.display = 'block';
		const timeline = gsap.timeline();
		const duration = 0.5;

		const delay = 0.3;
		const stagger = 0.05;

		// Animate menu items
		timeline.fromTo(
			[mainLinksRef.current.childNodes, subLinksRef.current, menuLogoRef.current],
			{
				x: '-20%',
			},
			{
				stagger: {
					each: stagger,
					ease: 'power1.out',
				},
				delay: delay,
				duration: duration,
				ease: 'back.out(2)',
				x: 0,
			},
			0
		);
		timeline.fromTo(
			[mainLinksRef.current.childNodes, subLinksRef.current, menuLogoRef.current],
			{
				opacity: 0,
			},
			{
				stagger: {
					each: stagger,
					ease: 'power1.out',
				},
				opacity: 1,
				delay: delay,
				duration: duration,
				ease: 'linear',
			},
			0
		);
	};

	useEffect(() => {
		if (typeof window !== 'undefined' && window.document) setIsHomePage(window.location.pathname === '/');
	}, []);

	useEffect(() => {
		// Open animation
		const menuOpenAnimation = () => {
			if (!fullScreenMenu.current) return;
			if (!mainLinksRef.current) return;
			fullScreenMenu.current.style.display = 'block';
			const timeline = gsap.timeline();
			const duration = 0.5;

			const delay = 0.3;
			const stagger = 0.05;

			// Animate menu items
			timeline.fromTo(
				[mainLinksRef.current.querySelectorAll(':scope > *'), subLinksRef.current, menuLogoRef.current],
				{
					x: '-20%',
				},
				{
					stagger: {
						each: stagger,
						ease: 'power1.out',
					},
					delay: delay,
					duration: duration,
					ease: 'back.out(2)',
					x: 0,
				},
				0
			);
			timeline.fromTo(
				[mainLinksRef.current.querySelectorAll(':scope > *'), subLinksRef.current, menuLogoRef.current],
				{
					opacity: 0,
				},
				{
					stagger: {
						each: stagger,
						ease: 'power1.out',
					},
					opacity: 1,
					delay: delay,
					duration: duration,
					ease: 'linear',
				},
				0
			);
		};

		// Close animation
		const menuCloseAnimation = () => {
			if (!fullScreenMenu.current) return;
			if (!mainLinksRef.current) return;
			const timeline = gsap.timeline();
			timeline.set(fullScreenMenu.current, { display: '' });
		};

		if (navigationToggle) {
			if (!fullScreenMenu.current) return;
			menuOpenAnimation();
		} else {
			if (!fullScreenMenu.current) return;
			menuCloseAnimation();

			const top = parseInt(document.body.style.top);
			if (top && top < 0) {
				window.scrollTo(0, top * -1);
			}
		}
	}, [navigationToggle, user]);

	const getNewRotation = (index: number) => {
		let rotation: number = Math.round(Math.random() * 5 + 5);
		if (index % 2) rotation = rotation * -1;
		return rotation + 'deg';
	};

	return (
		<>
			<TopBar>
				{isHomePage ? (
					<Home onClick={onLogoClick}>
						<LogoWithText
							mode={'light'}
							color={navigationToggle ? Colors.defaultWhite : specificColor ? specificColor : Colors.defaultWhite}
						/>
					</Home>
				) : (
					<Homelink data={{ link: '/' }}>
						<LogoWithText
							mode={'light'}
							color={navigationToggle ? Colors.defaultWhite : specificColor ? specificColor : Colors.defaultWhite}
						/>
					</Homelink>
				)}

				{showLogin && (
					<LoginLogoutButtonContainer ref={loginButton}>
						<LoginLogoutButton />
					</LoginLogoutButtonContainer>
				)}

				<MainMenuButton
					onClick={toggleMenu}
					$color={navigationToggle ? Colors.defaultWhite : specificColor ? specificColor : Colors.defaultWhite}
				>
					<BurgerIcon open={navigationToggle} />
				</MainMenuButton>
			</TopBar>

			{/* Menu */}
			<FullScreenMenuWrapper ref={fullScreenMenu}>
				<Splash animate={navigationToggle} color={Functions.hexFromRGB(topMenuState.backgroundColor?.color)} />
				<StyledMenuInner>
					<StyledMenuLogo ref={menuLogoRef}>
						<Homelink
							data={{ link: '/' }}
							customOnClick={() => {
								if (typeof window !== 'undefined' && window.document) {
									console.log(currentSlug);
									if (currentSlug === '/') {
										setNavigationToggle(false);
									}
								}
							}}
						>
							<LogoWithText mode={'light'} color={Colors.defaultWhite} />
						</Homelink>
					</StyledMenuLogo>

					<Mainlinks ref={mainLinksRef}>
						{topMenuState.primaryLinks.map((link, index) =>
							!link.internalLink && !link.link ? (
								<ComingSoonLink key={'pl' + index} rotation={getNewRotation(index)} className={'unavailable'}>
									{link.title}
								</ComingSoonLink>
							) : (
								<li key={'pl-div-' + index}>
									<Mainlink
										data={link}
										key={'pl' + index}
										customOnClick={() => {
											if (typeof window !== 'undefined' && window.document) {
												if (currentSlug === (link.internalLink?._prefix ?? '') + (link.internalLink?.slug ?? '')) {
													setNavigationToggle(false);
												}
											}
										}}
										isactive={(
											typeof window !== 'undefined' &&
											window.document &&
											link.internalLink?.slug === window?.location?.pathname.replace('/', '')
										).toString()}
									>
										{link.title}
									</Mainlink>
								</li>
							)
						)}

						{user && (
							<li key={'pl-div-min-side'}>
								<Mainlink
									data={createLinkObject({ _prefix: '', slug: '/min-side' })}
									key={'min-side'}
									isactive={(
										typeof window !== 'undefined' &&
										window.document &&
										'min-side' === window?.location?.pathname.replace('/', '')
									).toString()}
								>
									{'Mit Smuk'}
								</Mainlink>
							</li>
						)}
					</Mainlinks>
					<Sublinks ref={subLinksRef}>
						{topMenuState.secondaryLinks.map((link, index) => (
							<li key={'sl-div-' + index}>
								<Sublink key={'sl' + index} data={link}>
									{link.title}
								</Sublink>
							</li>
						))}
					</Sublinks>
				</StyledMenuInner>
			</FullScreenMenuWrapper>
		</>
	);
}
