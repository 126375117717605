import { AnimationType, ILottieData, IPNGData, ISpineData } from './IAnimationData';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import AnimationEffectComponent from './AnimationEffectComponent';
import Image from '@client/modules/Image';
import GotoElement from '@client/core/utils/GotoElement';
import Link from '@client/core/PageSwitch/Link';
import { OverlayController, Overlays } from '@client/core/modules/Overlays/Overlays';
import { OverlayNewsDuck } from '@client/modules/overlays/News/OverlayNewsDuck';

const ClickableContainer = styled.div`
	pointer-events: none;
`;

export interface IAnimationItem {
	id: string;
	name: string;
	modelId: string;
	type: string;

	itemType: AnimationType;

	animation: (IPNGData | ISpineData | ILottieData)[];

	alignX: string | undefined;
	alignY: string | undefined;
	pivotX: string | undefined;
	pivotY: string | undefined;
	scaleX: string | undefined;
	scaleY: string | undefined;
	offsetYRelativeToWidth: string | undefined;
	sortIndex: number | undefined;

	clickAction: any | undefined;
	effect: string | undefined;
	idleSound: any | undefined;
	interactSound: any | undefined;
}

export default function AnimationItemComponent({ item }: { item: IAnimationItem }) {
	const containerRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (containerRef.current && item.clickAction[0]) {
			containerRef.current.style.pointerEvents = 'all';
			containerRef.current.style.cursor = 'pointer';
		}
	}, [containerRef.current]);

	const onClick = () => {
		if (item.clickAction.length === 0) return;

		const clickAction = item.clickAction[0];

		if (clickAction.modelId.toLowerCase() === 'gotomodule') {
			GotoElement.Instance.scrollToElementWithId(`module_${clickAction.moduleId}`);
		} else if (clickAction.modelId.toLowerCase() === 'gotopageaction') {
			// not thouroughly tested, since it has not been used in any of the animations. With the new implementation;
			const newLink = Link({ data: clickAction.link[0] });
			newLink.props.onClick(new MouseEvent('click'));
		} else if (clickAction.modelId.toLowerCase() === 'openoverlay') {
			// not thouroughly tested, since it has not been used in any of the animations. With the new implementation;
			if (clickAction.overlayId.toLowerCase() === 'news') {
				Overlays.Instance.open({
					create: (controller: OverlayController) => {
						return <OverlayNewsDuck controller={controller} />;
					},
				});
			}
		}
	};
	return (
		<ClickableContainer ref={containerRef} onClick={onClick}>
			<AnimationEffectComponent effect={item.effect}>
				<Image data={item.animation[0].idleState} lossless={true} maxWidth={2200}></Image>
			</AnimationEffectComponent>
		</ClickableContainer>
	);
}
