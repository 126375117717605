import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';
import { Breakpoints } from '@client/style/Variables';
import Functions from '@client/style/Functions';
import { gsap } from 'gsap';
import { AnimationElementSparkles } from '../AnimationElementSparkles';
import { AnimationElementTreetop } from '../AnimationElementTreetop';

const StyledAnimationContainer = styled(AnimationContainer)`
	img {
		max-width: 100%;
		height: auto;
	}
`;
const MobileContainer = styled.div`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;
const DesktopContainer = styled.div`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;
const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const Melon = () => {
	const imageRef = useRef<HTMLImageElement | null>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670837779-vandmelon-desktop.png', {});
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		const tl = gsap.timeline({ repeat: -1, ease: 'linear' });

		tl.to(imageRef.current, { duration: 1.5, y: '+=1.25%', x: '-=2%', ease: 'sine.out' }, 'first');
		tl.to(imageRef.current, { duration: 1.5, y: '-=1.25%', x: '+=2%', ease: 'sine.in' }, 'second');
		tl.to(imageRef.current, { duration: 1.5, rotateZ: 6, ease: 'sine.out' }, 'first');
		tl.to(imageRef.current, { duration: 1.5, rotateZ: 0, ease: 'sine.out' }, 'second');

		containerTimeline.add(tl, 0);
		return () => {
			tl.kill();
		};
	}, []);
	return <AnimationImageElement image={image} ref={imageRef} />;
};
const Disco = () => {
	const imageRef = useRef<HTMLImageElement | null>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1686135805-discokugle.png', {});
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		const tl = gsap.timeline({ repeat: -1, ease: 'linear' });
		tl.set(imageRef.current, { transformOrigin: '50% 50% -5px' });

		const duration = 0.5;
		tl.to(imageRef.current, { duration: duration, rotateY: 15, ease: 'linear' });
		tl.to(imageRef.current, { duration: duration * 2, rotateY: -15, ease: 'linear' });
		tl.to(imageRef.current, { duration: duration, rotateY: 0, ease: 'linear' });

		containerTimeline.add(tl, 0);
		return () => {
			tl.kill();
		};
	}, []);
	return (
		<>
			<AnimationImageElement image={image} ref={imageRef} />
			<AnimationElementSparkles style={{ height: '50%', bottom: 0, top: 'unset' }} />
		</>
	);
};
const Paddy = () => {
	const imageRef = useRef<HTMLImageElement | null>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670839646-plantebof-desktop.png', {});
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		gsap.set(imageRef.current, { transformOrigin: '60% 80%' });
		const tween = gsap.from(imageRef.current, {
			duration: 0.4,
			scale: 0.3,
			x: '20%',
			ease: 'back.out(1, 0.3)',
		});

		containerTimeline.add(tween, 0);

		return () => {
			tween.kill();
		};
	}, []);
	return <AnimationImageElement image={image} ref={imageRef} />;
};
const Pedal = () => {
	const imageRef = useRef<HTMLImageElement | null>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670838571-pedal-desktop.png', {});
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		gsap.set(imageRef.current, { transformOrigin: '70% 100%' });
		const tween = gsap.to(imageRef.current, {
			scale: 1.02,
			duration: 0.4,
			yoyo: true,
			repeat: -1,
			ease: 'back.in(1, 0.3)',
		});
		containerTimeline.add(tween, 0);
		return () => {
			tween.kill();
		};
	}, []);
	return <AnimationImageElement image={image} ref={imageRef} />;
};

const mobileHaze = (
	<svg width='375' height='273' viewBox='0 0 375 273' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M-7.18359 21.9067C28.3164 7.90666 104.716 -12.6935 102.316 12.9065C99.3164 44.9065 51.8164 64.9065 55.8164 88.9065C59.8164 112.907 119.816 84.4065 125.316 106.907C129.472 123.907 89.8918 152.463 113.316 169.907C136.816 187.407 186.533 151.238 212.316 160.907C236.316 169.907 246.816 189.407 266.316 195.907C309.648 210.35 261.308 106.907 291.316 106.907C315.816 106.907 326.816 139.407 353.816 145.407C380.816 151.407 299.374 18.6782 374.816 55.4065C412.816 73.9065 434.316 94.4065 434.316 94.4065C433.352 93.5197 434.316 271.907 434.316 271.907H-10.1471C-10.1709 273.095 -10.1836 273.148 -10.1836 271.907H-10.1471C-9.87152 258.163 -8.10399 92.6238 -7.18359 21.9067Z'
			fill='#FFB09A'
		/>
	</svg>
);
const HazeContainer = styled.div`
	width: 100vw;
	svg {
		width: 100%;
		height: auto;
	}
`;

export function IllustrationBushPedal() {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (moduleRef.current) {
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor != null && nextColor != null) {
				setBackgroundColors([prevColor, nextColor]);
			} else if (prevColor != null) {
				setBackgroundColors([prevColor, prevColor]);
			} else if (nextColor != null) {
				setBackgroundColors([nextColor, nextColor]);
			} else {
				setBackgroundColors([undefined, undefined]);
			}
		}
	}, [moduleRef]);

	const line = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670838718-vector-35.png', {});
	return (
		<StyledAnimationContainer
			height={1280}
			width={690}
			breakpoints={{ mobile: { height: 1200, width: 2600 }, ultra: { width: 4000, height: 1846 } }}
			ref={moduleRef}
		>
			<ColorWrapper>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>
			<DesktopContainer>
				<AnimationElementTreetop
					variant={7}
					position={{
						flip: true,
						y: '6%',
						x: '-1%',
						breakpoints: {
							tiny: { width: '40vw' },
							mobile: { width: '40vw' },
							tablet: { width: '40vw' },
							laptop: { width: '40vw' },
							desktop: { width: '40vw' },
							largeDesktop: { width: '40vw' },
							ultra: { width: '40vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={8}
					position={{
						flip: true,
						y: '32%',
						x: '-1%',
						breakpoints: {
							tiny: { width: '33vw' },
							mobile: { width: '33vw' },
							tablet: { width: '33vw' },
							laptop: { width: '33vw' },
							desktop: { width: '33vw' },
							largeDesktop: { width: '33vw' },
							ultra: { width: '33vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={3}
					position={{
						anchor: 'right',
						y: '0%',
						x: '20%',
						breakpoints: {
							tiny: { width: '33vw' },
							mobile: { width: '33vw' },
							tablet: { width: '33vw' },
							laptop: { width: '33vw' },
							desktop: { width: '33vw' },
							largeDesktop: { width: '33vw' },
							ultra: { width: '33vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={1}
					position={{
						anchor: 'center',
						y: '35%',
						x: '-50%',
						breakpoints: {
							tiny: { width: '48vw' },
							mobile: { width: '48vw' },
							tablet: { width: '48vw' },
							laptop: { width: '48vw' },
							desktop: { width: '48vw' },
							largeDesktop: { width: '48vw' },
							ultra: { width: '48vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={1}
					position={{
						anchor: 'center',
						y: '40%',
						x: '10%',
						breakpoints: {
							tiny: { width: '45vw' },
							mobile: { width: '45vw' },
							tablet: { width: '45vw' },
							laptop: { width: '45vw' },
							desktop: { width: '45vw' },
							largeDesktop: { width: '45vw' },
							ultra: { width: '45vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<MobileContainer>
				<AnimationPositionElement position={{ y: '12%' }}>
					<HazeContainer>{mobileHaze}</HazeContainer>
				</AnimationPositionElement>
				<AnimationElementTreetop
					variant={3}
					position={{ anchor: 'right', x: '34%', y: '75%', breakpoints: { tiny: { width: '94vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={8}
					position={{ flip: true, x: '-33%', y: '123%', breakpoints: { tiny: { width: '77vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{ x: '-52%', y: '160%', anchor: 'center', breakpoints: { tiny: { width: '85vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={7}
					position={{ flip: true, x: '-40%', y: '110%', breakpoints: { tiny: { width: '117vw' } } }}
				></AnimationElementTreetop>
			</MobileContainer>
			<AnimationPositionElement
				position={{
					width: '55vw',
					y: '114%',
					x: '-14%',
					breakpoints: { mobile: { width: '20vw', x: '64%', y: '-6%' } },
				}}
			>
				<Melon />
			</AnimationPositionElement>
			<AnimationPositionElement
				position={{
					width: '34vw',
					y: '212%',
					x: '10%',
					breakpoints: { mobile: { width: '11vw', x: '105%', y: '140%' } },
				}}
			>
				<Disco />
			</AnimationPositionElement>

			<AnimationPositionElement
				position={{
					width: '85vw',
					x: '0%',
					anchor: 'right',
					y: '65%',
					breakpoints: { mobile: { width: '23vw', x: '-74%', y: '-3%' } },
				}}
			>
				<Pedal />
			</AnimationPositionElement>

			<DesktopContainer>
				<AnimationPositionElement position={{ y: '118%', width: '40vw', x: '67%' }}>
					<AnimationImageElement image={line}></AnimationImageElement>
				</AnimationPositionElement>
				<AnimationElementTreetop
					variant={3}
					position={{
						x: '4%',
						y: '45%',
						breakpoints: {
							tiny: { width: '27vw' },
							mobile: { width: '27vw' },
							tablet: { width: '27vw' },
							laptop: { width: '27vw' },
							desktop: { width: '27vw' },
							largeDesktop: { width: '27vw' },
							ultra: { width: '27vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={4}
					position={{
						anchor: 'center',
						x: '-20%',
						y: '67%',
						breakpoints: {
							tiny: { width: '33vw' },
							mobile: { width: '33vw' },
							tablet: { width: '33vw' },
							laptop: { width: '33vw' },
							desktop: { width: '33vw' },
							largeDesktop: { width: '33vw' },
							ultra: { width: '33vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{
						anchor: 'center',
						x: '-65%',
						y: '38%',
						breakpoints: {
							tiny: { width: '35vw' },
							mobile: { width: '35vw' },
							tablet: { width: '35vw' },
							laptop: { width: '35vw' },
							desktop: { width: '35vw' },
							largeDesktop: { width: '35vw' },
							ultra: { width: '35vw' },
						},
					}}
				></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={2}
					position={{
						anchor: 'right',
						x: '-25%',
						y: '65%',
						breakpoints: {
							tiny: { width: '30vw' },
							mobile: { width: '30vw' },
							tablet: { width: '30vw' },
							laptop: { width: '30vw' },
							desktop: { width: '30vw' },
							largeDesktop: { width: '30vw' },
							ultra: { width: '30vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{
						flip: true,
						anchor: 'right',
						x: '22%',
						y: '45%',
						breakpoints: {
							tiny: { width: '28vw' },
							mobile: { width: '28vw' },
							tablet: { width: '28vw' },
							laptop: { width: '28vw' },
							desktop: { width: '28vw' },
							largeDesktop: { width: '28vw' },
							ultra: { width: '28vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<MobileContainer>
				<AnimationElementTreetop
					variant={3}
					position={{ x: '-50%', y: '157%', breakpoints: { tiny: { width: '81vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{ flip: true, anchor: 'right', x: '44%', y: '147%', breakpoints: { tiny: { width: '77vw' } } }}
				></AnimationElementTreetop>
			</MobileContainer>
			<AnimationPositionElement
				position={{
					width: '60vw',
					x: '1%',
					anchor: 'right',
					y: '140%',
					breakpoints: { mobile: { width: '20vw', x: '9%', y: '73%' } },
				}}
			>
				<Paddy />
			</AnimationPositionElement>
		</StyledAnimationContainer>
	);
}
