import React, { HTMLAttributes, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import gsap from 'gsap';
import { Breakpoints, Colors } from '../../style/Variables';
import ScrollTrigger from 'gsap/ScrollTrigger';

const Container = styled.div`
	padding: 64px 0px 0px 0px;
	color: ${Colors.darkText};
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	column-gap: var(--gridGutter);
	row-gap: 22px;

	width: 100%;
	${Functions.hyphen()};
	${Functions.breakpoint(Breakpoints.mobile)} {
		column-gap: var(--gridGutter);
		row-gap: 64px;
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
`;

function ListWithImage({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
	const itemHolderRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!itemHolderRef.current) return;
		if (itemHolderRef.current.children.length === 0) return;

		const animation = (elements: Element[]) => {
			if (!elements) return;
			gsap.to(elements, {
				y: 0,
				duration: 0.7,
				stagger: {
					from: 'random',
					amount: 0.5,
					grid: 'auto',
				},
				ease: 'back.out(4)',
			});
			gsap.to(elements, {
				autoAlpha: 1,
				duration: 0.3,
				stagger: { from: 'random', amount: 0.5, grid: 'auto' },
				ease: 'linear',
			});
		};

		gsap.registerPlugin(ScrollTrigger);
		gsap.set(itemHolderRef.current.children, { autoAlpha: 0, y: 20 });
		ScrollTrigger.batch(itemHolderRef.current.children, {
			onEnter: elements => {
				animation(elements);
			},
			onEnterBack: elements => {
				animation(elements);
			},
			start: '20px bottom',
			once: true,
		});

		return () => {
			if (!itemHolderRef.current) return;
			if (itemHolderRef.current.children.length === 0) return;
			gsap.killTweensOf(itemHolderRef.current.children);
		};
	}, [props.children]);

	return (
		<Container ref={itemHolderRef} className={className}>
			{props.children}
		</Container>
	);
}
export default ListWithImage;
