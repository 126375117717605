import gsap, { Sine } from 'gsap';
import { AnimationEffect as AnimationEffect } from '../AnimationEffect';

export class WiggleEffect extends AnimationEffect {
	private _timeline: gsap.core.Timeline | undefined;

	private _speed: number = 1.5;
	private _force: number = 5;

	constructor(item: HTMLDivElement) {
		super(item);
	}

	public activate(): void {
		this._timeline = gsap.timeline({ repeat: -1 });
		this._timeline.set(this._container, { rotation: -this._force });
		this._timeline.to(this._container, {
			duration: this._speed,
			rotation: this._force,
			ease: Sine.easeInOut,
		});
		this._timeline.to(this._container, {
			duration: this._speed,
			rotation: -this._force,
			ease: Sine.easeInOut,
		});
	}

	public deactivate(): void {
		if (this._timeline) {
			this._timeline.kill();
		}
	}
}
