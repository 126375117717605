import { DateUtils } from '@client/core/utils/DateUtils';
import Functions from '@client/style/Functions';
import { Breakpoints, Colors, Fonts, presetColors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import Image, { ImageData } from '../Image';

const Container = styled.div`
	background-color: ${presetColors.dust};
	width: 100%;
	padding: 0 var(--gridMargin);
	padding-top: 100px;

	display: flex;
	flex-direction: column;
	align-items: center;
`;

const BackArrowContainer = styled.div`
	padding-bottom: 30px;
	z-index: 1;
	align-self: start;
`;

const BackArrow = styled.a<{ color?: string }>`
	width: 68px;
	height: 68px;
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: start;
	svg {
		path {
			stroke: ${props => props.color};
		}
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 0px;

		margin-left: -26px;
		justify-content: center;

		background-color: ${Colors.darkBackground};
		svg {
			path {
				stroke: ${Colors.defaultWhite};
			}
		}
	}
`;
const TitleContainer = styled.h1`
	z-index: 1;
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 44px;
	text-align: center;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 110px;
		line-height: 100%;
		width: ${Functions.col(10)};
	}
`;

const DateAuthoeContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 3px;
	font-size: 14px;

	margin-bottom: 40px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 18px;
	}
`;

const StyledImage = styled(Image)`
	aspect-ratio: 16/9;

	${Functions.breakpoint(Breakpoints.tablet)} {
		width: ${Functions.col(12)};
	}
`;

interface NewsPageHeaderProps {
	title: string;
	image: ImageData;
	author: string;
	date: Date;
}

export default function NewsPageHeader(props: NewsPageHeaderProps) {
	return (
		<Container>
			<BackArrowContainer>
				<BackArrow href='/pressesuiten' color={presetColors.blackish}>
					<svg width='15' height='24' viewBox='0 0 15 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M12.7569 21.9679L2.85742 12.0684L12.7569 2.16886' stroke='#262C2E' strokeWidth='4' />
					</svg>
				</BackArrow>
			</BackArrowContainer>
			<TitleContainer>{props.title}</TitleContainer>
			<DateAuthoeContainer>
				<p>Af: {props.author}</p>
				<p>{DateUtils.getDanishDateMonthYearString(props.date)}</p>
			</DateAuthoeContainer>
			<StyledImage data={props.image}></StyledImage>
		</Container>
	);
}
