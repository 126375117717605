import { useEffect, useRef, useState } from 'react';

export const useHammer = () => {
	const hammerRef = useRef<HammerStatic | undefined>(undefined);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!window) {
			return;
		}

		import('hammerjs').then(module => {
			hammerRef.current = module.default;
			setLoaded(true);
		});
	}, []);

	return [loaded, hammerRef.current] as const;
};
