import { CommonSubTitle } from '@client/common/CommonText';
import { Item, loadArtists } from '@client/core/utils/SpotifyLoader';
import { CommonButton } from '@client/style/Shared';
import { Colors } from '@client/style/Variables';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DefaultButton } from '../shared/DefaultButton';
import InputForm from './Form/InputForm';
import SelectedItem from './SelectedItem';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 100vh;
	max-width: 1024px;
	margin: 0 auto;
	margin-top: 30px;
	margin-bottom: 50px;
	/* min-height: 100%; */
`;

const Title = styled(CommonSubTitle)`
	color: ${Colors.darkText};
`;

const StyledButton = styled(CommonButton)`
	width: fit-content;
`;

const SelectionWrapper = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-wrap: wrap;

	margin-bottom: 100px;

	gap: var(--gridGutter);

	* {
		flex-basis: calc(50% - (var(--gridGutter) - var(--gridGutter) / 2));

		img {
			max-width: 100%;
		}
	}
`;

export default function SelectArtistModule({
	onClick,
	saveArtists,
}: {
	onClick: () => void;
	saveArtists: (local: Item[], international: Item[]) => void;
}) {
	const [localArtists, setLocalArtists] = useState<Item[]>([]);
	const [internationalArtists, setInternationalArtists] = useState<Item[]>([]);

	const [localSearchResults, setLocalSearchResults] = useState<Item[]>();
	const [internationalSearchResults, setInternationalSearchResults] = useState<Item[]>();

	const [canMoveOn, setCanMoveOn] = useState<boolean>(false);

	useEffect(() => {
		if (localArtists.length > 0 || internationalArtists.length > 0) setCanMoveOn(true);
		else setCanMoveOn(false);
	}, [localArtists, internationalArtists]);

	const goNext = () => {
		saveArtists(localArtists, internationalArtists);
		onClick();
	};

	const doLocalArtistSearch = async (name: string) => {
		const artists: Item[] = await loadArtists(name);
		const localArtistIds = localArtists.map(artist => artist.id);

		setLocalSearchResults(filterArtists(artists, localArtistIds));
	};

	const doInternationalArtistSearch = async (name: string) => {
		const artists: Item[] = await loadArtists(name);
		const internationalArtistIds = internationalArtists.map(artist => artist.id);

		setInternationalSearchResults(filterArtists(artists, internationalArtistIds));
	};

	const filterArtists = (artists: Item[], ids: (string | undefined)[]) => {
		const uniqueArtists = artists.filter(artist => {
			return !ids.includes(artist.id);
		});

		return uniqueArtists.slice(0, 4);
	};

	const addLocalArtist = (artist: Item) => {
		setLocalArtists([...localArtists, artist]);
	};

	const addInternationalArtist = (artist: Item) => {
		setInternationalArtists([...internationalArtists, artist]);
	};

	const removeLocalArtist = (id?: string) => {
		setLocalArtists(current => current.filter(artist => artist.id !== id));
	};

	const removeInternationalArtist = (id?: string) => {
		setInternationalArtists(current => current.filter(artist => artist.id !== id));
	};

	return (
		<Container>
			<InputForm
				label='Vælg 3 danske artister'
				placeholder='Søg dansk artist'
				inputHandler={doLocalArtistSearch}
				suggestion={localSearchResults}
				onSelect={addLocalArtist}
				disabled={localArtists.length >= 3}
			></InputForm>
			{localArtists.length > 0 && (
				<SelectionWrapper>
					{localArtists.map((artist, index) => {
						return <SelectedItem item={artist} onClose={removeLocalArtist} key={index}></SelectedItem>;
					})}
				</SelectionWrapper>
			)}
			<InputForm
				label='Vælg 3 internationale artister'
				placeholder='Søg international artist'
				inputHandler={doInternationalArtistSearch}
				onSelect={addInternationalArtist}
				suggestion={internationalSearchResults}
				disabled={internationalArtists.length >= 3}
			></InputForm>
			{internationalArtists.length > 0 && (
				<SelectionWrapper>
					{internationalArtists.map((artist, index) => {
						return <SelectedItem item={artist} onClose={removeInternationalArtist} key={index}></SelectedItem>;
					})}
				</SelectionWrapper>
			)}
			<DefaultButton
				backgroundColor={Colors.darkBackground}
				textColor={Colors.lightText}
				title='Næste skridt'
				disabled={!canMoveOn}
				onClick={goNext}
			/>
		</Container>
	);
}
