import { IFormState, IShowConditionOtherIsSet } from './FormStore';

export class FormStoreDataParse {
	public static CMSDataToState(cmsFields: any[]): IFormState {
		const state: IFormState = { fields: [] };

		for (const fieldData of cmsFields) {
			const showConditions: IShowConditionOtherIsSet[] = [];

			for (const condition of fieldData.showConditions) {
				if (condition.modelId === 'formShowConditionOtherEqual') {
					showConditions.push({
						conditionType: 'other-equals',
						otherId: condition.otherId,
						value: condition.otherValue,
					});
				}
			}

			if (fieldData.modelId === 'formFieldInput') {
				state.fields.push({
					fieldType: 'input',
					id: fieldData.fieldId,
					label: fieldData.name,
					value: '',
					required: fieldData.required === true,
					type: fieldData.inputType.toLowerCase(),
					showConditions: showConditions.length ? showConditions : undefined,
				});
			} else if (fieldData.modelId === 'formFieldSelect') {
				state.fields.push({
					fieldType: 'select',
					id: fieldData.fieldId,
					label: fieldData.name,
					value: '',
					notSelectValue: fieldData.notSelectedValue ? fieldData.notSelectedValue : undefined,
					options: fieldData.options.split(','),
					required: fieldData.required === true,
					showConditions: showConditions.length ? showConditions : undefined,
				});
			} else if (fieldData.modelId === 'formFieldTextarea') {
				state.fields.push({
					fieldType: 'textarea',
					id: fieldData.fieldId,
					label: fieldData.name,
					value: '',
					required: fieldData.required === true,
					showConditions: showConditions.length ? showConditions : undefined,
				});
			}
		}

		return state;
	}
}
