import { Item } from '@client/core/utils/SpotifyLoader';
import { DefaultButton } from '@client/modules/shared/DefaultButton';
import { Colors, Fonts } from '@client/style/Variables';
import { CommonInput } from '@client/style/Shared';
import gsap from 'gsap';
import { Expo } from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ContainerMask = styled.div`
	overflow: hidden;
	width: 100%;
	padding-top: 1px;

	/* Offset on border px size of the container below*/
	transform: translateY(-1px);
`;

const Container = styled.div`
	position: relative;
	padding-bottom: 20px;
	border: 2px solid ${Colors.darkText};
	border-radius: 20px;

	transform-origin: top;
	width: 100%;

	overflow: hidden;

	/* Start collapsed, See show() and hide() functions below */
	height: 25px;
	transform: translateY(-40px);
`;

const SuggestionItem = styled.div`
	box-sizing: border-box;
	padding: 20px;
	height: 60px;

	border-bottom: 2px solid ${Colors.darkText};
	font-family: ${Fonts.Geomanist};

	cursor: pointer;
	:hover {
		background-color: ${Colors.lightBackground};
	}
`;

const UserSuggestionWrapper = styled.div`
	padding-left: 20px;
	padding-right: 20px;

	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: 30px;
`;

const CustomLabel = styled.label`
	width: 100%;
	text-align: center;
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};

	font-size: 18px;
	margin-bottom: 20px;
`;

const CustomInput = styled(CommonInput)`
	width: 100%;

	font-family: ${Fonts.Geomanist};
	background-color: ${Colors.ErrorPageBackground};
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 20px;
`;

export default function InputSuggestions({
	suggestions,
	onSelect,
	active,
	showSuggestions,
	className,
}: {
	suggestions: Item[];
	onSelect: (item: Item) => void;
	active?: number;
	showSuggestions: boolean;
	className?: string;
}) {
	const [canAdd, setCanAdd] = useState<boolean>(false);
	const userInputRef = useRef<HTMLInputElement | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);

	while (suggestions.length < 4) {
		suggestions.push({ name: '' } as Item);
	}

	useEffect(() => {
		checkInput();

		userInputRef.current?.addEventListener('input', checkInput);

		return () => {
			userInputRef.current?.removeEventListener('input', checkInput);
		};
	}, []);
	useEffect(() => {
		if (showSuggestions) show();
		else hide();
	}, [showSuggestions]);

	const checkInput = () => {
		if (userInputRef.current && userInputRef.current.value.length > 0) setCanAdd(true);
		else setCanAdd(false);
	};

	const handleSelect = (index: number | string | undefined) => {
		if (typeof index === 'string' || index == undefined) {
			const item: Item = { name: `${index}`, id: index, popularity: -1 };
			onSelect(item);
		} else {
			const suggestion = suggestions[index];
			if (suggestion && suggestion.name) onSelect(suggestion);
		}
	};

	const show = () => {
		if (!containerRef.current) return;
		gsap.set(containerRef.current, {
			height: 'auto',
			y: 0,
		});

		gsap.from(containerRef.current, {
			duration: 0.5,
			ease: Expo.easeInOut,
			y: -40,
			height: 25,
		});
	};

	const hide = (duration: number = 0.5) => {
		if (!containerRef.current) return;

		gsap.to(containerRef.current, {
			duration: duration,
			y: -40,
			ease: Expo.easeInOut,
			height: 25,
		});
	};

	return (
		<ContainerMask>
			<Container ref={containerRef} className={className}>
				{suggestions.map((suggestion, index) => {
					return (
						<SuggestionItem key={index} onClick={() => handleSelect(index)}>
							{suggestion.name}
						</SuggestionItem>
					);
				})}
				<UserSuggestionWrapper>
					<CustomLabel>Findes artisten ikke på listen?</CustomLabel>
					<CustomInput ref={userInputRef} placeholder='Bare skriv den her'></CustomInput>
					<DefaultButton
						disabled={!canAdd}
						backgroundColor={Colors.darkBackground}
						textColor={Colors.lightText}
						title='Tilføj'
						onClick={() => {
							handleSelect(userInputRef.current?.value);
						}}
					></DefaultButton>
				</UserSuggestionWrapper>
			</Container>
		</ContainerMask>
	);
}
