import MagicModuleSwitcher from '@client/modules/MagicModuleSwitcher';
import { Page, PageContent } from '@client/style/Shared';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Menu from '@client/modules/Menu/Menu';
import ProgramPageCategories from '@client/modules/ListModules/ProgramPageCategories';
import CollectionInfoStore, { ICollectionInfo } from '@client/modules/ListModules/ContentTypeStore';
import { Breakpoints, Colors } from '@client/style/Variables';
import SimpleFooter from '@client/modules/Footers/SimpleFooter';
import FilterStore, { IDefaultFilterType } from '@client/modules/ListModules/FilterStore';
import Functions from '@client/style/Functions';
import { DateUtils } from '@client/core/utils/DateUtils';
import Site from '@client/store/Site';
import { HistoryState } from '@client/core/PageSwitch/PageHandler';

const ContentWrapper = styled(PageContent)``;

const Spacing = styled.div<{ color?: string }>`
	background-color: ${props => props.color};
	height: 38vw;
	width: 100%;

	${Functions.breakpoint(Breakpoints.tablet)} {
		height: 18vw;
	}
`;

const ColorWrapper = styled.div<{ bgColor?: string }>`
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: ${props => props.bgColor};
	position: fixed;
`;

export default function ListPage(props: {
	content?: {
		_prefix: string;
		slug: string;
		collectionsToShow: ICollectionInfo[];
		_artists: any[];
		_oddTags: IDefaultFilterType[];
		_locationTags: IDefaultFilterType[];
	};
}) {
	const global = Site.use(state => state.global) as any;

	const collectionInfo = CollectionInfoStore.use(state => state.activeInfo);
	const pageRef = useRef<HTMLDivElement>(null);

	console.dir(collectionInfo);

	useEffect(() => {
		if (props.content) {
			CollectionInfoStore.setCollectionInfo(props.content.collectionsToShow);
			FilterStore.setTagFilters(props.content._oddTags);
			FilterStore.setLocationFilters(props.content._locationTags);
		}

		console.log('global.startDate : ' + global.startDate);
		FilterStore.setDateFilters(DateUtils.getDaysListFromTo(new Date(global.startDate), new Date(global.endDate)));
	}, []);

	useEffect(() => {
		if (
			sessionStorage.getItem('scrollPosition_' + window.location.href) !== null &&
			sessionStorage.getItem('pageHeight_' + window.location.href) !== null &&
			sessionStorage.getItem('navHistoryState') === HistoryState.REPLACE
		) {
			const scrollPosition = parseInt(sessionStorage.getItem('scrollPosition_' + window.location.href) || '0');
			const pageHeight = parseInt(sessionStorage.getItem('pageHeight_' + window.location.href) || '0');

			if (scrollPosition > 0 && pageHeight > 0) {
				pageRef.current!.style.height = pageHeight + 'px';
				window.scrollTo(0, scrollPosition);
			}
		} else {
			FilterStore.setView('tile');
		}
	}, []);

	const getContentFromId = (id: string | undefined) => {
		if (!id) return [];

		const modules = props.content?.collectionsToShow.filter(item => item.id === id)[0]?.modules;

		return modules ?? [];
	};

	return (
		<Page ref={pageRef}>
			<ContentWrapper>
				<ColorWrapper
					bgColor={
						collectionInfo && collectionInfo.backgroundColor ? Functions.hexFromRGB(collectionInfo.backgroundColor) : ''
					}
				/>

				<Menu
					specificColor={Colors.defaultWhite}
					currentSlug={(props.content?._prefix ?? '') + (props.content?.slug ?? '')}
				></Menu>

				{/* Header */}
				<ProgramPageCategories subtitle={collectionInfo?.subtitle} />

				<MagicModuleSwitcher
					modules={collectionInfo ? getContentFromId(collectionInfo.id) : []}
					addedContent={props.content?._artists || undefined}
				/>

				<Spacing
					color={
						collectionInfo?.modules
							? Functions.hexFromRGB(
									collectionInfo.modules[collectionInfo.modules.length - 1]?.backgroundColorLink?.color
								)
							: 'var(--color)'
					}
					className='colorWrapperAnimatedModule'
				/>
				<SimpleFooter />
			</ContentWrapper>
		</Page>
	);
}
