import { BreakpointKey, Breakpoints } from '@client/style/Variables';

type breakpointMatches = {
	[breakpoint in keyof typeof Breakpoints]: boolean;
};
type eventCallback = (breakPoints: breakpointMatches) => any;

class MatchBreakpoint {
	public breakpoints: breakpointMatches = Object.keys(Breakpoints).reduce((acc, key) => {
		acc[key as keyof typeof Breakpoints] = false;
		return acc;
	}, {} as breakpointMatches);
	private _onChangeEvents: { [key: number]: eventCallback } = {};
	private _eventIdCounter = 1;

	constructor() {
		if (typeof window !== 'undefined') {
			for (const breakpoint in Breakpoints) {
				const query = window.matchMedia(`(min-width: ${Breakpoints[breakpoint as BreakpointKey]})`);
				this.breakpoints[breakpoint as BreakpointKey] = query.matches;
				query.addEventListener('change', e => {
					this.breakpoints[breakpoint as BreakpointKey] = e.matches;
					this._onChange();
				});
			}
		}
	}
	private _onChange() {
		for (const id in this._onChangeEvents) {
			this._onChangeEvents[id](this.breakpoints);
		}
	}
	addChangeListener(callback: eventCallback) {
		const id = this._eventIdCounter + 1;
		this._onChangeEvents[id] = callback;
		this._eventIdCounter = id;
		return id;
	}
	removeChangeListener(eventId: number) {
		delete this._onChangeEvents[eventId];
	}
}

export const matchBreakpoint = new MatchBreakpoint();
