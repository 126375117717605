import React, { AnchorHTMLAttributes, DetailedHTMLProps, KeyboardEvent, MouseEvent, Touch, TouchEvent } from 'react';
import LinkTools, { ILink } from '@core/utils/LinkTools';
import LinkHandling from './LinkHandling';

/**
 * Matches the link Model in DatoCMS
 */
export interface IInternalLink {
	_prefix?: string;
	slug?: string;
	title?: string;
}

export interface ILinkData {
	title?: string;
	link?: string;
	internalLink?: IInternalLink;
	onLinkUse?: () => void;
}

export const createLinkObject = (link: { _prefix: string; slug: string } | undefined | null) => {
	if (!link) return { internalLink: undefined };
	const constructedLink = {
		internalLink: {
			_prefix: link._prefix,
			slug: link.slug,
		},
	};
	return constructedLink;
};

interface LinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
	data?: ILinkData;
	customOnClick?: () => void;
}

export default function Link({ data, customOnClick, ...props }: LinkProps) {
	let touchId: number | null = null;
	let isCoolingDown: boolean = false;

	const getLink = (): ILink => {
		if (data && data.link) {
			return LinkTools.getLink(data.link);
		} else if (data && data.internalLink) {
			return LinkTools.getLink(data.internalLink._prefix + (data.internalLink.slug ? data.internalLink.slug : ''));
		} else if (props.href) {
			return LinkTools.getLink(props.href);
		} else {
			console.warn('Link data is misconfigured - ' + JSON.stringify(data));
			return LinkTools.getLink('');
		}
	};

	const link: ILink = getLink();

	const openLink = () => {
		if (isCoolingDown) return;
		cooldown();

		LinkHandling.Instance.openLink(link);

		if (data && data.onLinkUse) data.onLinkUse();
	};

	const click = (e: MouseEvent) => {
		if (!e) return;

		e.preventDefault();
		e.stopPropagation();

		customOnClick?.();

		if (e.ctrlKey) {
			window.open(link.full, '_blank');
		} else {
			openLink();
		}
	};

	const keydown = (e: KeyboardEvent) => {
		if (!e) return;
		if (e.key === 'Enter') {
			e.preventDefault();
			e.stopPropagation();
			customOnClick?.();

			if (e.ctrlKey) {
				window.open(link.full, '_blank');
			} else {
				openLink();
			}
		}
	};

	let moveDist = 0;
	let lastTouchXPos = 0;
	let lastTouchYPos = 0;

	const touchstart = (e: TouchEvent) => {
		const touch: Touch = e.touches[0];

		touchId = touch.identifier;

		moveDist = 0;
		lastTouchXPos = touch.screenX;
		lastTouchYPos = touch.screenY;
	};

	const touchmove = (e: TouchEvent) => {
		const touch: Touch = e.touches[0];

		if (touch.identifier !== touchId) {
			return;
		}

		const deltaX: number = lastTouchXPos - touch.screenX;
		const deltaY: number = lastTouchYPos - touch.screenY;

		moveDist += Math.sqrt(deltaX * deltaX + deltaY * deltaY);

		lastTouchXPos = touch.screenX;
		lastTouchYPos = touch.screenY;
	};

	const touchend = (e: TouchEvent) => {
		if (moveDist <= 5 && touchId !== null) openLink();

		touchId = null;
	};

	const cooldown = (t: number = 200) => {
		isCoolingDown = true;

		setTimeout(() => {
			isCoolingDown = false;
		}, t);
	};

	const getTitle = (): string | undefined => {
		if (data && data.title) return data.title;
		if (data && data.internalLink && data.internalLink.title) return data.internalLink.title;
		if (props.title) return props.title;

		return undefined;
	};

	return (
		<a
			{...props}
			href={link.isLocal ? link.path : link.full}
			target={props.target || '_self'}
			tabIndex={props.tabIndex || 0}
			onClick={click}
			onKeyDown={keydown}
			onTouchStart={touchstart}
			onTouchMove={touchmove}
			onTouchEnd={touchend}
			aria-label={getTitle()}
		>
			{props.children}
		</a>
	);
}
