import React from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import { Breakpoints, Fonts } from '../../style/Variables';
import Image from '../Image';
import Link, { createLinkObject } from '@client/core/PageSwitch/Link';
import { FavouritePanelCompact } from '../Favourites/FavouritePanelCompact';

const Cover = styled(Image)`
	height: auto;
	img {
		object-fit: cover;
		aspect-ratio: 1/1;
	}
`;

const CoverTitle = styled.div`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 24px;
	text-align: center;
	padding-top: 10px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 40px;
	}
`;

const ArtistContainer = styled(Link)<{ index: number }>`
	flex: 0 0 ${Functions.col(2, 4, 0)};
	height: auto;
	box-sizing: border-box;
	position: relative;

	${Functions.breakpoint(Breakpoints.laptop)} {
		flex: 0 0 ${Functions.col(1, 4, 0)};
	}
`;

const FavContainer = styled.div`
	position: absolute;
	right: 5px;
	top: 5px;
	z-index: 1;
`;

const Container = styled.div`
	position: relative;
`;

function SingleImageEntity({ item, index }: { item: any; index: number }) {
	const canFavorite = item.modelId === 'artistPage';

	const renderFavorite = () => {
		if (!canFavorite) return null;

		return (
			<FavContainer>
				<FavouritePanelCompact artistId={item.id} />
			</FavContainer>
		);
	};

	return (
		<Container>
			<ArtistContainer data={createLinkObject(item)} index={index} key={'ListWithImage_' + index}>
				{item.images ? (
					<Cover data={item.images[0]} />
				) : item.previewImage ? (
					<Cover data={item.previewImage} />
				) : item.image ? (
					<Cover data={item.image}></Cover>
				) : (
					<></>
				)}

				<CoverTitle>{item.title}</CoverTitle>
			</ArtistContainer>
			{renderFavorite()}
		</Container>
	);
}
export default SingleImageEntity;
