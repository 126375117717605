import { DateUtils } from '@client/core/utils/DateUtils';
import Site from '@client/store/Site';
import Functions from '@client/style/Functions';
import { Breakpoints, Fonts } from '@client/style/Variables';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '../shared/Checkbox';
import FilterStore from './FilterStore';

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;

	padding: 0 var(--gridMargin);
`;

const TextButton = styled.button`
	font-size: 34px;
	align-self: end;
	margin-top: 20px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 64px;
		align-self: center;
		margin-top: 60px;
	}
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
`;

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		justify-content: center;
	}
`;

export default function FilterSelector({
	data,
}: {
	data: {
		onButtonClick: () => void;
		useLocationFilter: boolean;
		useTagFilter: boolean;
		useDateFilter: boolean;
		backgroundColor: any;
	};
}) {
	const global = Site.use(state => state.global) as any;

	const selectedDateFilters: string = FilterStore.use(state => state.selectedDateFilters);
	const locationFilters = FilterStore.use(state => state.locationFilters);
	const selectLocationFilter = FilterStore.use(state => state.selectedLocationFilters);
	const dayFilters = FilterStore.use(state => state.dateFilters);

	const tagFilters = FilterStore.use(state => state.tagFilters);
	const selectedTagFilters = FilterStore.use(state => state.selectedTagFilters);

	const buttonClicked = () => {
		data.onButtonClick();
	};

	// console.log('dayFilters');
	// console.log(dayFilters);

	return (
		<Container>
			<FilterContainer>
				{data.useDateFilter &&
					dayFilters &&
					dayFilters.slice(0).map((filter, index) => (
						<Checkbox
							key={'dayFilter_' + index}
							label={DateUtils.getWeekDayName(filter)}
							// label={filter.toString()}
							checked={selectedDateFilters === filter.toString()}
							onChange={checked => FilterStore.changeDateFilter(filter, checked)}
							backgroundColor={data.backgroundColor}
						></Checkbox>
					))}
			</FilterContainer>
			<FilterContainer>
				{data.useLocationFilter &&
					locationFilters &&
					locationFilters.map(filter => (
						<Checkbox
							key={'locationFilter_' + filter.id}
							label={filter.name}
							checked={selectLocationFilter.includes(filter.id)}
							onChange={checked => FilterStore.changeLocationFilter(filter.id, checked)}
						></Checkbox>
					))}
			</FilterContainer>

			<FilterContainer>
				{data.useTagFilter &&
					tagFilters &&
					tagFilters.map(filter => (
						<Checkbox
							key={'tagFilter_' + filter.id}
							label={filter.name}
							checked={selectedTagFilters.includes(filter.id)}
							onChange={checked => FilterStore.changeTagFilter(filter.id, checked)}
						></Checkbox>
					))}
			</FilterContainer>
			{/* <TextButton onClick={buttonClicked}>Ok</TextButton> */}
		</Container>
	);
}
