import Functions from '@client/style/Functions';
import { Breakpoints, Colors } from '@client/style/Variables';
import gsap, { Power2 } from 'gsap';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import RoundButtonWithAnimation from './RoundButtonWithAnimation';

const OuterContainer = styled.div<{ backgroundColor?: string }>`
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'inherit')};
	pointer-events: all;
`;

const TransitionContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

const Container = styled.div`
	position: relative;
	padding: 20px;
	max-width: 800px;
	width: 100%;
	height: 100%;
	pointer-events: all;
`;

const InnerContainer = styled.div<{ backgroundColor: string }>`
	position: relative;
	height: 100%;
	padding: 20px;
	padding-top: 80px;
	background-color: ${props => props.backgroundColor};
	overflow-y: scroll;
	overscroll-behavior: contain;

	${Functions.breakpoint(Breakpoints.laptop)} {
		padding: 50px;
		padding-top: 150px;
	}

	scrollbar-width: thin;
	::-webkit-scrollbar {
		width: 0.6em;
		display: none;
	}
	::-webkit-scrollbar-track {
		background: white;

		&:hover {
			background: ${Colors.defaultOffWhite};
			background-clip: padding-box;

			border: 1px solid transparent;
			border-radius: 1em;
		}
	}
	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 1em;
	}
`;

const ScrollContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 140px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		padding-bottom: 120px;
	}
`;

const ScrollCover = styled.div<{ backgroundColor: string }>`
	height: 40%;
	width: calc(100% - 40px);
	max-height: 200px;
	bottom: 20px;
	position: absolute;
	pointer-events: none;

	background: linear-gradient(
		0deg,
		${props => props.backgroundColor} 0%,
		${props => props.backgroundColor} 50%,
		${props => props.backgroundColor}00 100%
	);
`;

const CloseButton = styled(RoundButtonWithAnimation)`
	position: absolute;
	top: 50px;
	right: 50px;
	width: 40px;
	height: 40px;
	pointer-events: all;
`;

/**
 * Bottom container
 */
const BottomContainer = styled.div`
	position: absolute;
	bottom: 50px;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
`;

const BottomContainerInner = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	justify-content: center;
	padding-left: 50px;
	padding-right: 50px;
`;

interface IProps {
	className?: string;
	children?: JSX.Element | JSX.Element[];
	bottomChildren?: JSX.Element | JSX.Element[];
	backgroundColor: string;
	dimColor?: string;
	onCloseButtonClick?: () => void;
}

export const DefaultOverlayFrame = (props: IProps) => {
	const outerContainerRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (!outerContainerRef.current) return;
		gsap.from(outerContainerRef.current, {
			y: '100%',
			ease: Power2.easeOut,
			duration: 0.5,
		});
	}, []);

	const onCloseButtonClick = (): void => {
		if (!outerContainerRef.current) {
			props.onCloseButtonClick?.();
			return;
		}
		gsap.to(outerContainerRef.current, {
			y: '100%',
			ease: Power2.easeIn,
			duration: 0.5,
			onComplete: () => {
				props.onCloseButtonClick?.();
			},
		});
	};

	return (
		<>
			<OuterContainer backgroundColor={props.dimColor}>
				<TransitionContainer ref={outerContainerRef}>
					<Container>
						<InnerContainer backgroundColor={props.backgroundColor}>
							<ScrollContainer>{props.children && props.children}</ScrollContainer>
						</InnerContainer>
						<ScrollCover backgroundColor={props.backgroundColor} />
						{props.onCloseButtonClick && (
							<CloseButton size={40} interaction={onCloseButtonClick}>
								<svg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M0.656854 12.6569L6.31371 7M11.9706 1.34315L6.31371 7M11.9706 12.6569L6.31371 7M6.31371 7L0.656854 1.34315'
										stroke='#262C2E'
										strokeWidth='1.5'
									/>
								</svg>
							</CloseButton>
						)}
					</Container>
					{props.bottomChildren && (
						<BottomContainer>
							<BottomContainerInner>{props.bottomChildren}</BottomContainerInner>
						</BottomContainer>
					)}
				</TransitionContainer>
			</OuterContainer>
		</>
	);
};
