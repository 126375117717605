import { IAzureAuthConfig, IAzureAuthData, IAzureTokenData, IAzureVerifier, IUserInfo } from './IAzureAuthConfig';
import { AzureAuthHelper } from './AzureAuthHelper';
import { AzureAuthLog } from './AzureAuthLog';
import { jwtDecode } from 'jwt-decode';

export class AzureAuthUser {
	private _data: IAzureAuthData;
	private _config: IAzureAuthConfig;
	private _log: AzureAuthLog = new AzureAuthLog('[AzureAuth]');

	constructor(config: IAzureAuthConfig, data?: Partial<IAzureAuthData>) {
		this._config = config;
		this._data = { ...{}, ...data };

		this._log.enabled = this._config.log === true;
	}

	public get data() {
		return this._data;
	}

	/**
	 * Evaluation
	 */
	public canRetreiveAccessToken(): boolean {
		if (!this._data.user?.code) return false;
		if (!this._data.verifier?.codeVerifier) return false;
		return true;
	}

	public canRefreshAccessToken(): boolean {
		if (!this._data.user?.code) return false;
		if (!this._data.token?.refresh_token) return false;

		return true;
	}

	public isComplete(): boolean {
		if (!this._data.user?.code) return false;
		if (!this._data.verifier?.codeVerifier) return false;
		if (!this._data.token?.access_token) return false;
		return true;
	}

	public secondsToExpire(): number {
		if (!this._data.token?.expires_on) return 0;

		const nowDate = new Date();
		const expireDate = new Date(this._data.token.expires_on * 1000);

		const diff: number = (expireDate.getTime() - nowDate.getTime()) / 1000;
		return diff;
	}

	/**
	 * Login
	 *
	 * The link is paired with a verifier
	 */
	public async getNewLoginURL(): Promise<string> {
		this._log.log('AuthUser.getNewLoginURL();');

		const verifier: IAzureVerifier = await AzureAuthHelper.getCodeVerifier();
		this.setData({ verifier: { codeChallenge: 'hidden', codeVerifier: verifier.codeVerifier } });

		const searchParams = new URLSearchParams();
		searchParams.append('p', this._config.policy);
		searchParams.append('client_id', this._config.clientId);
		searchParams.append('nonce', 'defaultNonce');
		searchParams.append('redirect_uri', this._config.redirectURL);
		searchParams.append('scope', 'openid offline_access');
		searchParams.append('response_type', 'code');
		// searchParams.append('prompt', 'login');
		searchParams.append('code_challenge', verifier.codeChallenge);
		searchParams.append('code_challenge_method', 'S256');
		return `${this._config.authServer}oauth2/v2.0/authorize?${searchParams.toString()}`;
	}

	/**
	 * Loading token
	 */
	public async loadAccessToken() {
		this._log.log('loadAccessToken');

		// check if we have enough info to load the token
		if (!this.canRetreiveAccessToken()) {
			throw Error('bad state');
		}

		// if we already have the token or should renew one
		if (this.isComplete()) {
			if (this.secondsToExpire() < 60) {
				throw Error('token has expired');
			}

			// if less than 5 hours back renew
			if (this.secondsToExpire() < 60 * 60 * 5) {
				try {
					await this.renewAccessToken();
				} catch (e) {
					throw Error('unable to renew access token');
				}
			}
		} else {
			// get a new token
			let token: IAzureTokenData;

			try {
				this._log.log('get token');
				token = await this.getAccessTokenCall();

				this.setData({ token: token });
			} catch (e) {
				throw Error('unable to get access token');
			}

			try {
				const userInfo: IUserInfo = jwtDecode(token.id_token);

				this.setData({ userInfo: userInfo });
			} catch (e) {
				throw Error('unable to decode user info: ' + e);
			}
		}

		// check if the token was loaded
		if (!this.isComplete()) {
			throw Error('bad state');
		}

		this._log.log('success');
		this._log.dir(this.data);
	}

	/**
	 * Renew access token
	 */
	public async renewAccessToken() {
		this._log.log('renewAccessToken');

		if (!this.canRetreiveAccessToken()) {
			throw Error('bad state');
		}

		// do we already have a token that can be renewd
		if (!this.isComplete()) {
			throw Error('bad state');
		}

		try {
			this._log.log('nenew token');
			const token: IAzureTokenData = await this.getRenewedAccessTokenCall();
			this.setData({ token: token });
		} catch (e) {
			throw Error('unable to renew access token');
		}

		if (!this.isComplete()) {
			throw Error('bad state');
		}

		this._log.log('success');
		this._log.dir(this.data);
	}

	public async getRenewedAccessTokenCall() {
		if (!this.canRefreshAccessToken()) {
			throw new Error('canRefreshAccessToken() should be fulfilled before renewing an access token');
		}

		if (!this._data.token?.refresh_token) return;

		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		const data = new URLSearchParams();
		data.append('client_id', this._config.clientId);
		data.append('scope', this._config.scopes.join(' '));
		data.append('refresh_token', this._data.token?.refresh_token);
		data.append('grant_type', 'refresh_token');

		const request: RequestInit = {
			method: 'POST',
			headers: headers,
			body: data,
			redirect: 'follow',
		};

		this._log.log('getRenewedAccessTokenCall();');
		this._log.log(data.toString());

		try {
			const result = await fetch(
				`${this._config.authServer}${this._config.policy.toLowerCase()}/oauth2/v2.0/token`,
				request
			);
			const json = await result.json();
			return json;
		} catch (e) {
			this._log.log('unable to renew access token');
			this._log.warn(e);
			throw Error('unable to renew access token');
		}
	}

	/**
	 * Call to get initial access token
	 */
	private async getAccessTokenCall() {
		if (!this.canRetreiveAccessToken()) {
			throw new Error('canRetreiveAccessToken() should be fulfilled before getting an access token');
		}

		if (!this._data.user?.code) return undefined;
		if (!this._data.verifier?.codeVerifier) return undefined;

		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		const data = new URLSearchParams();
		data.append('client_id', this._config.clientId);
		data.append('redirect_uri', 'https://jwt.ms');
		data.append('scope', this._config.scopes.join(' '));
		data.append('code', this._data.user.code);
		data.append('grant_type', 'authorization_code');
		data.append('code_verifier', this._data.verifier.codeVerifier);

		const request: RequestInit = {
			method: 'POST',
			headers: headers,
			body: data,
			redirect: 'follow',
		};

		this._log.log('getAccessTokenCall();');
		this._log.log(data.toString());

		try {
			const result = await fetch(
				`${this._config.authServer}${this._config.policy.toLowerCase()}/oauth2/v2.0/token`,
				request
			);
			const json = await result.json();
			return json;
		} catch (e) {
			this._log.log('unable to load access token');
			this._log.warn(e);
			throw Error('unable to load access token');
		}
	}

	public async signOutCall() {
		if (!this.canRetreiveAccessToken()) return;

		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		const data = new URLSearchParams();
		data.append('client_id', this._config.clientId);

		const request: RequestInit = {
			method: 'GET',
			headers: headers,
			redirect: 'follow',
		};

		this._log.log('signOutCall();');
		this._log.log(data.toString());

		try {
			const result = await fetch(
				`${this._config.authServer}${this._config.policy.toLowerCase()}/oauth2/v2.0/logout?${data.toString()}`,
				request
			);

			this._log.log('Sign out done');
			this._log.dir(result);

			return result;
		} catch (e) {
			this._log.log('unable to sign out');
			this._log.warn(e);
			throw Error('unable to sign out');
		}
	}

	/**
	 * State
	 */
	public setData(data: Partial<IAzureAuthData>): void {
		this._data = { ...this._data, ...data };
		this.saved = this._data;
	}

	public restore(): void {
		const restoredData: IAzureAuthData | undefined = this.saved;
		if (restoredData) {
			this.setData(restoredData);
		}
	}

	private get saved(): IAzureAuthData | undefined {
		let data: IAzureAuthData | undefined = undefined;

		try {
			const dataStr: string | null = localStorage.getItem('auth.user');
			if (dataStr) {
				data = JSON.parse(dataStr);
			}
		} catch (e) {}

		return data;
	}

	private set saved(data: IAzureAuthData | undefined) {
		if (!data) localStorage.removeItem('auth.user');
		else localStorage.setItem('auth.user', JSON.stringify(data));
	}

	public flush(): void {
		this._log.log('AuthUser.flush();');
		this._data = {};
		this.saved = {};
	}
}
