import React, { useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import { ScrollToPlugin, ScrollTrigger } from 'gsap/all';

const Container = styled.div`
	height: fit-content;
	width: 100%;
	position: relative;
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;
const Inner = styled.div`
	position: relative;
	left: 0;
	display: flex;
`;

export default function DraggableScrollContainer({
	children,
	className,
	activeIndex,
	dragToNewIndex,
}: {
	children: Array<any>;
	className?: string;
	activeIndex: number;
	dragToNewIndex: (index: number) => void;
}) {
	const [localActiveIndex, setLocalActiveIndex] = React.useState(activeIndex);
	const carouselContainer = React.useRef<HTMLDivElement | null>(null);
	let draggable: globalThis.Draggable[];
	let observer: Observer;

	const canTriggerUpdates = React.useRef(false);

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

		initDraggable();
		requestAnimationFrame(() => {
			moveToIndex(activeIndex, 0.5);
		});
		// moveToIndex(activeIndex, 0.5);

		const targets = window.document.querySelectorAll('.draggableChildElement');
		const scrollTriggers: ScrollTrigger[] = [];

		targets.forEach((target, index) => {
			const trigger = ScrollTrigger.create({
				trigger: target,
				scroller: carouselContainer.current,
				horizontal: true,
				start: 'left 50%',
				end: 'right 50%',
				onEnter: () => canTriggerUpdates.current && updateIndex(index),
				onEnterBack: () => canTriggerUpdates.current && updateIndex(index),
			});
			scrollTriggers.push(trigger);
		});

		return () => {
			if (draggable) draggable[0].kill();
			scrollTriggers.forEach(trigger => trigger.kill());
			observer?.disable();
			observer?.kill();
		};
	}, []);

	useEffect(() => {
		setLocalActiveIndex(activeIndex);
		// If the state is changed from outside, we need to move the carousel to the new index
		if (activeIndex !== localActiveIndex) moveToIndex(activeIndex, 0.5);
	}, [activeIndex]);

	const initDraggable = () => {
		if (!carouselContainer.current) return;
		gsap.registerPlugin(Draggable, InertiaPlugin, ScrollTrigger);

		observer = ScrollTrigger.observe({
			target: carouselContainer.current,
			type: 'wheel',

			onChange: () => {
				canTriggerUpdates.current = true;
			},
			onStop: () => {
				canTriggerUpdates.current = false;
			},
		});

		// const boundaries = calcBoundariesAndSnapPoints(;

		draggable = Draggable.create(carouselContainer.current, {
			type: 'scrollLeft',
			throwProps: true,
			throwResistance: 1,
			maxDuration: 0.25,
			edgeResistance: 0.65,
			onDragStart: () => {
				canTriggerUpdates.current = true;
			},

			onThrowComplete: () => {
				canTriggerUpdates.current = false;
			},
		});
	};

	const updateIndex = (newIndex: number) => {
		setLocalActiveIndex(newIndex);
		dragToNewIndex(newIndex);
	};

	function moveToIndex(index: number, duration: number) {
		const targets = window.document.querySelectorAll('.draggableChildElement');
		if (!targets[index]) return;
		gsap.to(carouselContainer.current, {
			duration: duration,
			scrollTo: { x: (targets[index] as HTMLElement).offsetLeft, autoKill: true },
			ease: 'back(1.3).out',
		});
	}

	return (
		<Container className={className} ref={carouselContainer}>
			<Inner>
				{React.Children.map(children, (child, idx) => {
					return <div className='draggableChildElement'>{React.cloneElement(child, {})}</div>;
				})}
			</Inner>
		</Container>
	);
}
