import { context } from '@client/App';
import Functions from '@client/style/Functions';
import { Colors } from '@client/style/Variables';
import { useAuthenticatedUser } from '@client/utils/AuthStore';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ bgColor?: string }>`
	z-index: 0;
	position: relative;
	background-color: ${props => props.bgColor || 'white'};
	pointer-events: none;
	padding-top: 100px;
	display: flex;
`;

const StoreDomContainer = styled.div`
	width: 100%;
	pointer-events: all;
	position: relative;

	preo-storefront {
	}
`;

interface IProps {
	data: {
		backgroundColorLink: any;
		cmsName: string;
	};
}

export const StoreModule = (props: IProps) => {
	const user = useAuthenticatedUser();

	const preoRef = useRef<Element>();

	useEffect(() => {
		console.log('preoRef : ', preoRef);

		if (!preoRef.current) return;

		console.log('Ref is set');

		const onPreoEvent = (e: any) => {
			console.dir(e);

			if (e.type === 'onSsoLogin') {
				context.auth.signIn(true);
			}
		};

		preoRef.current.addEventListener('onSsoLogin', onPreoEvent);

		return () => {
			preoRef.current?.removeEventListener('onSsoLogin', onPreoEvent);
		};
	}, [preoRef]);

	useEffect(() => {
		// @ts-expect-error - They have no types for this
		import('@preo-live/components');
	});

	return (
		<Container
			bgColor={props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<StoreDomContainer className='iFrameContainer'>
				{/* @ts-expect-error - They have no types for this */}
				<preo-storefront
					ref={preoRef}
					sdk-id='dev:eu:demo:edt_2u7pzb7pV3FdOvEkFucJYhaa5hc'
					auth-method='sso'
					auth-user-first-name={user?.userInfo?.given_name}
					auth-user-last-name={user?.userInfo?.family_name}
					auth-user-phone={user?.userInfo?.extension_MobilePhone}
					auth-user-email={user?.userInfo?.emails.length ? user?.userInfo?.emails[0] : undefined}
					checkout-success-url='https://shop.preo.dev/smukfest/smukfest-2025/transaction'
					checkout-cancel-url='https://shop.preo.dev/smukfest/smukfest-2025/transaction'
					age-gate-mode='on_cart_add'
					color-brand={Colors.loginButton}
					color-text-button='#fff'
					is-catalogue='true'
				/>
			</StoreDomContainer>
		</Container>
	);
};
