import { CommonSubTitle, CommonText } from '@client/common/CommonText';
import HTML from '@client/core/utils/HTML';
import { Colors, Fonts, presetColors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import { DefaultButton } from '../shared/DefaultButton';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;
	height: 100%;
	/* min-height: 100%; */
	min-height: 100vh;
	/* position: relative; */
	position: relative;
	padding-bottom: 55px;
`;

const Background = styled.div`
	margin-left: calc(-1 * var(--gridMargin));
	z-index: 0;
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${presetColors.evergreen};
`;

const Title = styled(CommonSubTitle)`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	z-index: 2;
`;

const Text = styled(CommonText)`
	z-index: 2;
	margin-bottom: 40px;
`;

interface IProps {
	title: string;
	text: string;
	buttonText: string;
	onClick: () => void;
}

export default function ThankYouModule({ title, text, buttonText: buttonText, onClick }: IProps) {
	return (
		<Container>
			<Background />

			<Title>{title}</Title>
			<Text dangerouslySetInnerHTML={{ __html: HTML.clean(text) }}></Text>
			<DefaultButton
				backgroundColor={Colors.lightButtonBackground}
				textColor={presetColors.evergreen}
				title={buttonText}
				onClick={onClick}
			></DefaultButton>
		</Container>
	);
}
