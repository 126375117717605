import React, { useEffect } from 'react';
import DebugModule from '@modules/DebugModule';
import RouterSwitch from '@client/RouterSwitch';
import Global from '@style/Global';
import Normalize from '@style/Normalize';
import Mode from '@core/interface/Mode';
import { SnailLog } from '@core/SnailLog';
import PageHandler from '@core/PageSwitch/PageHandler';
import ResizeHandler from '@core/utils/ResizeHandler';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import Site, { DefaultState } from './store/Site';
import LinkoutPaper from './modules/Linkout/LinkoutPaper';
import LinkHandling from './core/PageSwitch/LinkHandling';
import { OverlaysComponent } from './core/modules/Overlays/OverlaysComponent';
import CookieInformationMiddleware from './modules/CookieInformationMiddleware';
import { GoogleAnalyticsToCookieInformation } from './core/utils/GoogleAnalytics';
import { OverlayFlipMobile } from './core/modules/FlipMobile/OverlayFlipMobile';
import styled from 'styled-components';
import { Index } from './style/Variables';
import gsap from 'gsap';
import { MorphSVGPlugin, ScrollTrigger } from 'gsap/all';
import { Papand } from './modules/Papand';
import { AzureAuth, AzureAuthStatus } from './core/auth/azure/AzureAuth';
import { SmukApi } from './utils/SmukApi';
import { IAzureAuthData } from './core/auth/azure/IAzureAuthConfig';
import AuthStore from './utils/AuthStore';
import config from './utils/SmukConfig';

if (typeof window !== 'undefined') {
	gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);
	ScrollTrigger.config({
		limitCallbacks: true, // Don't fire callbacks if the user quickly scrolls past elements
		ignoreMobileResize: true, // Don't recalculate viewport height when mobile browser shows/hides address bar
	});
}

const PixiBackground = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${Index.PixiBackground};
`;

const PixiForeground = styled(PixiBackground)`
	z-index: ${Index.PixiForeground};
`;

export interface IContext {
	auth: AzureAuth;
	smukAPI: SmukApi;
}

const auth: AzureAuth = new AzureAuth(config.authConfig);
export const context: IContext = {
	auth: auth,
	smukAPI: new SmukApi(config, auth),
};

export default function App({ data = null }) {
	const ssrState = typeof window !== 'undefined' ? (window as any)._ssrState : data ? data : {};

	Site.set({
		server: ssrState?.server ?? DefaultState.server,
		language: ssrState?.language ?? DefaultState.language,
		global: ssrState?.global ?? DefaultState.global,
		news: ssrState?.news ?? DefaultState.news,
		footer: ssrState?.footer ?? DefaultState.footer,
		topMenu: ssrState?.topMenu ?? DefaultState.topMenu,
	});

	const server = Site.use(state => state.server);

	useEffect(() => {
		const handleError = (err: ErrorEvent) => {
			if (ssrState?.server?.mode !== Mode.PROD || !ssrState?.server?.bugsnagKey) return;
			Bugsnag.notify(err.message);
			return false;
		};

		const handleRejection = (e: PromiseRejectionEvent) => {
			if (ssrState?.server?.mode !== Mode.PROD || !ssrState?.server?.bugsnagKey) return;
			console.warn('Caught rejection: ' + e.reason.message);
			Bugsnag.notify(e.reason.message);
			return false;
		};

		if (ssrState?.server?.mode === Mode.PROD && ssrState?.server?.bugsnagKey) {
			Bugsnag.start({
				apiKey: ssrState.server.bugsnagKey,
				plugins: [new BugsnagPluginReact(React)],
			});

			window.addEventListener('error', handleError);
			window.addEventListener('unhandledrejection', handleRejection);
		}

		if (ssrState?.server?.mode === Mode.PROD) {
			SnailLog.brand();
			PageHandler.getInstance(ssrState.global, ssrState?.server?.uid);
		} else {
			SnailLog.log(ssrState);
			PageHandler.getInstance(ssrState.global);
		}
		ResizeHandler.getInstance();

		LinkHandling.Instance.handleExternalLink = LinkoutPaper.Instance.handleExternalLinkDefault;

		// auth callbacks
		if (typeof window !== 'undefined') {
			context.auth.statusChange.on((status: AzureAuthStatus) => {
				AuthStore.setStatus(status);
			});
			context.auth.userSignedIn.on((data: IAzureAuthData) => {
				AuthStore.setUser(data);
			});
			context.auth.userSignedOut.on(() => {
				AuthStore.setUser(undefined);
			});
			context.auth.restore();
		}

		// ScrollTrigger.normalizeScroll({ allowNestedScroll: true });

		// ScrollTrigger.normalizeScroll({
		// 	allowNestedScroll: true,
		// 	type: 'touch,wheel,pointer', // now the page will be drag-scrollable on desktop because "pointer" is in the list
		// });

		// setTimeout(() => {
		// 	Overlays.Instance.open({
		// 		create: (controller: OverlayController) => {
		// 			return <OverlayNewsDuck controller={controller} />;
		// 		},
		// 	});
		// }, 100);

		return function () {
			window.removeEventListener('error', handleError);
			window.removeEventListener('unhandledrejection', handleRejection);

			PageHandler.getInstance().unmount();
			ResizeHandler.getInstance().unmount();
		};
	}, []);

	return (
		<div className={'app-content ' + (typeof window === 'undefined' ? 'ssr' : 'client')}>
			<CookieInformationMiddleware />
			<GoogleAnalyticsToCookieInformation />
			<Normalize />
			<Global />
			{server.mode === Mode.DEV ? <DebugModule /> : <></>}

			<RouterSwitch data={ssrState ? ssrState.content : null} />
			{ssrState && ssrState.news.message[0] && <Papand message={ssrState.news.message[0]} />}
			<OverlaysComponent />
			<OverlayFlipMobile />
		</div>
	);
}
