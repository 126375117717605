export class DateUtils {
	private static _indexToWeekday: string[] = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];

	public static getDaysListFromTo(startDate: Date, endDate: Date): Date[] {
		// console.log('getDaysListFromTo();');
		const dateArray: Date[] = [];
		const currentDate = new Date(startDate);
		currentDate.setHours(0);
		currentDate.setMinutes(0);
		currentDate.setSeconds(0);
		currentDate.setMilliseconds(0);

		// console.log('startDate : ' + startDate);
		// console.log('endDate : ' + endDate);

		while (currentDate <= endDate) {
			dateArray.push(new Date(currentDate));
			currentDate.setDate(currentDate.getDate() + 1);
		}

		// dateArray.push(new Date(currentDate));
		// console.log(dateArray);

		return dateArray;
	}

	public static getLowestArtistDate = (artists: any[]): Date => {
		let best: number | undefined;
		const l = artists.length;

		for (let i = 0; i < l; i += 1) {
			const artist = artists[i];
			const artistDate = new Date(artist.startTime);

			// Adjusting the artist dates, to show the day before if playing past midnight
			artistDate.setHours(artistDate.getHours() - 6);

			if (!best || artistDate.getTime() < best) {
				best = artistDate.getTime();
			}
		}

		if (!best) return new Date();

		return new Date(best);
	};

	public static getHighestArtistDate = (artists: any[]): Date => {
		let best: number | undefined;
		const l = artists.length;

		for (let i = 0; i < l; i += 1) {
			const artist = artists[i];
			const artistDate = new Date(artist.startTime);

			// Adjusting the artist dates, to show the day before if playing past midnight
			artistDate.setHours(artistDate.getHours() - 6);

			if (!best || artistDate.getTime() > best) {
				best = artistDate.getTime();
			}
		}

		if (!best) return new Date();

		return new Date(best);
	};

	public static getWeekDayName(date: Date) {
		// console.log('getWeekDayName(); ' + date);
		// console.log('date.getDay() : ' + date.getDay());

		return this._indexToWeekday[date.getDay()];
	}

	public static getDanishDateMonthYearString(date: Date, includeYear: boolean = true) {
		const returnVal = includeYear
			? date.toLocaleDateString('da-DK', { year: 'numeric', month: 'long', day: 'numeric' })
			: date.toLocaleDateString('da-DK', { month: 'long', day: 'numeric' });
		return returnVal;
	}

	public static isOnSameSmukDay(date: Date, compareToDate: Date) {
		date.setHours(date.getHours() - 6);
		return date.getDate() === compareToDate.getDate() && date.getMonth() === compareToDate.getMonth();
	}

	public static normalizeTime(date: string | Date): Date {
		// console.log('normalizeTime(); ' + date);

		let converted: Date;
		if (typeof date === 'string') {
			converted = new Date(date.split('+')[0]);
		} else {
			converted = date;
		}
		// console.log('converted : ' + converted);

		return converted;
	}

	public static formatTime(date: Date): string {
		let hours: string = date.getHours().toString();
		if (hours.length === 1) hours = '0' + hours;

		let minutes: string = date.getMinutes().toString();
		if (minutes.length === 1) minutes = '0' + minutes;

		return hours + ':' + minutes;
	}

	// daylight saving time
	public static isDST(date: Date) {
		const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
		const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
		return Math.max(jan, jul) !== date.getTimezoneOffset();
	}

	public static getHoursBetween(start: Date, end: Date): number {
		const diff = Math.abs(end.getTime() - start.getTime());
		const hours = diff / (1000 * 60 * 60);
		return hours;
	}
}
