import { CommonText, CommonTitle } from '@client/common/CommonText';
import HTML from '@client/core/utils/HTML';
import { ILocation } from '@client/pages/MapPage';
import Functions from '@client/style/Functions';
import { Breakpoints, Fonts } from '@client/style/Variables';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Image from '../Image';
import gsap, { Power0, Power1 } from 'gsap';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';

const Container = styled.div<{ bgColor?: string }>`
	overflow-y: scroll;
	background-color: ${props => props.bgColor};
	color: ${props => props.color};
	z-index: 1001;

	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;

	${Functions.breakpoint(Breakpoints.tablet)} {
		width: 27%;
		min-width: 480px;
		height: 100%;
		right: 0;
		top: 0;
		left: unset;
	}
`;

const Title = styled(CommonTitle)<{ color?: string }>`
	padding: 0 var(--gridMargin);
	color: ${props => props.color};

	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 34px;

	width: 100%;
	margin-top: 20px;
	${Functions.breakpoint(Breakpoints.tablet)} {
		padding: 0 35px;
		margin-top: 60px;
	}
`;

const ImageWrapper = styled.div`
	display: none;
	padding: 0 35px;
	width: 100%;
	height: auto;
	aspect-ratio: 16/9;
	margin-top: 40px;

	${Functions.breakpoint(Breakpoints.tablet)} {
		display: block;
	}
`;

const InfoMid = styled.div`
	padding: 0 var(--gridMargin);

	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	${Functions.breakpoint(Breakpoints.tablet)} {
		margin-top: 40px;

		margin-bottom: 40px;

		padding: 0 35px;
		gap: 16px;
	}
`;

const InfoEntry = styled.div`
	display: flex;

	justify-content: space-between;
	gap: 10px;
`;

const Text = styled(CommonText)<{ color?: string }>`
	color: ${props => props.color};
	margin: 0;
	font-size: 20px;

	b {
		font-family: ${Fonts.Geomanist};
		font-weight: ${Fonts.boldWeight};
	}
	text-align: left;
`;
const InfoText = styled(Text)`
	flex: 0 1 50%;
`;

const InfoBottom = styled.div<{ color?: string }>`
	color: ${props => props.color};
	padding: 12px var(--gridMargin);
	width: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
	background-color: rgba(38, 44, 46, 0.1);
`;

export default function InfoContainer({
	show,
	currentLocation,
	soundColors,
	soundTexts,
	onClose,
	onOffsetChange,
	onOpen,
}: {
	show: boolean;
	onOffsetChange?: (offset: { x: number; y: number }) => void;
	currentLocation?: ILocation;
	soundColors: { noSoundColor: any; lowSoundColor: any; midSoundColor: any; highSoundColor: any };
	soundTexts: { lowSoundText: string; midSoundText: string; highSoundText: string };
	onClose?: () => void;
	onOpen?: () => void;
}) {
	const [renderImage, setRenderImage] = React.useState(false);
	const infoContainerRef = React.useRef<HTMLDivElement | null>(null);
	const isMobile = useIsMobile('tablet');

	const updateOffset = () => {
		if (infoContainerRef.current === null) return;
		const rect = infoContainerRef.current?.getBoundingClientRect();
		const offset = { x: rect.x - window.innerWidth, y: rect.y - window.innerHeight };
		if (isMobile) {
			offset.x = 0;
		} else {
			offset.y = 0;
		}
		if (onOffsetChange) onOffsetChange(offset);
	};

	const gsapVars = {
		mobile: {
			open: { duration: 0.125, y: 0, ease: Power0.easeIn, onUpdate: updateOffset, overwrite: true },
			closed: {
				duration: 0.125,
				y: '100%',
				onComplete: () => setRenderImage(false),
				onUpdate: updateOffset,
				overwrite: true,
			},
		},
		desktop: {
			open: { duration: 0.25, x: 0, ease: Power1.easeOut, onUpdate: updateOffset, overwrite: true },
			closed: {
				duration: 0.25,
				x: '100%',
				onComplete: () => setRenderImage(false),
				onUpdate: updateOffset,
				overwrite: true,
			},
		},
	};

	useEffect(() => {
		gsap.set(infoContainerRef.current, { clearProps: true });

		if (show) {
			gsap.set(infoContainerRef.current, isMobile ? gsapVars.mobile.open : gsapVars.desktop.open);
		} else {
			gsap.set(infoContainerRef.current, isMobile ? gsapVars.mobile.closed : gsapVars.desktop.closed);
		}
	}, [isMobile]);

	useEffect(() => {
		if (!currentLocation) return;
		if (show) {
			setRenderImage(true);

			const openTl = gsap.timeline();
			openTl.to(infoContainerRef.current, isMobile ? gsapVars.mobile.open : gsapVars.desktop.open);
		} else {
			const closeTl = gsap.timeline();
			closeTl.to(infoContainerRef.current, isMobile ? gsapVars.mobile.closed : gsapVars.desktop.closed);
		}
	}, [show]);

	const selectColor = (lydNiveauer: 'no_info' | 'low' | 'mid' | 'high', contrast: boolean = false) => {
		let color: any = undefined;
		if (lydNiveauer === 'no_info') color = soundColors.noSoundColor;
		if (lydNiveauer === 'low') color = soundColors.lowSoundColor;
		if (lydNiveauer === 'mid') color = soundColors.midSoundColor;
		if (lydNiveauer === 'high') color = soundColors.highSoundColor;

		return contrast ? color.contrastColor : color.color;
	};

	return (
		<Container
			ref={infoContainerRef}
			bgColor={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, false))}
			color={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
		>
			<Title color={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}>
				{currentLocation?.title}
				<svg
					width='31'
					height='31'
					viewBox='0 0 31 31'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					onClick={() => onClose && onClose()}
				>
					<rect
						width='28'
						height='4'
						transform='matrix(0.707107 0.707107 0.707107 -0.707107 4.14648 6.3125)'
						fill={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
					/>
					<rect
						width='28'
						height='4'
						transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 7.14648 26.3125)'
						fill={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
					/>
				</svg>
			</Title>
			{renderImage && (
				<ImageWrapper>
					<Image data={currentLocation?.image}></Image>
				</ImageWrapper>
			)}
			<InfoMid>
				{currentLocation?.info.map((info, index) => {
					return (
						<InfoEntry key={'MapInfoEntry_' + index}>
							<InfoText
								style={{ fontFamily: Fonts.Geomanist, fontWeight: Fonts.boldWeight }}
								color={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
							>
								{info.label}
							</InfoText>
							<InfoText
								style={{ textAlign: 'right' }}
								color={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
							>
								{info.text}
							</InfoText>
						</InfoEntry>
					);
				})}
			</InfoMid>
			{currentLocation?.lydNiveauer !== 'no_info' && (
				<InfoBottom color={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}>
					<svg width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<circle
							cx='25.0586'
							cy='25.5625'
							r='25'
							fill={
								currentLocation &&
								Functions.hexFromRGB(
									currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, false))
								)
							}
						/>
						<g clip-path='url(#clip0_3548_41958)'>
							<path
								d='M11.5586 30.8818L11.5586 20.1247H18.1443L24.5414 12.9304L24.5414 38.0762L18.1443 30.8818H11.5586Z'
								fill={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
							/>
						</g>
						<path
							d='M36.6274 25.5002C36.6274 30.7119 32.677 35.0039 27.6055 35.5645V32.9104C31.2142 32.3761 33.9996 29.2579 33.9996 25.509C33.9996 21.7601 31.2142 18.6418 27.6055 18.1075V15.4535C32.6682 16.0053 36.6274 20.3061 36.6274 25.5177V25.5002Z'
							fill={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
						/>
						<path
							d='M31.6873 25.4997C31.6873 27.9786 29.9354 30.0545 27.6143 30.5625V27.7946C28.4727 27.3829 29.0595 26.5158 29.0595 25.4997C29.0595 24.4837 28.4639 23.6165 27.6143 23.2048V20.437C29.9442 20.945 31.6873 23.0209 31.6873 25.4997Z'
							fill={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
						/>
						<path
							d='M27.6055 40.5039V37.8762C33.9383 37.3156 38.931 31.9813 38.931 25.4995C38.931 19.0178 33.9471 13.6922 27.6055 13.1229V10.4951C35.3923 11.0645 41.5588 17.5638 41.5588 25.4995C41.5588 33.4353 35.3923 39.9346 27.6055 40.5039Z'
							fill={currentLocation && Functions.hexFromRGB(selectColor(currentLocation.lydNiveauer, true))}
						/>
						<defs>
							<clipPath id='clip0_3548_41958'>
								<rect width='27.3022' height='30' fill='white' transform='matrix(1 0 0 -1 11.5586 40.5039)' />
							</clipPath>
						</defs>
					</svg>
					{currentLocation?.lydNiveauer === 'low' ? (
						<Text dangerouslySetInnerHTML={{ __html: HTML.clean(soundTexts.lowSoundText) }}></Text>
					) : currentLocation?.lydNiveauer === 'mid' ? (
						<Text dangerouslySetInnerHTML={{ __html: HTML.clean(soundTexts.midSoundText) }}></Text>
					) : currentLocation?.lydNiveauer === 'high' ? (
						<Text dangerouslySetInnerHTML={{ __html: HTML.clean(soundTexts.highSoundText) }}></Text>
					) : (
						''
					)}
				</InfoBottom>
			)}
		</Container>
	);
}
