import Functions from '@client/style/Functions';
import { Breakpoints, Colors, Fonts } from '@client/style/Variables';
import styled, { css } from 'styled-components';

// Text styles
export const commonTextStyle = css`
	font-family: ${Fonts.Geomanist};
	font-size: 18px;
	line-height: 130%;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 20px;
	}
`;

export const boldTextStyle = css`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
`;

export const heading1BigTextStyle = css`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 55px;
	line-height: 110%;
	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 138px;
	}
`;

export const heading1TextStyle = css`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 44px;
	line-height: 110%;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 55x;
	}
`;

export const heading2TextStyle = css`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 35px;
	line-height: 110%;
	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 44px;
	}
`;

export const heading3TextStyle = css`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 22px;
	line-height: 100%;
	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 36px;
	}
`;

// Components
export const CommonTitle = styled.h2`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 34px;
	text-align: center;
	margin: 0px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 96px;
	}
`;

export const CommonSubTitle = styled.div`
	font-family: ${Fonts.Geomanist};
	font-size: 38px;
	text-align: center;
	padding: 0px;
	color: ${Colors.lightText};
	margin: 0px;
`;

export const CommonText = styled.div`
	${commonTextStyle}
	text-align: center;
	margin: 0px;
	margin-top: 10px;
`;

export const CommonBoldText = styled.div`
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 24px;
	text-align: center;
	color: ${Colors.lightText};
`;
