import Loader from '../Loader';

export async function loadContentArtists(
	amountToLoad: number = 100,
	locations?: string[],
	tags?: string[],
	dates?: Date | string
) {
	const locationFilter = locations ? formatFilterString(locations, 'locations') : '';
	const tagFilter = tags ? formatFilterString(tags, 'tags') : '';
	const dateFilter = dates ? '&dates=' + dates : '';

	const artists = await Loader.get(
		`/api/performers?max=${amountToLoad.toString()}${locationFilter}${tagFilter}${dateFilter}`
	);
	const json = JSON.parse(artists);
	return json;
}

export async function loadExperiences(
	amountToLoad: number = 100,
	contentType: string[],
	locations?: string[],
	tags?: string[],
	dates?: Date[]
) {
	const contentTypeFilter = contentType ? formatFilterString(contentType, 'contentType') : '';
	const locationFilter = locations ? formatFilterString(locations, 'locations') : '';
	const tagFilter = tags ? formatFilterString(tags, 'tags') : '';

	const artists = await Loader.get(
		`/api/experiences?max=${amountToLoad.toString()}${contentTypeFilter}${locationFilter}${tagFilter}`
	);
	const json = JSON.parse(artists);
	return json;
}

export async function loadNews(amountToLoad: number = 15) {
	const news = await Loader.get(`/api/news?max=${amountToLoad.toString()}`);
	const json = JSON.parse(news);
	return json;
}

function formatFilterString(filterArray: (string | Date)[], queryParam: string) {
	if (filterArray.length === 0) return '';

	let tempString = `&${queryParam}=`;

	for (let index = 0; index < filterArray.length; index++) {
		const element = filterArray[index];

		if (index > 0) {
			tempString = tempString + ',';
		}
		tempString = tempString + element;
	}

	return tempString;
}
