import React, { useContext } from 'react';
import styled from 'styled-components';
import discord from '@client/assets/svgs/SoMe/discord.svg';
import instagram from '@client/assets/svgs/SoMe/instagram.svg';
import snap from '@client/assets/svgs/SoMe/snap.svg';
import spotify from '@client/assets/svgs/SoMe/spotify.svg';
import tiktok from '@client/assets/svgs/SoMe/tiktok.svg';
import twitter from '@client/assets/svgs/SoMe/twitter.svg';
import vimeo from '@client/assets/svgs/SoMe/vimeo.svg';
import youtube from '@client/assets/svgs/SoMe/youtube.svg';
import facebook from '@client/assets/svgs/SoMe/facebook.svg';

const Discord = styled(discord)``;
const Instagram = styled(instagram)``;
const Snap = styled(snap)``;
const Spotify = styled(spotify)``;
const TikTok = styled(tiktok)``;
const Twitter = styled(twitter)``;
const Vimeo = styled(vimeo)``;
const Youtube = styled(youtube)``;
const Facebook = styled(facebook)``;

export default function SoMeIcon(props: { icon: string }) {
	const findIcon = (type: string) => {
		switch (type) {
			case 'discord':
				return <Discord></Discord>;
			case 'instagram':
				return <Instagram></Instagram>;
			case 'snapchat':
				return <Snap></Snap>;
			case 'spotify':
				return <Spotify></Spotify>;
			case 'tiktok':
				return <TikTok></TikTok>;
			case 'twitter':
				return <Twitter></Twitter>;
			case 'vimeo':
				return <Vimeo></Vimeo>;
			case 'youtube':
				return <Youtube></Youtube>;
			case 'facebook':
				return <Facebook></Facebook>;
			default:
				console.log('No SoMe logo found');
		}
	};

	return <>{findIcon(props.icon)}</>;
}
