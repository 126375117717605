import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import gsap from 'gsap';
import { Breakpoints, Colors, Fonts } from '../../style/Variables';
import { CommonText, CommonTitle } from '@client/common/CommonText';
import Image, { ImageData } from '../Image';
import Link from '@client/core/PageSwitch/Link';

const Container = styled.div`
	padding: 64px 20px 0px 20px;
	color: ${Colors.darkText};
	position: relative;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: initial;
		padding: 0px 24px;
	}
	${Functions.breakpoint(Breakpoints.tablet)} {
	}
`;

const ImageContainer = styled.div`
	height: fit-content;
	overflow: hidden;
	${Functions.breakpoint(Breakpoints.mobile)} {
		opacity: 0;
		position: fixed;
		pointer-events: none;
		z-index: 0;
		top: 0px;
		left: 0px;
		height: auto;
		width: auto;
		transform: translate(-50%, -50%);
	}
`;

const SingleArtistImage = styled(Image)<{ ratio?: string; presetWidth?: string; presetHeight?: string }>`
	aspect-ratio: 1/1;
	position: relative;
	object-fit: cover;
	width: 100%;
	max-width: 100vw;
	${Functions.breakpoint(Breakpoints.tablet)} {
		width: ${props => props.presetWidth};
		aspect-ratio: ${props => props.ratio};
		height: ${props => props.presetHeight};
	}
	/* :before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		box-shadow: inset 0px 0px 15px 4px ${Colors.darkBackground};
	} */
`;

const TitleContainer = styled(CommonTitle)<{ fontSize: number }>`
	color: ${Colors.lightText};
	margin-top: 20px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: initial;
		white-space: nowrap;
		z-index: 5;
		position: relative;
		margin-top: 0px;
		font-size: ${props => props.fontSize}px;
	}
`;

const NationalityContainer = styled.span`
	display: none;
	color: ${Colors.lightText};
	font-weight: 400;
	font-size: 24px;
	font-family: ${Fonts.Geomanist};
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: inline-block;
	}
`;

const PlaceContainer = styled(CommonText)`
	color: ${Colors.lightText};
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;

const TimeContainer = styled(CommonText)`
	color: ${Colors.lightText};
	text-transform: capitalize;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;

const LinkContainer = styled(Link)``;

function SingleArtist({
	title,
	place,
	time,
	image,
	nationality,
	link,
	fontSize,
}: {
	title: string;
	place: any;
	time: string;
	nationality: string;
	image: ImageData;
	link: any;
	fontSize: number;
}) {
	const pictureRef = useRef<HTMLDivElement | null>(null);
	const titleRef = useRef<HTMLDivElement | null>(null);
	const [linkData, setLinkData] = useState<any>();
	const [hasMounted, setHasMounted] = useState(false);

	useEffect(() => {
		setHasMounted(true);
		const constructedLink = {
			internalLink: {
				_prefix: link._prefix,
				slug: link.slug,
			},
		};
		setLinkData(constructedLink);

		window.addEventListener('resize', setImageOpacityAndAdjust);
		window.addEventListener('mousemove', setImagePosition);

		return () => {
			window.removeEventListener('resize', setImageOpacityAndAdjust);
			window.removeEventListener('mousemove', setImagePosition);
		};
	}, []);

	const setImagePosition = (e: MouseEvent) => {
		adjustPicture(e.clientY, e.clientX);
	};

	const getHeightFromRatio = (width: number, height: number) => {
		if (Functions.findRatio(width, height) === '1') {
			return '500px';
		} else if (Functions.findRatio(width, height) === '16/9') {
			return '450px';
		} else if (Functions.findRatio(width, height) === '4/5') {
			return '563px';
		}
	};

	const getWidthFromRatio = (width: number, height: number) => {
		if (Functions.findRatio(width, height) === '1') {
			return '500px';
		} else if (Functions.findRatio(width, height) === '16/9') {
			return '800px';
		} else if (Functions.findRatio(width, height) === '4/5') {
			return '450px';
		}
	};

	const setImageOpacityAndAdjust = () => {
		if (pictureRef.current) {
			if (window.innerWidth < parseInt(Breakpoints.mobile)) {
				gsap.set(pictureRef.current, {
					opacity: 1,
					transform: 'translate(0px,0px)',
				});
			} else {
				gsap.set(pictureRef.current, {
					opacity: 0,
				});
			}
		}
	};

	const adjustPicture = (x: number, y: number) => {
		if (!pictureRef.current) return;
		if (window.innerWidth > parseInt(Breakpoints.tablet)) {
			const xVal = x - pictureRef.current.getBoundingClientRect().height / 2;
			const yVal = y - pictureRef.current.getBoundingClientRect().width / 2;
			if (pictureRef.current) {
				gsap.set(pictureRef.current, {
					transform: 'translate(' + yVal + 'px,' + xVal + 'px)',
					delay: 0.05,
				});
			}
		} else {
			gsap.set(pictureRef.current, {
				transform: 'translate(0px,0px)',
			});
		}
	};

	const showPicture = () => {
		if (!pictureRef.current || !titleRef.current) return;

		if (window.innerWidth > parseInt(Breakpoints.tablet)) {
			gsap.to(pictureRef.current, {
				opacity: 1,
				duration: 0.25,
			});
			gsap.to('.SingleCustomArtist', {
				opacity: 0.15,
				duration: 0.25,
			});
			gsap.to(titleRef.current, {
				opacity: 1,
				duration: 0.25,
				overwrite: true,
			});
		}
	};

	const hidePicture = () => {
		if (!pictureRef.current) return;

		if (window.innerWidth > parseInt(Breakpoints.tablet)) {
			gsap.to(pictureRef.current, {
				opacity: 0,
				duration: 0.25,
			});
			gsap.to('.SingleCustomArtist', {
				opacity: 1,
				duration: 0.25,
			});
		}
	};

	return (
		<Container>
			{hasMounted && (
				<LinkContainer data={linkData}>
					<ImageContainer ref={pictureRef}>
						<SingleArtistImage
							ratio={Functions.findRatio(image.width, image.height)}
							presetWidth={getWidthFromRatio(image.width, image.height)}
							presetHeight={getHeightFromRatio(image.width, image.height)}
							data={image}
						></SingleArtistImage>
					</ImageContainer>
					<TitleContainer
						className={'SingleCustomArtist'}
						ref={titleRef}
						onMouseEnter={showPicture}
						onMouseLeave={hidePicture}
						fontSize={fontSize}
					>
						{title}
						<NationalityContainer>({nationality})</NationalityContainer>
					</TitleContainer>
				</LinkContainer>
			)}
			{/* <PlaceContainer>{place.name}</PlaceContainer>
			<TimeContainer>{getTimeOfEvent(time)}</TimeContainer> FIND THIS GETTIMEOFEVENT in ArtistHERO */}
		</Container>
	);
}
export default SingleArtist;
