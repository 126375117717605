import { ILinkData } from '@client/core/PageSwitch/Link';
import Functions from '@client/style/Functions';
import React from 'react';
import styled from 'styled-components';
import SquaredLinkButton from './shared/SquaredLinkButton';
import { Breakpoints } from '@client/style/Variables';

const Container = styled.div<{ bgColor?: string }>`
	background-color: ${props => props.bgColor || 'white'};
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 40px;
	padding: 40px 0;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 80px 0;
	}
`;

export default function LinkButtonList({
	data,
}: {
	data: {
		backgroundColorLink: { color: any; contrastColor: any };
		list: { buttonColorLink: any; link: ILinkData; id: string }[];
	};
}) {
	return (
		<Container
			bgColor={data.backgroundColorLink && Functions.hexFromRGB(data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			{data.list?.map(item => {
				return (
					<SquaredLinkButton
						key={item.id}
						// @ts-expect-error - What???
						data={item.link[0]}
						backgroundColor={Functions.hexFromRGB(item.buttonColorLink?.color)}
						textColor={Functions.hexFromRGB(item.buttonColorLink?.contrastColor)}
					>
						{item.link.title}
					</SquaredLinkButton>
				);
			})}
		</Container>
	);
}
