import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';
import Functions from '@client/style/Functions';
import { Breakpoints, Fonts, presetColors } from '@client/style/Variables';
import { AnimationElementTreetop } from '../AnimationElementTreetop';
import Breaker from '@client/modules/Dividers.tsx/Breaker';
import { MorphSVGPlugin, MotionPathPlugin, SlowMo } from 'gsap/all';
import { gsap } from 'gsap';
import { Power1 } from 'gsap';
import { Quad } from 'gsap';

const StyledAnimationContainer = styled(AnimationContainer)`
	/* overflow: hidden; */
`;

const BackgroundContainer = styled.div`
	width: 101vw;
	svg {
		width: 100%;
		height: auto;
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		/* width: 15vw; */
	}
`;

const MobileContainer = styled.span`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;
const DesktopContainer = styled.span`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;
const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
	${Functions.breakpoint(Breakpoints.mobile)} {
		height: 40%;
	}
	${Functions.breakpoint(Breakpoints.ultra)} {
		height: 50%;
	}
`;

const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;

	${Functions.breakpoint(Breakpoints.mobile)} {
		/* top: 98%; */
		height: 60%;
	}
	${Functions.breakpoint(Breakpoints.ultra)} {
		height: 50%;
	}
`;

const StyledBreaker = styled(Breaker)<{ svgColor?: string }>`
	path {
		fill: ${props => props.svgColor || ''};
		/* fill: red; */
	}
`;

const SleepSvgContainer = styled.div`
	width: 60vw;
	svg {
		width: 100%;
		height: auto;
	}
`;

const SleepingZ = styled.div`
	color: ${presetColors.dust};
	font-family: ${Fonts.Geomanist};
	font-weight: ${Fonts.boldWeight};
	font-size: 24px;
	position: absolute;
	opacity: 0;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 48px;
	}
`;

const backgroundSvgMobile = (
	<svg width='375' height='271' viewBox='0 0 375 271' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M284.558 27.7026C328 35.2047 369.422 31.905 378.528 0.274958L378.528 270.601L-9.52734 267.827V55.6819C6.1163 54.2022 30.539 64.1049 78.7307 27.7026C138.97 -17.8003 246.226 21.0829 284.558 27.7026Z'
			fill={presetColors.evergreen}
		/>
	</svg>
);
const backgroundSvgDesktop = (
	<svg width='1728' height='295' viewBox='0 0 1728 295' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M938.125 144.033C659.597 40.1746 218.004 -76.4666 0 67.3371V294.228H1728V102.489C1557.55 150.956 1160.95 227.119 938.125 144.033Z'
			fill={presetColors.evergreen}
		/>
	</svg>
);

const Tent = () => {
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1686837247-telt.png', {
		width: 1600,
	});
	return (
		<AnimationPositionElement
			position={{
				anchorY: 'bottom',
				width: '90vw',
				x: '-36%',
				y: '-115%',
				breakpoints: { mobile: { width: '50vw', y: '-43%' }, ultra: { x: '-38%' } },
			}}
		>
			<AnimationImageElement image={image} />
		</AnimationPositionElement>
	);
};
const Can = () => {
	const image = new AnimationImageAsset(' https://www.datocms-assets.com/80292/1686837378-can.png', {
		tiny: 400,
		mobile: 600,
	});

	return (
		<>
			<DesktopContainer>
				<AnimationPositionElement
					position={{ anchorY: 'bottom', width: '17vw', anchor: 'right', x: '-60%', y: '-61%' }}
				>
					<AnimationImageElement image={image} />
				</AnimationPositionElement>
				<AnimationPositionElement
					position={{ anchorY: 'bottom', width: '17vw', anchor: 'right', x: '-128.5%', y: '-55%' }}
				>
					<AnimationImageElement image={image} />
				</AnimationPositionElement>
			</DesktopContainer>
			<AnimationPositionElement
				position={{
					anchorY: 'bottom',
					width: '45vw',
					x: '10%',
					anchor: 'right',
					y: '-40%',
					breakpoints: { mobile: { width: '17vw', x: '-69%' } },
				}}
			>
				<AnimationImageElement image={image} />
			</AnimationPositionElement>
		</>
	);
};

const Heart = (props: { variant: 1 | 2 }) => {
	const heart1Ref = React.useRef<HTMLImageElement>(null);
	const heart2Ref = React.useRef<HTMLImageElement>(null);

	const heart1 = new AnimationImageAsset('https://www.datocms-assets.com/80292/1679307288-hjerte-1.png', {
		tiny: 100,
		mobile: 300,
	});
	const heart2 = new AnimationImageAsset('https://www.datocms-assets.com/80292/1679307291-hjerte-2.png', {
		tiny: 205,
		mobile: 300,
	});
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		const tween = gsap.to('.heart', {
			stagger: 0.2,
			scale: 1.04,
			duration: 0.4,
			yoyo: true,
			repeat: -1,
			ease: 'back.in(1, 0.3)',
		});
		containerTimeline.add(tween, 0);
		return () => {
			tween.kill();
		};
	}, []);

	return (
		<>
			{props.variant === 1 && (
				<AnimationImageElement image={heart1} className={'heart'} ref={heart1Ref}></AnimationImageElement>
			)}
			{props.variant === 2 && (
				<AnimationImageElement image={heart2} className={'heart'} ref={heart2Ref}></AnimationImageElement>
			)}
		</>
	);
};

const SleepingZs = () => {
	const sleepingZ1 = React.useRef<HTMLDivElement>(null);
	const sleepingZ2 = React.useRef<HTMLDivElement>(null);
	const sleepingZ3 = React.useRef<HTMLDivElement>(null);
	const pathRef = React.useRef<SVGSVGElement>(null);

	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		gsap.registerPlugin(MorphSVGPlugin);
		gsap.registerPlugin(MotionPathPlugin);
		const myPath = pathRef.current?.querySelector<SVGPathElement>('.pathClass');
		if (!myPath) return;
		const tl = gsap.timeline({ repeat: -1 });

		tl.to([sleepingZ1.current, sleepingZ2.current, sleepingZ3.current], {
			duration: 3,
			ease: 'power1.inOut',
			delay: i => [0, 1, 2][i],
			motionPath: {
				path: myPath,
				align: myPath,
				//autoRotate: true,
				alignOrigin: [0.5, 0.5],
			},
		});
		tl.fromTo(
			[sleepingZ1.current, sleepingZ2.current, sleepingZ3.current],
			{
				opacity: 0,
			},
			{
				opacity: 1,
				duration: 0.5,
				delay: i => [0, 1, 2][i],
				ease: Power1.easeIn,
			},
			0
		);
		tl.fromTo(
			[sleepingZ1.current, sleepingZ2.current, sleepingZ3.current],
			{
				opacity: 1,
			},
			{
				opacity: 0,
				duration: 0.5,
				delay: i => [0, 1, 2][i],
				ease: Power1.easeOut,
			},
			'+2.5'
		);
		containerTimeline.add(tl, 0);
		gsap.set([sleepingZ1.current, sleepingZ2.current, sleepingZ3.current], { clearProps: 'all' });
		// containerTimeline.repeat(-1);
		return () => {
			tl.kill();
		};
	}, []);

	return (
		<>
			<AnimationPositionElement
				position={{ x: '38%', y: '25%', breakpoints: { mobile: { x: '10%', y: '-5%' }, ultra: { x: '8%', y: '-2%' } } }}
			>
				<SleepSvgContainer>
					<svg ref={pathRef} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 50'>
						<path
							className='pathClass'
							d='m 5 8 C 5.3333 7 6 6 6 5 C 6 4 5 4 5 3 C 5 2 5.3333 1.6667 6 1'
							// stroke='#FF0000'
							// strokeWidth='0.10'
							fill='none'
						/>
					</svg>
				</SleepSvgContainer>
			</AnimationPositionElement>
			<AnimationPositionElement position={{ angle: 10 }}>
				<SleepingZ ref={sleepingZ1}>Z</SleepingZ>
			</AnimationPositionElement>
			<AnimationPositionElement position={{ angle: -10 }}>
				<SleepingZ ref={sleepingZ2}>Z</SleepingZ>
			</AnimationPositionElement>
			<AnimationPositionElement position={{ angle: 15 }}>
				<SleepingZ ref={sleepingZ3}>Z</SleepingZ>
			</AnimationPositionElement>
		</>
	);
};

export default function IllustrationSovSmukt() {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (moduleRef.current) {
			// console.log('moduleRef: ', moduleRef.current);
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor != null && nextColor != null) {
				setBackgroundColors([prevColor, nextColor]);
			} else if (prevColor != null) {
				setBackgroundColors([prevColor, prevColor]);
			} else if (nextColor != null) {
				setBackgroundColors([nextColor, nextColor]);
			} else {
				setBackgroundColors([undefined, undefined]);
			}
		}
	}, [moduleRef]);
	return (
		<StyledAnimationContainer
			height={1175}
			width={715}
			breakpoints={{ mobile: { width: 2600, height: 1350 }, ultra: { width: 4000, height: 2250 } }}
			ref={moduleRef}
		>
			<ColorWrapper>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={presetColors.evergreen}></BottomColor>
			</ColorWrapper>

			<DesktopContainer>
				<AnimationElementTreetop
					variant={7}
					position={{
						flip: true,
						width: '10vw',
						anchorY: 'center',
						y: '-73%',
						x: '-7%',
						breakpoints: {
							mobile: { width: '50vw' },
							laptop: { width: '50vw' },
							desktop: { width: '50vw' },
							largeDesktop: { width: '50vw' },
							ultra: { width: '50vw', x: '-23%', y: '-76%' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{
						y: '-62%',
						anchor: 'right',
						anchorY: 'bottom',

						x: '-35%',
						width: '55vw',
						breakpoints: {
							laptop: { width: '45vw' },
							desktop: { width: '45vw' },
							largeDesktop: { width: '45vw' },
							ultra: { width: '45vw' },
						},
					}}
				></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={2}
					position={{
						y: '-64%',
						flip: true,
						anchor: 'right',
						anchorY: 'bottom',
						x: '-8%',
						width: '55vw',
						breakpoints: {
							laptop: { width: '45vw' },
							desktop: { width: '45vw' },
							largeDesktop: { width: '45vw' },
							ultra: { width: '45vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<AnimationElementTreetop
				variant={3}
				position={{
					anchor: 'right',
					anchorY: 'bottom',
					width: '110vw',
					x: '35%',
					y: '-90%',
					breakpoints: {
						tiny: { width: '120vw' },
						mobile: { width: '45vw', y: '-77%' },
						laptop: { width: '45vw' },
						desktop: { width: '45vw' },
						largeDesktop: { width: '45vw' },
						ultra: { width: '43vw', y: '-105%' },
					},
				}}
			></AnimationElementTreetop>
			<MobileContainer>
				<AnimationElementTreetop
					variant={6}
					position={{
						flip: true,
						anchorY: 'bottom',
						x: '-50%',
						width: '87vw',
						y: '-171%',
						breakpoints: { tiny: { width: '87vw', y: '-171%' } },
					}}
				></AnimationElementTreetop>
			</MobileContainer>

			<MobileContainer>
				<AnimationPositionElement position={{ anchorY: 'bottom', y: '-48%' }}>
					<BackgroundContainer>{backgroundSvgMobile}</BackgroundContainer>
				</AnimationPositionElement>
			</MobileContainer>
			<DesktopContainer>
				<AnimationPositionElement position={{ anchorY: 'bottom', y: '-135%' }}>
					<BackgroundContainer>{backgroundSvgDesktop}</BackgroundContainer>
				</AnimationPositionElement>
			</DesktopContainer>

			<AnimationPositionElement position={{ anchorY: 'bottom', height: '25vw', width: '100vw' }}>
				<div style={{ backgroundColor: presetColors.evergreen, width: '100%', height: '100%' }}></div>
			</AnimationPositionElement>
			<Tent />
			<Can />
			<AnimationPositionElement
				position={{
					anchorY: 'bottom',
					width: '12vw',
					anchor: 'right',
					x: '-227%',
					y: '-620%',
					breakpoints: { mobile: { x: '-1117%', y: '-425%', width: '5vw' }, ultra: { x: '-1115%' } },
				}}
			>
				<Heart variant={1} />
			</AnimationPositionElement>
			<AnimationPositionElement
				position={{
					anchorY: 'bottom',
					width: '16vw',
					anchor: 'right',
					x: '-230%',
					y: '-530%',
					breakpoints: { mobile: { y: '-370%', width: '6vw', x: '-1037' }, ultra: { x: '-1005%' } },
				}}
			>
				<Heart variant={2} />
			</AnimationPositionElement>
			<SleepingZs />
			<AnimationPositionElement position={{ anchorY: 'bottom', width: '100vw' }}>
				<StyledBreaker number={0} svgColor={backgroundColors[1]} />
			</AnimationPositionElement>
		</StyledAnimationContainer>
	);
}
